import { Modal } from 'antd';
import { useState } from 'react';
import styles from './ModalAddOrganization.module.scss';
import FullScreenSpinner from 'common/FullScreenSpinner';

export default function ModalDeleteOrganization(props) {
  const [propsDeleteOrganization] = useState({
    loading: true,
    hasData: false,
  });

  const cancelDeleteOrganization = () => {
    props.hide();
  };

  const deleteOrganization = () => {
    props.deleteOrganization();
    props.hide();
  };

  return (
    <Modal
      {...propsDeleteOrganization}
      className={styles.Organizations}
      title="Confirmation"
      open={props.isShowing}
      onOk={deleteOrganization}
      onCancel={cancelDeleteOrganization}
      okText="Ok"
      cancelText="Cancel"
    >
      <p style={{}}>
        Are you sure you want to delete the organization: <b>{props.selectedOrganization?.name}</b> ?
      </p>
      <p>ALL data will permanently be deleted from this organization.</p>
    </Modal>
  );
}
