import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { EventFieldValuesParams, EventFieldValuesResponse, AggregatedEventFieldValuesResponse } from 'types/EventTypes';
import { userAtom } from '../common/Atoms';
import AxiosApiInstance from '../common/Interceptors';
import { URL_API_V1 } from '../constants/global';

export function useEventFieldValuesApi() {
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();
  const [loading, setLoading] = useState(false);

  const getEventsFieldValuesApi = async (params: EventFieldValuesParams): Promise<AggregatedEventFieldValuesResponse> => {
    setLoading(true);
    try {
      const response = await axiosApiInstance.get<AggregatedEventFieldValuesResponse>(`${URL_API_V1}/r/${user.organization}/events-field-values`, {
        params,
      });

      setLoading(false);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
      throw err;
    }
  };

  return { loading, getEventsFieldValuesApi };
}
