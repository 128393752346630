import React, { useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import { URL_API_V1 } from '../constants/global';
import axios from 'axios';
import useAuth from '../hooks/auth';
import { Modal, Button } from 'antd';
import { userAtom } from '../common/Atoms';
import { useRecoilState } from 'recoil';
import jwt from 'jwt-decode';
import { Utils } from './2FAUtils';

function IdleMonitor() {
  const axiosApiInstance = axios.create();
  const { handleLogout } = useAuth();
  const [user, setUser] = useRecoilState(userAtom);

  const [idleModal, setIdleModal] = useState(false);
  const [refreshTokenExpiringModal, setRefreshTokenExpiringModal] = useState(false);
  const [refreshTokenExpiredModal, setRefreshTokenExpiredModal] = useState(false);

  const [isRefreshTokenExpiringModalSeen, setIsRefreshTokenExpiringModalSeen] = useState(
    localStorage.getItem('IsRefreshTokenExpiringModalSeen') === 'true'
  );

  const [idleTimeout] = useState(1000 * parseInt(process.env.REACT_APP_IDLE_TIMEOUT_IN_SEC || '900')); // 15 mins
  const [idleLogout] = useState(idleTimeout + (1000 * parseInt(process.env.REACT_APP_IDLE_LOGOUT_TIMEOUT_IN_SEC || '15'))); // 15 mins and 15 sec

  const [warnBeforeRefreshTokenExp] = useState(1000 * parseInt(process.env.REACT_APP_WARN_BEFORE_REFRESH_TOKEN_EXP || '300')); // 5 mins
  const [warnFinalBeforeRefreshTokenExp] = useState(1000 * parseInt(process.env.REACT_APP_WARN_FINAL_BEFORE_REFRESH_TOKEN_EXP || '10')); // 10 sec
  const [counter, setCounter] = useState(parseInt(process.env.REACT_APP_WARN_FINAL_BEFORE_REFRESH_TOKEN_EXP || '10')); // 10 sec

  let idleEvent: any;
  let idleLogoutEvent: any;
  /**
   * Add any other events listeners here
   */
  const events = ['mousemove', 'click', 'keypress'];

  useEffect(() => {
    let decoded_refresh_token: any = jwtDecode(user.refresh);
    let exRefreshTokenEpoch = decoded_refresh_token['exp'];

    let now = new Date();
    let currentTime = now.getTime() / 1000;

    let expiringRefreshTokenSeconds = 0;
    if (exRefreshTokenEpoch - currentTime > 0) {
      expiringRefreshTokenSeconds = (exRefreshTokenEpoch - currentTime) * 1000;

      setTimeout(() => logOut(), expiringRefreshTokenSeconds);
      setTimeout(() => {
        setRefreshTokenExpiredModal(true);
      }, expiringRefreshTokenSeconds - warnFinalBeforeRefreshTokenExp);
      setTimeout(() => setRefreshTokenExpiringModal(true), expiringRefreshTokenSeconds - warnBeforeRefreshTokenExp);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const refreshToken = async () => {
    const refreshToken = user.refresh;
    try {
      const response = await axiosApiInstance.post(`${URL_API_V1}/auth/refresh`, {
        refresh: refreshToken,
      });
      if (response && response.status === 200) {
        console.log('re-authenticating.. good - got the fresh token');
        const data: any = response.data;
        const token = data['token'];
        // is related with Interceptors.ts AxiosApiInstance() function
        let jwt_decoded: any = jwtDecode(data.token);
        const is_2fa_authenticated = Utils.isJWT2FAAuthenticated(jwt_decoded);
        setUser({ ...user, token: token, is_2fa_authenticated });
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @method sessionTimeout
   * This function is called with each event listener to set a timeout or clear a timeout.
   */
  const sessionTimeout = () => {
    if (!!idleEvent) clearTimeout(idleEvent);
    if (!!idleLogoutEvent) clearTimeout(idleLogoutEvent);

    idleEvent = setTimeout(() => setIdleModal(true), idleTimeout); //show session warning modal.
    idleLogoutEvent = setTimeout(() => logOut(), idleLogout); //Call logged out on session expire.
  };

  React.useEffect(() => {
    if (refreshTokenExpiredModal) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, refreshTokenExpiredModal]);

  /**
   * @method extendSession
   * This function will extend current user session.
   */
  const extendSession = () => {
    setIdleModal(false);
    refreshToken();
  };

  /**
   * @method logOut
   * This function will destroy current user session.
   */
  const logOut = () => {
    console.log('logging out');
    handleLogout();
  };

  const confirmRefreshTokenExpiringModal = () => {
    setRefreshTokenExpiringModal(false);

    localStorage.setItem('IsRefreshTokenExpiringModalSeen', JSON.stringify(true));
    setIsRefreshTokenExpiringModalSeen(true);
  };

  useEffect(() => {
    function setupEventListeners() {
      for (let e in events) {
        window.addEventListener(events[e], sessionTimeout);
      }

      return () => {
        for (let e in events) {
          window.removeEventListener(events[e], sessionTimeout);
        }
      };
    }

    setupEventListeners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Modal
        title=" Session expire warning"
        open={idleModal}
        footer={[
          <Button key={'logoutSession'} type="default" onClick={() => logOut()}>
            Logout
          </Button>,
          <Button key={'extendSession'} type="primary" onClick={() => extendSession()}>
            Extend session
          </Button>,
        ]}
      >
        <p>Your session will expire in 15 seconds. Do you want to extend the session?</p>
      </Modal>

      <Modal
        title=" Session expire warning"
        open={refreshTokenExpiringModal && !isRefreshTokenExpiringModalSeen}
        footer={[
          <Button key={'extendSession'} type="primary" onClick={() => confirmRefreshTokenExpiringModal()}>
            Okay
          </Button>,
        ]}
      >
        <p>Attention: You will be logged out in 5 minutes. Please save your work now to avoid losing any progress</p>
      </Modal>

      <Modal
        title=" Session is ending"
        open={refreshTokenExpiredModal}
        footer={[
          <Button key={'logoutSession'} type="primary" onClick={() => logOut()}>
            Logout
          </Button>,
        ]}
      >
        <p>Attention: You will be logged out in {counter} seconds.</p>
      </Modal>
    </div>
  );
}

export default IdleMonitor;
