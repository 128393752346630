import { Alert, Button, Card, Form, Input, Result } from "antd";
import axios from "axios";
import { useCallback, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link, useNavigate } from "react-router-dom";
import RenderErrorMessages from "../../common/RenderErrorMessages";
import { URL_API_V1, reCAPTCHAKEY } from "../../constants/global";
import styles from "./ForgotCredentials.module.scss";

export default function ForgotCredentials() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [errorMessage, setErrorMessage] = useState("");
  const [, setCounter] = useState(0);
  const [message, setMessage] = useState("");

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const routeChange = () => {
    let path = `/login`;
    navigate(path);
  };

  const onFinish = useCallback((values, recaptchaToken) => {
    const data = {
      email: values.email,
      response: recaptchaToken,
    };
    axios
      .post(`${URL_API_V1}/auth/password/forgot`, data)
      .then((resp) => {
        setCounter((keySequence) => keySequence + 1);
        if ((resp && resp.status === 200) || resp.status === 201) {
          setMessage("Email sent to " + data.email + ", please check your email.");
        }
      })
      .catch((err) => {
        console.log("error:");
        console.log(err);
        if (err && err.response) {
          if (err.response.status === 498) {
            setErrorMessage(
              `Authentication failed. Invalid challenge-response test, we could not determine if you are a human. Please try again.`
            );
          } else {
            setErrorMessage("An unexpected error has occurred");
          }
        } else {
          setErrorMessage("An unexpected error has occurred");
        }
        RenderErrorMessages(err?.response?.data["reason"], form);
      });
  
}, [form]);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async (values) => {
    let token = '';
    if(reCAPTCHAKEY){
      console.log("handleReCaptchaVerify")
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      token = await executeRecaptcha("login");
      console.log("setting token..")
    }
    onFinish(values, token)
  }, [executeRecaptcha, onFinish])


  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const getStatusMessage = () => {
    if (message) {
      return (
        <>
          <Result
            status="success"
            title={message}
            extra={
              <Button key="home" type="primary" onClick={routeChange}>
                Go Back
              </Button>
            }
          />
        </>
      );
    }
    return;
  };

  return (
    <div className={styles.ForgotCredentials}>
      {!message && (
        <Card title="Enter your email address to reset your password" style={{ margin: "25px", width: "500px" }}>
          <Form layout="inline" form={form} onFinish={handleReCaptchaVerify} onFinishFailed={onFinishFailed}>
            <Form.Item
              name="email"
              label="Email address"
              rules={[
                {
                  required: true,
                  message: "Email address has not been filled in.",
                },
              ]}
            >
              <Input disabled={message?.length > 0} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={message?.length > 0}>
                Submit
              </Button>
            </Form.Item>
            {errorMessage.length > 0 && <div><Alert style={{marginTop:"15px"}} message={errorMessage} type="error" showIcon closable /></div>}
          </Form>
        </Card>
      )}
      {getStatusMessage()}
      <nav className="navbar navbar-expand-lg navbar-light fixed-bottom">
        <Link style={{ margin: "5px" }} to={"/login"}>
          {" "}
          Back to Login
        </Link>
      </nav>
    </div>
  );
}
