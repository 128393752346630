import React, { useState } from 'react';
import { Card, Button } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

// Function to mask the key
const maskKey = (key: string) => {
  if (key.length <= 4) {
    return key;
  }
  const visiblePart = key.substring(0, 4);
  const maskedPart = '*'.repeat(8 - 4);
  return visiblePart + maskedPart;
};

interface ToggleableKeyProps {
  keyValue: string | null;
}

const ToggleableKey: React.FC<ToggleableKeyProps> = ({ keyValue }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      {keyValue ? (isVisible ? keyValue : maskKey(keyValue)) : 'N/A'}
      <Button
        type="link"
        icon={isVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        onClick={toggleVisibility}
      />
    </>
  );
};

export default ToggleableKey;
