import React, { useContext, useEffect, useState, useRef } from "react";
import { Alert, Button, Col, message, Modal, Row, Steps, Checkbox } from "antd";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";
import { notificationsRefreshAtom, userAtom } from '../../common/Atoms';
import EncryptionKeys from "../EncryptionKeys/EncryptionKeys";
import PiiFields from "../PiiFields/PiiFields";
import APIKeys from "../APIKeys/APIKeys";
import AgentService from "../AgentService/AgentService";
import SetupAgentsCommands from "../SetupAgentsCommands/SetupAgentsCommands";
import { StepStatus } from "./SetupWizard.types";
import styles from "./SetupWizard.module.scss";
import SecurityKeys, {
  SecurityKeysProps,
} from "components/SecurityKeys/SecurityKeys";
import Certificates from "components/Certificates/Certificates";
import { SetupWizardContext } from "./SetupWizardContext";
import PrivacyComponent from "components/PiiFields/PrivacyComponent";

const { Step } = Steps;

interface SetupWizardProps {
  visible: boolean;
  onComplete: () => void;
  onCancel: () => void;
  notificationUuid?: string;
}

interface IngestionMetrics {
  tenant: string;
  details: {
    failed_events_total: number;
    processed_events_total: number;
    last_metric_update_timestamp: number;
  };
}

const SetupWizard: React.FC<SetupWizardProps> = ({
  visible,
  onComplete,
  onCancel,
  notificationUuid,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [checking, setChecking] = useState(false);
  const [privacyAcknowledged, setPrivacyAcknowledged] = useState(false);
  const [stepStatus, setStepStatus] = useState<StepStatus>({
    hasPrivacySetup: false,
    hasEncryptionKey: false,
    hasApiKey: false,
    hasAgentCert: false,
    hasAgentRunning: false,
  });
  const [user] = useRecoilState(userAtom);
  const [refreshCount, setRefreshCount] = useRecoilState(notificationsRefreshAtom); // Use the refresh atom
  const { axiosApiInstance } = AxiosApiInstance();
  const metricsIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [currentEventCount, setCurrentEventCount] = useState<number>(0);
  
  interface SecurityKeysState {
    masterKey: string;
    dataEncryptionKey: string;
    apiAccessKey: string;
    apiSecretKey: string;
    organizationKey: string;
    tokenKey: string;
    certificateUuid: string;
  }

  const context = useContext(SetupWizardContext);
  const { securityKeys, setSecurityKeys, resetSecurityKeys } = context || { 
    securityKeys: {} as SecurityKeysState, 
    setSecurityKeys: () => {}, 
    resetSecurityKeys: () => {} 
  };

  useEffect(() => {
    if (!visible) {
      setStepStatus({
        hasPrivacySetup: false,
        hasEncryptionKey: false,
        hasApiKey: false,
        hasAgentCert: false,
        hasAgentRunning: false,
      });
      setCurrentStep(0); // Optionally reset the current step
    }
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      resetSecurityKeys();
    }
  }, [visible]);

  const labelToKeyMap: Record<string, keyof SecurityKeysState> = {
    "Master Encryption Key": "masterKey",
    "API Access Key": "apiAccessKey",
    "API Secret Key": "apiSecretKey",
    "Organization Key": "organizationKey",
    "Token Key": "tokenKey",
    "Certificate UUID": "certificateUuid",
  };

  const handleEncryptionKeysChange = (hasKeys: boolean) => {
    setStepStatus((prev) => ({
      ...prev,
      hasEncryptionKey: hasKeys,
    }));
  };

  const handleUpdateSecurityKey = (label: string, value: string) => {
    const key = labelToKeyMap[label];
    if (key) {
      console.log(`Updating key: ${key} with value: ${value}`); // Debugging
      setSecurityKeys((prevKeys) => ({
        ...prevKeys,
        [key]: value,
      }));
    } else {
      console.warn(`Unknown key label: ${label}`);
    }
  };

  const [isLoadingKeys, setIsLoadingKeys] = useState(false);

  // Remove the redundant useEffect that clears the interval based on visibility
  // useEffect(() => {
  //   if (!visible && metricsInterval) {
  //     clearInterval(metricsInterval);
  //     setMetricsInterval(null);
  //     setCurrentEventCount(0); // Reset the count
  //   }
  // }, [visible]);

  // Fetch security keys when current step is 4
  useEffect(() => {
    if (currentStep === 4) {
      const fetchSecurityKeys = async () => {
        setIsLoadingKeys(true);
        try {
          // Fetch API keys
          const apiKeysResponse = await axiosApiInstance.get(
            `${URL_API_V1}/r/${user.organization}/security/api-keys`
          );
          console.log("API Keys Response:", apiKeysResponse.data);
          const activeKey = apiKeysResponse.data.results?.find(
            (key: any) => key.state === "active"
          );

          // Fetch encryption keys
          const encryptionResponse = await axiosApiInstance.get(
            `${URL_API_V1}/r/${user.organization}/security/encryption-keys/`
          );
          console.log("Encryption Keys Response:", encryptionResponse.data);
          const activeOrgKey = encryptionResponse.data.entries?.find(
            (key: any) => key.state === "active"
          );

          // Get token
          const token = localStorage.getItem("jwt_token");
          console.log("Token:", token);

          console.log("Updated Security Keys:", {
            masterKey: activeOrgKey?.master_key || "",
            apiAccessKey: activeKey?.access_key || "",
            apiSecretKey: activeKey?.secret_key || "",
            organizationKey: activeOrgKey?.key || "",
            tokenKey: token || "",
          });
        } catch (error) {
          console.error("Error fetching security keys:", error);
          message.error("Failed to fetch security keys");
        } finally {
          setIsLoadingKeys(false);
        }
      };

      fetchSecurityKeys();
    }
  }, [currentStep, user.organization]);

  // Add debug logging
  useEffect(() => {
    if (currentStep === 4) {
      console.log("Security Keys:", securityKeys);
    }
  }, [securityKeys, currentStep]);

  // Start/stop polling based on current step and visibility
  useEffect(() => {
    // Clear any existing interval first
    if (metricsIntervalRef.current) {
      clearInterval(metricsIntervalRef.current);
      metricsIntervalRef.current = null;
    }

    // Only start polling if wizard is visible and we're on step 4
    if (visible && currentStep === 4) {
      checkIngestionMetrics();
      metricsIntervalRef.current = setInterval(checkIngestionMetrics, 2000);
    }

    // Cleanup function to clear the interval when dependencies change or component unmounts
    return () => {
      if (metricsIntervalRef.current) {
        clearInterval(metricsIntervalRef.current);
        metricsIntervalRef.current = null;
      }
    };
  }, [currentStep, visible]); // Dependencies on both step and visibility

  useEffect(() => {
    console.log("securityKeys: ", securityKeys)
    setStepStatus((prev) => ({
      ...prev,
      hasEncryptionKey: Boolean(securityKeys.masterKey),
      hasApiKey: Boolean(
        securityKeys.apiAccessKey || securityKeys.apiSecretKey
      ),
      hasAgentCert: Boolean(securityKeys.certificateUuid),
    }));
  }, [securityKeys]);

  const checkIngestionMetrics = async () => {
    try {
      const response = await axiosApiInstance.get<IngestionMetrics>(
        `${URL_API_V1}/r/${user.organization}/ingestion/metrics`
      );

      const processedEvents = response.data.details.processed_events_total;
      setCurrentEventCount(processedEvents);

      if (processedEvents > 0) {
        // Stop polling once we have events
        // if (metricsIntervalRef.current) {
        //   clearInterval(metricsIntervalRef.current);
        //   metricsIntervalRef.current = null;
        // }
        setStepStatus((prev) => ({
          ...prev,
          hasAgentRunning: true,
        }));
      }
    } catch (error) {
      console.error("Error checking ingestion metrics:", error);
    }
  };

  const checkStepStatus = async () => {
    setChecking(true);
    try {
      // Only check what's needed based on current step
      switch (currentStep) {
        case 0: // Privacy
          const piiResponse = await axiosApiInstance.get(
            `${URL_API_V1}/r/${user.organization}/events/pii/field-groups/`
          );

          const privacySetup = piiResponse.data.entries?.length > 0;
          
          setStepStatus((prev) => ({
            ...prev,
            hasPrivacySetup: privacySetup,
          }));
          break;

        case 1: // Encryption Keys
          const encryptionResponse = await axiosApiInstance.get(
            `${URL_API_V1}/r/${user.organization}/security/encryption-keys/`
          );
          setStepStatus((prev) => ({
            ...prev,
            hasEncryptionKey: encryptionResponse.data.entries?.length > 0,
          }));
          break;

        case 2: // API Keys
          const apiKeysResponse = await axiosApiInstance.get(
            `${URL_API_V1}/r/${user.organization}/security/api-keys`
          );
          setStepStatus((prev) => ({
            ...prev,
            hasApiKey: apiKeysResponse.data.results?.length > 0,
          }));
          break;

        case 3: // Agent Certificates
          const certResponse = await axiosApiInstance.get(
            `${URL_API_V1}/r/${user.organization}/kafka/agent-certs/`
          );
          setStepStatus((prev) => ({
            ...prev,
            hasAgentCert: certResponse.data.certificates?.length > 0,
          }));
          break;

        case 4: // Agent Running
          // Clear any existing interval
          if (metricsIntervalRef.current) {
            clearInterval(metricsIntervalRef.current);
            metricsIntervalRef.current = null;
          }

          // Initial check
          await checkIngestionMetrics();

          // Start polling every 2 seconds if no events yet
          metricsIntervalRef.current = setInterval(checkIngestionMetrics, 2000);
          break;
      }
    } catch (error) {
      console.error("Error checking setup status:", error);
      message.error("Failed to check setup status");
    } finally {
      setChecking(false);
    }
  };

  useEffect(() => {
    if (visible) {
      checkStepStatus();
    }
  }, [visible]);

  const getCurrentStepStatus = () => {
    switch (currentStep) {
      case 0:
        return stepStatus.hasPrivacySetup || privacyAcknowledged;
      case 1:
        return stepStatus.hasEncryptionKey;
      case 2:
        return stepStatus.hasApiKey;
      case 3:
        return stepStatus.hasAgentCert;
      case 4:
        return stepStatus.hasAgentRunning;
      default:
        return false;
    }
  };

  const steps = [
    {
      title: "Privacy",
      content: (
        <div>
          <PrivacyComponent />
          <StepStatusIndicator
            isComplete={stepStatus.hasPrivacySetup || privacyAcknowledged}
            checking={checking}
            onCheck={checkStepStatus}
          />
          {!stepStatus.hasPrivacySetup && (
            <div style={{ marginTop: 16 }}>
              <Checkbox
                checked={privacyAcknowledged}
                onChange={(e) => setPrivacyAcknowledged(e.target.checked)}
              >
                I acknowledge that data is not protected
              </Checkbox>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Encryption Keys",
      content: (
        <div>
          <EncryptionKeys onEncryptionKeysChange={handleEncryptionKeysChange} />
          <StepStatusIndicator
            isComplete={stepStatus.hasEncryptionKey}
            checking={checking}
            onCheck={checkStepStatus}
          />
        </div>
      ),
    },
    {
      title: "API Keys",
      content: (
        <div>
          <APIKeys />
          <StepStatusIndicator
            isComplete={stepStatus.hasApiKey}
            checking={checking}
            onCheck={checkStepStatus}
          />
        </div>
      ),
    },
    {
      title: "Agent Certificates",
      content: (
        <div>
          <Certificates />
          <StepStatusIndicator
            isComplete={stepStatus.hasAgentCert}
            checking={checking}
            onCheck={checkStepStatus}
          />
        </div>
      ),
    },
    {
      title: "Install Agents",
      content: (
        <div>
          {isLoadingKeys ? (
            <div style={{ textAlign: "center", padding: "20px" }}>
              <LoadingOutlined style={{ fontSize: 24 }} />
              <p>Loading security keys...</p>
            </div>
          ) : (
            <SetupAgentsCommands
              checking={checking}
              stepStatus={stepStatus}
              onCheck={checkStepStatus}
              securityKeys={securityKeys} // Ensure this is passed correctly
              onUpdate={handleUpdateSecurityKey}
            />
          )}
          {currentStep === 4 && ( // Changed from 3 to 4 to match step index
            <Alert
              style={{ marginTop: "16px" }}
              message={
                stepStatus.hasAgentRunning
                  ? `Agent is running successfully! (${currentEventCount} events processed)`
                  : `Waiting for events... (${currentEventCount} events processed)`
              }
              type={stepStatus.hasAgentRunning ? "success" : "info"}
              showIcon
            />
          )}
        </div>
      ),
    },
  ];

  const next = async () => {
    try {
      setChecking(true);
      let isStepComplete = false;

      switch (currentStep) {
        case 0: // Privacy
          const piiResponse = await axiosApiInstance.get(
            `${URL_API_V1}/r/${user.organization}/events/pii/field-groups/`
          );
          isStepComplete = piiResponse.data.entries?.length > 0;
          setStepStatus((prev) => ({
            ...prev,
            hasPrivacySetup: isStepComplete,
          }));

          if (!isStepComplete && !privacyAcknowledged) {
            message.warning(
              "Please acknowledge that data is not protected before proceeding"
            );
            return; // Prevent proceeding
          }

          break;

        case 1: // Encryption Keys
          console.log("securityKeys.masterKey:", securityKeys.masterKey);
          console.log("securityKeys.dataEncryptionKey:", securityKeys.dataEncryptionKey);
          isStepComplete = Boolean(securityKeys.masterKey || securityKeys.dataEncryptionKey);
          setStepStatus((prev) => ({
            ...prev,
            hasEncryptionKey: isStepComplete,
          }));
          break;

        case 2: // API Keys
          console.log("securityKeys.apiAccessKey:", securityKeys.apiAccessKey);
          console.log("securityKeys.apiSecretKey:", securityKeys.apiSecretKey);

          isStepComplete = Boolean(
            securityKeys.apiAccessKey || securityKeys.apiSecretKey
          );
          setStepStatus((prev) => ({
            ...prev,
            hasApiKey: isStepComplete,
          }));
          break;

        case 3: // Agent Certificates
          isStepComplete = Boolean(securityKeys.certificateUuid);
          setStepStatus((prev) => ({
            ...prev,
            hasAgentCert: isStepComplete,
          }));
          break;

        case 4: // Agent Running
          const agentResponse = await axiosApiInstance.get(
            `${URL_API_V1}/r/${user.organization}/ingestion/metrics`
          );
          isStepComplete =
            agentResponse.data.details.processed_events_total > 0;
          setStepStatus((prev) => ({
            ...prev,
            hasAgentRunning: isStepComplete,
          }));
          break;
      }

      if (isStepComplete || (currentStep === 0 && privacyAcknowledged)) {
        setCurrentStep(currentStep + 1);
      } else {
        message.warning("Please complete the current step before proceeding");
      }
    } catch (error) {
      console.error("Error checking step status:", error);
      message.error("Failed to check step status");
    } finally {
      setChecking(false);
    }
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleComplete = async () => {
    try {
      setChecking(true);

      if (metricsIntervalRef.current) {
        clearInterval(metricsIntervalRef.current);
        metricsIntervalRef.current = null;
      }

      const agentResponse = await axiosApiInstance.get(
        `${URL_API_V1}/r/${user.organization}/ingestion/metrics`
      );
      const isAgentRunning =
        agentResponse.data.details.processed_events_total > 0;

      if (
        isAgentRunning
      ) {
        console.log(
          "Completing setup with notification UUID:",
          notificationUuid
        );

        if (notificationUuid && notificationUuid.length > 0) {
          try {
            await axiosApiInstance.patch(
              `${URL_API_V1}/r/${user.organization}/user-notifications/${notificationUuid}`,
              { state: "completed" }
            );
            message.success("Setup completed and notification updated");
          } catch (error) {
            console.error("Failed to update notification:", error);
            message.error(
              "Setup completed but failed to update notification status"
            );
          }
        }

        message.success("Setup completed successfully!");

        // Trigger Notifications to refresh
        setRefreshCount(prev => prev + 1);

        onComplete();
      } else {
        message.warning(
          "Please ensure all steps are completed before finishing"
        );
      }
    } catch (error) {
      console.error("Error completing setup:", error);
      message.error("Failed to complete setup");
    } finally {
      setChecking(false);
    }
  };

  const handleCancel = () => {
    if (metricsIntervalRef.current) {
      clearInterval(metricsIntervalRef.current);
      metricsIntervalRef.current = null;
      setCurrentEventCount(0); // Reset the count
    }
    onCancel();
  };

  // Add a function to check if all steps are complete
  const areAllStepsComplete = () => {
    console.log("stepStatus : ", stepStatus);
    return (
      stepStatus.hasAgentRunning
    );
  };

  return (
    <Modal
      className={styles.setupWizard}
      title="Initial Setup Required"
      open={visible}
      closable={false}
      maskClosable={false}
      keyboard={false}
      footer={null}
      width={1000}
      onCancel={handleCancel}
    >
      <Row>
        <Col span={24}>
          <Steps current={currentStep}>
            {steps.map((item, index) => (
              <Step
                key={item.title}
                title={item.title}
                status={
                  index === currentStep
                    ? "process"
                    : index < currentStep
                    ? "finish"
                    : "wait"
                }
                icon={
                  index === currentStep && checking ? (
                    <LoadingOutlined />
                  ) : undefined
                }
              />
            ))}
          </Steps>
        </Col>
      </Row>
      <Row style={{ marginTop: 24, marginBottom: 24 }}>
        <Col span={24}>
          <div className="steps-content" style={{ minHeight: "400px" }}>
            {steps[currentStep].content}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: "right" }}>
          {currentStep > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={prev}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button
              type="primary"
              onClick={next}
              loading={checking}
              disabled={!getCurrentStepStatus()} // Disabled when step isn't complete
            >
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button
              type="primary"
              onClick={handleComplete}
              loading={checking}
              disabled={!areAllStepsComplete()}
            >
              Complete Setup
            </Button>
          )}
          <Button style={{ marginLeft: "8px" }} onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

// Status indicator component for each step
const StepStatusIndicator: React.FC<{
  isComplete: boolean;
  checking: boolean;
  onCheck: () => void;
}> = ({ isComplete, checking, onCheck }) => (
  <Row style={{ marginTop: 16 }}>
    <Col span={24}>
      <Alert
        message={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>
              {isComplete
                ? "Step completed successfully"
                : "This step needs to be completed before proceeding"}
            </span>
            <div>
              <Button
                type="link"
                onClick={onCheck}
                loading={checking}
                icon={
                  isComplete ? (
                    <CheckCircleOutlined style={{ color: "#52c41a" }} />
                  ) : undefined
                }
              >
                {checking ? "Checking..." : "Check Status"}
              </Button>
            </div>
          </div>
        }
        type={isComplete ? "success" : "info"}
        showIcon
      />
    </Col>
  </Row>
);

export default SetupWizard;
