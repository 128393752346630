import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { notification } from "antd";
import { userAtom, userSecretsAtom } from "../common/Atoms";
import AxiosApiInstance from "../common/Interceptors";
import { URL_API_V1 } from "../constants/global";


interface FetchKeysResult {
  success: boolean;
  data?: {
    DECRYPTION_KEYS_OK: boolean;
    ENCRYPTED_DDK: {
      encryption_key: string;
      encryption_key_time_start: string;
    };
  };
  message?: string;
}

export const usePseudonymizationService = () => {
  const { axiosApiInstance } = AxiosApiInstance();
  const [userSecrets, setUserSecrets] = useRecoilState(userSecretsAtom);
  const [user] = useRecoilState(userAtom);

  
  const fetchKeys = useCallback(async (): Promise<FetchKeysResult> => {
    if (!user || !user.organization) {
      return { success: false, message: "User or organization data missing." };
    }
  
    try {
      const response = await axiosApiInstance.get(`${URL_API_V1}/r/${user.organization}/security/encryption-keys/?is_default=true`);
      if (response.status === 200 && response.data.entries && response.data.entries.length > 0) {
        const ddk_first = response.data.entries[0];
        const keysData = {
          DECRYPTION_KEYS_OK: false,
          ENCRYPTED_DDK: {
            encryption_key: ddk_first.encryption_key,
            encryption_key_time_start: ddk_first.encryption_key_time_start,
          }
        };
        return { success: true, data: keysData };
      } else {
        return { success: false, message: "No keys found." };
      }
    } catch (exception: any) {
      return { success: false, message: exception.message || "Error in fetching keys." };
    }
  }, [axiosApiInstance, user?.organization]);
  

  return { fetchKeys };
};
