import {
  Alert, Button,
  Card,
  Col, Form, Input, Layout, Modal, notification, Row
} from "antd";
import { AES_GCM } from "common/AESEncryption";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { userSecretsAtom } from "./Atoms";
import {
  KEY_NAME_IP_DECRYPTION, KEY_NAME_TEXT_DECRYPTION, KEY_NAME_TEXT_DECRYPTION_ENC_KEY
} from "./PseudonymizerService";

import { usePseudonymizationService } from "hooks/usePseudonymizationService";

export default function ModalPromptMasterKey(props) {

  //const [initialized, setInitialized] = useState(false);
  const { fetchKeys } = usePseudonymizationService();
  const [userSecrets, setUserSecrets] = useRecoilState(userSecretsAtom);
  const [form] = Form.useForm();
  const [messages, setMessages] = useState("");
  const [msgType, setMsgType] = useState<any>("info");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
  
    fetchKeys().then(result => {
      if (isMounted) {
        if (result.success && result.data) {
          setUserSecrets(result.data as any);
          setIsLoading(false);
        } else {
          console.error("Failed to load keys:", result.message);
          setIsLoading(false);
        }
      }
    }).catch(error => {
      if (isMounted) {
        console.error("Error fetching keys:", error);
        setIsLoading(false);
      }
    });
  
    return () => { isMounted = false; };
  }, []);
  
  const handleOk = async () => {
    if (isLoading) {
      notification.info({
        message: "Please Wait",
        description: "Still loading decryption keys..."
      });
      return;
    }

    clearMessages();
    try {
      const values = await form.validateFields();
      setMessage("Validating master key...", "info");
      const encryptedDdk = userSecrets.ENCRYPTED_DDK["encryption_key"];
      if (!encryptedDdk) {
        throw new Error("No data decryption key found");
      }

      const dataDecryptionKey = new AES_GCM(values["masterKey"]).decrypt(encryptedDdk);
      const dataDecryptionKey16FirstBytes = dataDecryptionKey.substring(0, 16);

      setUserSecrets(prevState => ({
        ...prevState,
        DECRYPTION_KEYS_OK: true,
        DECRYPTION_KEYS: {
          ...prevState.DECRYPTION_KEYS,
          [KEY_NAME_TEXT_DECRYPTION]: dataDecryptionKey16FirstBytes,
          [KEY_NAME_IP_DECRYPTION]: dataDecryptionKey16FirstBytes,
          [KEY_NAME_TEXT_DECRYPTION_ENC_KEY]: dataDecryptionKey
        }
      }));

      notification.success({
        message: "Master key valid",
        description: "Data decryption key successfully decrypted."
      });
      props.hide();
      clearMessages();
      form.resetFields();
    } catch (error: any) {
      console.error("Error during decryption:", error);
      setMessage(error.message, "error");
      notification.error({
        message: "Decryption Error",
        description: error.message || "Invalid master key. Please check the key and try again."
      });
    }
  };
  
  const setMessage = (msg: string, type: string) => {
    clearMessages()
    setMsgType(type)
    setMessages(msg)
  }


  const clearMessages = () => {
    setMessages("");
    setMsgType("");
  }

  const handleCancel = () => {
    clearMessages()
    props.hide();
  };

  return (
    <Modal
      title="Enter the master key"
      closable={false}
      open={props.isShowing}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      width={800}
      centered
      keyboard={false}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Ok
        </Button>,
      ]}
    >
      <Layout>
        <Card>
          <Form form={form}>
            <Row gutter={24}>
              <Col span={22} offset={6}>
                <Form.Item
                  label="Master key"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 14 }}
                  name="masterKey"
                  rules={[
                    {
                      required: true,
                      message: "Enter the master key to decrypt data!",
                    },
                  ]}
                >
                  <Input.Password
                    // prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Enter the master key to decrypt the data"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={13} offset={6}>
                {messages && <Alert type={msgType} message={messages} showIcon />}
              </Col>
            </Row>
          </Form>
        </Card>
      </Layout>
    </Modal>
  );
}
