import moment from "moment";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATETIME_FORMAT, TIMEZONE } from "../constants/user";
import React from "react";
import { Tooltip } from "antd";

export function getDateWithTooltip(date: string | Date | moment.Moment | null | undefined): JSX.Element {
  const isValidDate = date !== null && date !== undefined && moment(date).isValid();

  const displayDate = isValidDate
    ? moment(date).tz(TIMEZONE).format(DEFAULT_DATE_FORMAT)
    : "";

  const tooltipDate = isValidDate
    ? moment(date).tz(TIMEZONE).format(DEFAULT_DATETIME_FORMAT)
    : "Invalid Date";

    return (
      <Tooltip title={tooltipDate}>
        <span>{displayDate}</span>
      </Tooltip>
    );
}