import { Layout, Space } from 'antd';
import LogoComponent from 'common/LogoComponent';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { reCAPTCHAKEY } from '../../constants/global';
import styles from './PublicLayout.module.scss';
import Version from 'common/Version';
import RecaptchaV3Component from 'common/RecaptchaV3Component';

const { Content, Footer } = Layout;

export default function PublicLayout({ children }) {
  const PublicLayoutForm = () => {
    return (
      <>
        <div
          style={{
            position: 'relative', // Parent must be relative
            height: '100vh', // Full viewport height
            textAlign: 'center', // Center child text
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center', // Center horizontally
              alignItems: 'center', // Center vertically
              height: '100%', // Take full height of the parent
            }}
          >
            <RecaptchaV3Component
              reCAPTCHAKEY={reCAPTCHAKEY}
              useRecaptchaNet={true}
              useEnterprise={true}
              scriptProps={{
                async: false, // optional, default to false,
                defer: false, // optional, default to false
                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                nonce: undefined, // optional, default undefined
              }}
            >
              <Layout>
                <LogoComponent />
                <Content style={{ overflow: 'initial' }}>
                  <Space>{children}</Space>
                </Content>
                <Footer>
                  <PublicNavigation />
                </Footer>
              </Layout>
            </RecaptchaV3Component>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              fontSize: 'small', // Small font size
              textAlign: 'center', // Center text
            }}
          >
            <Version />
          </div>
        </div>
      </>
    );
  };

  const PublicNavigation = () => {
    return <></>;
  };

  return (
    <div className={styles.PublicLayout}>
      <PublicLayoutForm />
    </div>
  );
}
