import { Badge, Button, Select, Switch, Table } from "antd";
import { LockTwoTone, UnlockTwoTone } from "@ant-design/icons";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useEventsTableContext } from "../../contexts/EventsTableContext";
import TableHeader from "./TableHeader";
import { CheckOutlined, CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { OrderedComponent } from "types/EventsTableTypes";
import { usePIIFields } from "../../contexts/PIIFieldsContext";
import { FIELD_TO_EXCLUDE } from "hooks/getEvents";
import { Content } from "antd/es/layout/layout";
import { userSecretsAtom } from "common/Atoms";
import { useRecoilState } from "recoil";

interface EventTableProps {
  fetchEvents: (
    startTime: number,
    endTime: number,
    page: number,
    pageSize: number,
    queryTerms?: any
  ) => Promise<any>;
  setTableData: (data: any[]) => void;
  tableData: any[];
  startTime: number;
  endTime: number;
  continuousView: boolean;
  searchVersion: number;
}

const EventTable: React.FC<EventTableProps> = ({
  fetchEvents,
  setTableData,
  tableData,
  startTime,
  endTime,
  continuousView,
  searchVersion,
}) => {
  //console.log("EventTable()");

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [cacheSize, setCacheSize] = useState(100);
  const [hasMore, setHasMore] = useState(true);
  
  const [userSecrets] = useRecoilState(userSecretsAtom);
  const [, setUserSecrets] = useRecoilState(userSecretsAtom);

  const {
    maskingTechniques,
    piiFields,
    getIconIfPiiField,
    showModalMasterKey,
    setShowModalMasterKey,
  } = usePIIFields();

  const {
    tableColumns,
    tableFields,
    isSelectFieldsViewVisible,
    setIsSelectFieldsViewVisible,
    setContinuousView,
    isLoading,
  } = useEventsTableContext();
  const { decryptedPiiFields, showPIIsInClear, setShowPIIsInClear } =
    usePIIFields();

  const loadMoreData = useCallback(
    async (reset = false) => {
      if (loading || (!hasMore && !reset)) return;

      setLoading(true);
      const newPage = reset ? 1 : page + 1;
      try {
        const newData = await fetchEvents(
          startTime,
          endTime,
          newPage,
          cacheSize
        );
        if (newData) {
          setTableData(reset ? newData : [...tableData, ...newData]);
          setHasMore(newData.length === cacheSize);
          setPage(newPage); // Update the page state only after successful data load
        }
      } catch (error) {
        console.error("Error loading data:", error);
      }
      setLoading(false);
    },
    [loading, hasMore, page, pageSize, fetchEvents, tableData]
  );

  useEffect(() => {
    loadMoreData(true);
  }, [searchVersion]);

  // useEffect(() => {
  //   console.log(
  //     "EventTable useEffect tableData:",
  //     JSON.stringify(tableData, null, 2)
  //   );
  // }, [tableData]);

  useEffect(() => {
    if (tableData) {
      let modifiedData = false;
      tableData.forEach((row) => {
        let rowIngestionId = row["ingestion_id"];
        if (rowIngestionId in decryptedPiiFields) {
          const decryptedRecordKeyValues = decryptedPiiFields["recordId"];

          // Object.keys(decryptedRecordKeyValues).forEach((fieldName) => {
          //   const value = decryptedRecordKeyValues[fieldName]
          //   console.log(fieldName, " => ", value)
          //   const fieldNamePayloadPrefixed = "payload_" + fieldName
          //   if(fieldNamePayloadPrefixed in row) {
          //     row[fieldNamePayloadPrefixed] = value
          //     modifiedData = true;
          //   }
          // })
        }
      });
      if (modifiedData) {
        const updatedData = tableData.map((item, idx) => {
          const other = Object.entries(item)
            .filter(([key]) => !FIELD_TO_EXCLUDE.includes(key))
            .map(([key, value]) => `${key}: ${value}`)
            .join(", ");
          const seq = 1 + idx;
          return { ...item, key: item.ingestion_id, other, seq };
        });
        setTableData([...updatedData]);
      }
    }
  }, [decryptedPiiFields]);

  useEffect(() => {}, [showPIIsInClear]);

  const handleScroll = useCallback(
    debounce(() => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const fullHeight = document.body.scrollHeight;

      const bottomThreshold = 5; // Pixels from the bottom to start loading more data
      if (fullHeight - scrollTop - windowHeight <= bottomThreshold) {
        if (!loading) {
          console.log("near the bottom of the page");
          loadMoreData();
        }
      }
    }, 100),
    [loading, hasMore, loadMoreData]
  );

  useEffect(() => {
    continuousView && window.addEventListener("scroll", handleScroll);
    !continuousView && window.removeEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [continuousView, handleScroll]);

  const tableChangeHandler = useCallback(
    (pagination) => {
      if (pagination.pageSize !== pageSize) {
        setPageSize(pagination.pageSize);
      }
    },
    [pageSize]
  );

  useEffect(() => {
    continuousView && window.addEventListener("scroll", handleScroll);
    !continuousView && window.removeEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [continuousView, handleScroll]);

  const promptMasterKey = () => {
    if (userSecrets.DECRYPTION_KEYS_OK !== true) {
      setShowModalMasterKey(true);
    }
  };

  const clearMasterKey = () => {
    setUserSecrets({ ...userSecrets, DECRYPTION_KEYS_OK: false, DECRYPTION_KEYS: {} });
  };

  const showLockIcon =
  userSecrets.DECRYPTION_KEYS_OK !== true ? (
    <LockTwoTone onClick={promptMasterKey} />
  ) : (
    <UnlockTwoTone twoToneColor="#52c41a" onClick={clearMasterKey} />
  );

  const leftItems: OrderedComponent[] = [
    {
      component: (
        <Button
          type="primary"
          size="small"
          icon={<MenuOutlined />}
          onClick={() =>
            setIsSelectFieldsViewVisible(!isSelectFieldsViewVisible)
          }
          title="Choose fields to display in the table"
        >
          Select Fields
        </Button>
      ),
      order: 1,
    },
    {
      component: (
        <Select
          defaultValue={continuousView}
          style={{ width: 180 }}
          size="small"
          onChange={() => setContinuousView(!continuousView)}
        >
          <Select.Option value={false}>Pagination View</Select.Option>
          <Select.Option value={true}>Continuous View</Select.Option>
        </Select>
      ),
      order: 2,
    },
  ];

  // Define your rightItems with the correct structure
  const rightItems: OrderedComponent[] = [
    {
      component: (
        <>
          <div
            style={{
              display: "flex",
            }}
          >
            <Content style={{ marginRight: "8px" }}>Show in clear: </Content>
          
          <Switch
            title="Show PII fields in clear text"
            defaultChecked={showPIIsInClear}
            onChange={() => setShowPIIsInClear(!showPIIsInClear)}
          />
          </div>
        </>
      ),
      order: 1,
    },
    {
      component: (
        <span style={{ marginRight: "1em", marginLeft: "1em" }}>{showLockIcon}</span>
      ),
      order: 2,
    },
    {
      component: (
        <Badge 
          overflowCount={99999999}
          count={tableData.length}
          showZero
          style={{ backgroundColor: "gray" }}
        />
      ),
      order: 3,
    },
  ];

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <TableHeader leftItems={leftItems} rightItems={rightItems} />
      {isLoading ? (
        <div style={{ textAlign: "center" }}>Loading...</div>
      ) : (
        <Table
          dataSource={tableData}
          columns={tableColumns}
          pagination={
            continuousView
              ? false
              : {
                  pageSize,
                  showSizeChanger: true,
                  onChange: tableChangeHandler,
                  onShowSizeChange: tableChangeHandler,
                }
          }
          loading={isLoading}
          onChange={tableChangeHandler}
        />
      )}

      {!continuousView && hasMore && !loading && (
        <Content style={{ textAlign: "center", margin: "16px" }}>
          <Button type="primary" onClick={() => loadMoreData()}>
            Load more
          </Button>
        </Content>
      )}
    </div>
  );
};

export default EventTable;
