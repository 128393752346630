import {
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";
import EncryptionKeys from "../EncryptionKeys/EncryptionKeys";
import ModalAddField from "./ModalAddField";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Tour } from 'antd';
import type { TourProps } from 'antd';
import { SetupWizardContext } from "components/SetupWizard/SetupWizardContext";

//import "./styles.css";

const { Option } = Select;
const { Content } = Layout;

interface IMaskingTechniques {
  id: number;
  name: string;
  value: string;
  description: string;
  is_two_way: boolean;
}

enum ActionType {
  ADD = "add",
  EDIT = "edit",
}

export default function PiiFields() {
  const addButtonRef = useRef(null);

  const [openTour, setOpenTour] = useState(false);

  const steps: TourProps['steps'] = [
    {
      title: 'Add a Privacy Entry',
      description: 'Click here to add a new privacy entry.',
      target: () => addButtonRef.current,
    },
  ];

  // Ref to track if the Tour has been shown
  const tourShownRef = useRef(false);

  const [maskingTechniques, setMaskingTechniques] = useState<
    IMaskingTechniques[]
  >([]);

  const { axiosApiInstance } = AxiosApiInstance();
  const [user] = useRecoilState(userAtom);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [isAddOrEdit, setIsAddOrEdit] = useState<"add" | "edit">("add");
  const [showAddField, setShowAddField] = useState(false);
  const [hasErrors, setHasErrors] = useState("");
  const [availableFields, setAvailableFields] = useState({});

  const context = useContext(SetupWizardContext);
  const isUsingContext = Boolean(context);
  
  const initialData: any[] = [];
  const [state, setState] = useState({
    loading: true,
    hasData: false,
    data: initialData,
  });

  const setEditVisible = (piiFieldGroup) => {
    setVisible(true);

    let currentFields = Object.keys(availableFields)
      .filter((fieldId) => piiFieldGroup.fields.includes(parseInt(fieldId)))
      .map((key) => {
        return { value: key, label: availableFields[key]["name"] };
      });

    form.setFieldsValue({
      id: piiFieldGroup.id,
      name: piiFieldGroup.name,
      maskingTechnique: piiFieldGroup.masking_technique_id,
      fields: currentFields,
    });
  };

  const setAddVisible = () => {
    setIsAddOrEdit(ActionType.ADD);
    setVisible(true);
  };

  const createOrUpdate = (form, action) => {
    setHasErrors("");
    setIsAddOrEdit(action);

    if (isAddOrEdit === ActionType.ADD) {
      addPiiField(form);
    } else {
      updatePiiField(form);
    }
  };

  const updatePiiField = (form) => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          name: values["name"],
          masking_technique_id: values["maskingTechnique"],
        };

        if (values["fields"] && values["fields"].length > 0) {
          data["fields"] = values["fields"].map((field) => {
            if (typeof field == "object" && field.hasOwnProperty("value")) {
              return field.value;
            }
            console.log(field);
            return field;
          });
        }

        axiosApiInstance
          .put(
            `${URL_API_V1}/r/${user.organization}/events/pii/field-groups/${values["id"]}/`,
            data
          )
          .then((response) => {
            setState({ ...state, loading: false });
            if (response && response.status === 200) {
              getPiiFieldGroups();
              setVisible(false);
            }
            form.resetFields();
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response?.data);
            if (err.response?.data?.reason) {
              console.log(err.response.data.reason);
              setHasErrors(err.response.data.reason);
            } else if (err.response?.data) {
              setHasErrors(err.response.data[0]);
            }
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const addPiiField = (form) => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          name: values["name"],
          masking_technique_id: values["maskingTechnique"],
        };

        if (values["fields"] && values["fields"].length > 0) {
          data["fields"] = values["fields"];
        }

        axiosApiInstance
          .post(
            `${URL_API_V1}/r/${user.organization}/events/pii/field-groups`,
            data
          )
          .then((response) => {
            setState({ ...state, loading: false });
            //console.log("getPiiFields:" + JSON.stringify(responseData));
            if (response && response.status === 201) {
              getPiiFieldGroups();
              setVisible(false);
            }
            form.resetFields();
          })
          .catch((err) => {
            if (err.response?.data?.reason) {
              console.log(err.response.data.reason);
              setHasErrors(err.response.data.reason);
            } else if (err.response?.data) {
              setHasErrors(err.response.data[0]);
            } else {
              setHasErrors("An unexpected error has occured");
            }
          });
      })
      .catch((err) => {
        console.log("Validate Failed:", err);
      });
  };

  const deleteItem = (row) => {
    setState({ ...state, loading: true });

    axiosApiInstance
      .delete(
        `${URL_API_V1}/r/${user.organization}/events/pii/field-groups/${row.id}`
      )
      .then((response) => {
        setState({ ...state, loading: false });
        if (response.status === 200) {
          getPiiFieldGroups();
        } else {
          setState({ ...state, data: [] });
        }
      })
      .catch(() => {
        console.log("an error has occurred..");
      });
  };

  const cancel = (form) => {
    form.resetFields();
    setHasErrors("");
    setVisible(false);
  };

  // const trimMiddle = (str, length_limit) => {
  //   return str.length > length_limit
  //     ? str.substring(0, length_limit) +
  //         "..." +
  //         str.substring(str.length - length_limit, str.length)
  //     : "";
  // };

  // const trimEnd = (str, length_limit) => {
  //   return str.length > length_limit
  //     ? str.substring(0, length_limit) + "..."
  //     : "";
  // };

  const getNormalizedField = (row, item): string => {
    const fieldFound = row["normalized_fields"].filter(
      (entry) => entry["old_field"] === item["name"]
    );
    if (fieldFound.length > 0) {
      return fieldFound[0]["new_field"];
    } else {
      return "";
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        return text;
      },
    },
    {
      title: (
        <Tooltip
          overlayStyle={{ maxWidth: "450px" }} // or use 'width' for fixed width
          title={
            <>
              <div>Masking Technique can be one of:</div>
              <div>
                {" "}
                - <b>AES-GCM:</b>Used for any other types. Offers highest
                security but least usability. Identical input values will
                generate distinct encrypted values (non-deterministic).
              </div>
              <div>
                {" "}
                - <b>AES-GCM DET:</b>Used for any types of values. Offers
                reduced security level but higher usability. Identical input
                values will generate identical output values (determinsitic)
              </div>
              <div>
                {" "}
                - <b>CryptopANT IPv4/IPv6:</b>Used only for IPs. Offers reduced
                security level but higher usability (non-deterministic).
              </div>
              <div>
                {" "}
                - <b>HMAC-SHA256:</b>Used for any type of values. Uses reduced
                security level but higher usability (non-deterministic).
              </div>
            </>
          }
        >
          <a>Masking Technique Name</a>
        </Tooltip>
      ),
      dataIndex: "masking_technique_value",
      key: "masking_technique_value",
      render: (text, row) => {
        return (
          <Tag color="purple" key={row["masking_technique_value"]}>
            {row["masking_technique_name"]}
          </Tag>
          // <Select disabled defaultValue={text} style={{ width: `${8 * text.length + 100}px` }}>
          //   {maskingTechniques.map((maskingTechnique) => (
          //     <Option value={maskingTechnique["value"]} key={maskingTechnique["value"]} title={getMaskingTechniqueDescription(row["masking_technique_value"])}>
          //       {maskingTechnique["name"]}
          //     </Option>
          //   ))}
          // </Select>
        );
      },
    },
    {
      title: (
        <Tooltip
          title={
            <>
              PII Fields: These fields will be matched at the input level by the
              log shipper (i.e. fluent-bit) before they leave customer's
              network.
            </>
          }
        >
          <a>PII Fields</a>
        </Tooltip>
      ),
      dataIndex: "piifields",
      search: true,
      render: (text, row) => (
        <Space
          size="middle"
          style={{
            display: "block",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "flexStart",
          }}
        >
          {row["fields_names"].map((item) => (
            <span>
              <Tag color="blue" className="cellTag" key={item}>
                {item["name"]}
              </Tag>
              <ArrowRightOutlined />
            </span>
          ))}
        </Space>
      ),
    },
    {
      title: (
        <Tooltip
          title={
            <>
              Normalized fields: These fields are renamed from PII Fields (i.e.
              input logs) to the specified fields for consistency and
              normalization.
            </>
          }
        >
          <a>Normalized fields</a>
        </Tooltip>
      ),
      dataIndex: "normalized_fields",
      search: true,
      render: (text, row) => (
        <Space
          size="middle"
          style={{
            display: "block",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "flexStart",
          }}
        >
          {row["fields_names"].map((item) => (
            <Tag color="blue" className="cellTag" key={item}>
              {getNormalizedField(row, item)}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      search: false,
      render: (text, row) => (
        <>
          <EditOutlined
            style={{ paddingRight: "10px" }}
            onClick={() => setEditVisible(row)}
          />
          <DeleteOutlined onClick={() => deleteItem(row)} />
        </>
      ),
    },
  ];

  function onFinish() {
    getPiiFieldGroups();
  }

  function getMaskingTechniques() {
    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/events/masking-techniques/`)
      .then((response) => {
        let results: any = [];
        if (response.status === 200) {
          results = response.data;
          if ("entries" in results) {
            results = results["entries"];
          }
        }
        console.log("getMaskingTechniques:" + JSON.stringify(results));
        setMaskingTechniques(results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    form
      .validateFields()
      .then(() => {
        getAllFields();
        getMaskingTechniques();
      })
      .then(() => {
        getPiiFieldGroups();
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getAllFields() {
    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/events/fields/`)
      .then((response) => {
        let results: any = [];
        if (response.status === 200) {
          results = response.data;
          if ("entries" in results) {
            results = results["entries"];
          }
        }
        let fields = {};
        for (let i = 0; i < results.length; i++) {
          fields[results[i].id] = results[i];
        }
        console.log("setting the field");
        setAvailableFields(fields);
      })
      .catch((exception) => {
        console.log("exception getAllFields.." + exception);
      });
  }

  function getPiiFieldGroups() {
    setState(prevState => ({ ...prevState, loading: true }));
    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/events/pii/field-groups/`)
      .then((response) => {
        let results = [];
        if (response && response.status === 200) {
          const responseData = response.data;
          if (responseData && "entries" in responseData) {
            results = responseData["entries"];
          }
        }
        setState(prevState => ({
          ...prevState,
          loading: false,
          data: results,
          hasData: results.length > 0,
        }));
      })
      .catch((exception) => {
        console.log("getPiiFields getUserProfile.." + exception);
        setState(prevState => ({
          ...prevState,
          loading: false,
          data: [],
          hasData: false,
        }));
      });
  }
  

  useEffect(() => {
    if (!state.loading) {
      if (state.data.length === 0 && isUsingContext && !tourShownRef.current) {
        setOpenTour(true);
        tourShownRef.current = true;
      } else {
        setOpenTour(false);
      }
    }
  }, [state.loading, state.data]);
  

  const getTitle =
    isAddOrEdit === ActionType.ADD
      ? "Add new PII field group"
      : "Edit PII field group";
      return (
        <div>
          <Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} />
          <Row>
            <Col span={24}>
              <h3>Privacy</h3>
              <p>Manage protection of Personally Identifiable Information (PII)</p>
            </Col>
            <Col span={24}>
              <div>
                <Form form={form} onFinish={onFinish}>
                  <Col style={{ width: "100%" }}>
                    <div style={{ marginBottom: "1em", textAlign: "right" }}>
                      <Button
                        ref={addButtonRef}
                        style={{ marginBottom: "1em", textAlign: "right" }}
                        htmlType="button"
                        type="primary"
                        onClick={setAddVisible}
                      >
                        Add
                      </Button>
                      <Table
                        rowKey="id"
                        pagination={false}
                        columns={columns}
                        dataSource={state.data}
                      />
                    </div>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>


      <Modal
        title={getTitle}
        centered
        open={visible}
        width={600}
        onOk={() => createOrUpdate(form, ActionType.ADD)}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => cancel(form)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => createOrUpdate(form, ActionType.EDIT)}
          >
            Submit
          </Button>,
        ]}
      >
        <Content>
          <Form form={form} onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name="id" hidden />
                <Form.Item label="Name" name="name" required>
                  <Input placeholder="PII Field Group Name" required />
                </Form.Item>
              </Col>
              <Col></Col>

              <Col span={24}>
                <Form.Item
                  label="Masking Technique"
                  name="maskingTechnique"
                  required
                >
                  <Select
                    style={{ width: "100%" }}
                    disabled={isAddOrEdit === "edit"}
                  >
                    {maskingTechniques.map((maskingTechnique) => (
                      <Option
                        value={maskingTechnique["id"]}
                        key={maskingTechnique["value"]}
                      >
                        {maskingTechnique["name"]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={20}>
                <Input.Group compact>
                  <Form.Item
                    style={{ width: "100%" }}
                    label="PII fields (input)"
                    name="fields"
                  >
                    <Select
                      showArrow
                      showSearch
                      mode="multiple"
                      placeholder="Select PII fields"
                      filterOption={(input, option) => {
                        return (
                          option?.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      options={Object.keys(availableFields).map((key) => {
                        return {
                          value: parseInt(key),
                          label: availableFields[key]["name"],
                        };
                      })}
                    />
                  </Form.Item>
                </Input.Group>
              </Col>
              <Col span={2}>
                <Tooltip title="Add new field">
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => setShowAddField(!showAddField)}
                  >
                    Add field
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Form>
        </Content>
        <Content>
          {hasErrors && hasErrors.length > 0 && (
            <Alert type="error" message={hasErrors} banner />
          )}
        </Content>
      </Modal>
      <ModalAddField
        isShowing={showAddField}
        hide={() => setShowAddField(!showAddField)}
        getAllFields={getAllFields}
      />
        </div>
      );
  }