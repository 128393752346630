import React from 'react';
import { LuCircleEqual } from "react-icons/lu";
import { useEventsTableContext } from '../../contexts/EventsTableContext';
import { usePIIFields } from '../../contexts/PIIFieldsContext';

interface TableCellRendererProps {
  fieldName: string;
  text: string;
  recordId: string;
}

export const TableCellRenderer: React.FC<TableCellRendererProps> = ({ fieldName, text, recordId }) => {
    const { getIconIfPiiField } = usePIIFields();
    const { appendEqualsFilter } = useEventsTableContext();



    return (
        <div>
            {text || "-"}
            {text && (
                <LuCircleEqual
                    title="Filter by this value"
                    onClick={(event) => {
                        event.stopPropagation();
                        appendEqualsFilter(fieldName, text);
                    }}
                />
            )}
            {getIconIfPiiField("TableCellRenderer used to render columns", fieldName, text, recordId)}
        </div>
    );
};
