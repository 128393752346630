import Icon, { GithubOutlined, GoogleOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Form, Input, Space } from 'antd';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { URL_API_V1, ENABLE_SOCIAL_LOGIN } from 'constants/global';
import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/auth';
import styles from './Login.module.scss';
import { reCAPTCHAKEY } from '../../constants/global';

const Login = (props) => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { loginUser, error, confirmationMessage } = useAuth();
  const [form] = Form.useForm();

  const handleSubmit = async (values, recaptchaToken) => {
    values['response'] = recaptchaToken;
    const success = await loginUser({
      ...values,
    });
    console.log('success:' + success);
    if (success) {
      navigate('/dashboard');
    }
  };

  const handleReCaptchaVerify = useCallback(
    async (values) => {
      let token = '';
      if (reCAPTCHAKEY) {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }
        token = await executeRecaptcha('login');
        handleSubmit(values, token);
      } else {
        handleSubmit(values, token);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [executeRecaptcha]
  );

  const generateState = () => {
    const state = crypto.randomUUID(); // or any other method to generate a unique ID
    localStorage.setItem('oauthState', state);
    return state;
  };

  const handleSocialLogin = (provider) => {
    const state = generateState();
    axios
      .get(`${URL_API_V1}/${provider}/login?state=${state}`)
      .then((resp) => {
        if (resp.status === 200) {
          const data: any = resp.data;
          console.log(data);
          window.location.href = data['authUrl'];
        } else {
          console.error(resp.data);
        }
      })
      .catch((err) => {
        console.error(err.response.data);
      });
    //window.location.replace(`${URL_API_V1}/${provider}/login`)
  };

  const LoginForm = () => {
    return (
      <div className="" style={{}}>
        <Form className="login-form" form={form} onFinish={handleReCaptchaVerify}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" autoComplete="off" />
          </Form.Item>
          {error && error.includes('not verified') && (
            <Form.Item
              name="passcode_user_verification"
              rules={[
                {
                  required: true,
                  message: 'Please input your passcode!',
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Verification passcode"
                autoComplete="off"
              />
            </Form.Item>
          )}
          {error.length > 0 && (
            <div>
              <Alert style={{ marginBottom: '15px' }} message={error} type="error" showIcon closable />
            </div>
          )}
          {confirmationMessage && (
            <div>
              <Alert style={{ marginBottom: '15px' }} message={confirmationMessage} type="success" showIcon closable />
            </div>
          )}
          <Form.Item wrapperCol={{}}>
            <Button className={styles.LoginButton} type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
          <Link className={`${styles.LoginFormRegister}`} to={'/register'}>
            Create new account!
          </Link>{' '}
          |{' '}
          <Link className={styles.LoginFormForgot} to={'/forgot-credentials'}>
            Forgot username or password
          </Link>
        </Form>

        {ENABLE_SOCIAL_LOGIN && ENABLE_SOCIAL_LOGIN === 'true' && (
          <>
            <Divider plain style={{ color: 'gray' }}>
              Or Sign in
            </Divider>

            <div style={{ textAlign: 'center' }}>
              <div>
                <Button onClick={() => handleSocialLogin('google')} style={{ width: '250px' }} icon={<GoogleOutlined />}>
                  Sign in with Google
                </Button>
              </div>
              <div style={{ margin: '5px' }} />
              <div>
                <Button
                  onClick={() => handleSocialLogin('azure')}
                  style={{ width: '250px' }}
                  icon={<Icon component={() => <img width={18} height={18} src="images/microsoft.svg" />} />}
                >
                  Sign in with Microsoft
                </Button>
              </div>
              <div style={{ margin: '5px' }} />
              <div>
                <Button onClick={() => handleSocialLogin('github')} style={{ width: '250px' }} icon={<GithubOutlined />}>
                  Sign in with Github
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };
  return <LoginForm />;
};

export default Login;
