import React from "react";
import { OrderedComponent } from "types/EventsTableTypes";

const TableHeader = ({
  leftItems,
  rightItems,
}: {
  leftItems: OrderedComponent[];
  rightItems: OrderedComponent[];
}) => {
  return (
    <div
      style={{
        padding: "8px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {leftItems
          .sort((a, b) => a.order - b.order)
          .map((item, index) => (
            <span
              key={index}
              style={{
                paddingLeft: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {item.component}
            </span>
          ))}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {rightItems
          .sort((a, b) => a.order - b.order)
          .map((item, index) => (
            <span
              key={index}
              style={{
                paddingLeft: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {item.component}
            </span>
          ))}
      </div>
    </div>
  );
};

export default TableHeader;
