import { Tag } from "antd";
import { usePIIFields } from "../../contexts/PIIFieldsContext";

const FIELD_NAME_TIMESTAMP = "_timestamp";
const FIELD_NAME_EVENT_TYPE = "meta_ss_event_type";
const FIELD_NAME_INGESTION_ID = "ingestion_id";

interface EventDetailsRendererProps {
  text: string;
  event: Event;
  setSelectedEntry: (event: Event) => void;
}

export const EventDetailsRenderer: React.FC<EventDetailsRendererProps> = ({
  text,
  event,
  setSelectedEntry,
}) => {
  const handleClick = () => {
    setSelectedEntry(event);
  };

  const {
    maskingTechniques,
    piiFields,
    decryptedPiiFields,
    isLoading,
    showPIIsInClear,
    getIconIfPiiField,
  } = usePIIFields();

  return (
    <div
      style={{ overflowWrap: "anywhere", maxWidth: "100%" }}
      onClick={handleClick}
    >
      {text.split(", ").map((entry, index) => {
        const [fullKey, value] = entry.split(": ");
        const key = fullKey.replace(/^(payload_|meta_)/, "");

        return (
          <span key={`${key}-${index}`}>
            <Tag
              style={{ border: "none", padding: "0 0 0 0", margin: "0 5px 0 0" }}
              title={fullKey}
              color="geekblue"
            >
              {key}:
              <Tag
                style={{
                  border: "none",
                  padding: "0 0px 0 0",
                  margin: "0 0px 0 0",
                
                  whiteSpace: "normal",
                  wordBreak: "break-all",
                }}
                title={value}
              >
                {value}
                {/* Assume getIconIfPiiField is defined elsewhere appropriately */}
                {getIconIfPiiField("EventDetailsRenderer used to render columns", fullKey, value, event[FIELD_NAME_INGESTION_ID])}
              </Tag>
            </Tag>
          </span>
        );
      })}
    </div>
  );
};
