import React, { useState, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { userSecretsAtom, userAtom } from '../common/Atoms';
import AxiosApiInstance from '../common/Interceptors';
import { URL_API_V2 } from '../constants/global';

export const FIELD_TO_EXCLUDE = ['_timestamp', 'meta_ss_event_type', 'other'];

export const useFetchEvents = (initialQueryTerms) => {
  const { axiosApiInstance } = AxiosApiInstance();
  const [events, setEvents] = useState<EventData[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [histogram, setHistogram] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [queryTerms, setQueryTerms] = useState(initialQueryTerms);
  const [user] = useRecoilState(userAtom);

  const processRawData = (rawData) => {
    return rawData.map((item, idx) => {
      const other = Object.entries(item)
        .filter(([key]) => !FIELD_TO_EXCLUDE.includes(key))
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ');
      const seq = 1 + idx;
      return { ...item, key: item.ingestion_id, other, seq };
    });
  };

  const fetchEvents = useCallback(
    async (startTime, endTime, page, pageSize, currentQueryTerms) => {
      console.log('Fetching events: startTime:', startTime, 'endTime:', endTime, 'page:', page, 'pageSize:', pageSize);
      setLoading(true);
      setError(null);

      const params = {
        size: pageSize,
        from: page * pageSize,
        startTime,
        endTime,
        queryTerms: JSON.stringify(currentQueryTerms),
      };
  
      try {
        
        console.log('Fetching events with params:', params)
      
        const responseData: any = await axiosApiInstance.get(`${URL_API_V2}/r/${user.organization}/events`, { params });
        
        if (responseData.status === 200) {
          const rawData: EventData[] = (responseData.data as EventData[])['hits'];
          const histogramData: any[] = (responseData.data as EventData[])['aggs']['histogram'];
          const updatedData = processRawData(rawData);
  
          setEvents(updatedData);
          setHistogram(histogramData);
          setHasMore(rawData.length === pageSize);
  
          return updatedData;
        } else {
          throw new Error('Failed to fetch events');
        }
      } catch (error: any) {
        console.error('Error fetching events:', error);
        setError(error);
        setHasMore(false);
        return [];
      } finally {
        setLoading(false);
      }
    },
    [user.organization]
  );

  return { events, loading, error, histogram, fetchEvents, hasMore, setQueryTerms, queryTerms };
};