// EncryptionKeys.tsx
import {
  CopyOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Tooltip, Tour } from 'antd';
import type { TourProps } from 'antd';
import type { DatePickerProps } from "antd";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from "antd";
import { AES_GCM } from "common/AESEncryption";
import * as crypto from "crypto-browserify";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import { useRecoilState } from "recoil";
import zxcvbn from "zxcvbn";
import { userAtom } from "../../common/Atoms";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1, DATA_SAMPLING_MASTER_KEY } from "../../constants/global";
import { SetupWizardContext } from '../SetupWizard/SetupWizardContext';
import styles from "./EncryptionKeys.module.scss";
import dayjs from "dayjs";
import { getDateWithTooltip } from "utils/utils";

const { Paragraph } = Typography;
const { Footer, Content } = Layout;

const MASTER_KEY = "masterKey";
const DATA_ENCRYPTION_KEY = "dataEncryptionKey";
const dateFormat = "YYYY-MM-DD";

const enum CharacterType {
  LETTER_LOWERCASE = 0,
  LETTER_UPPERCASE = 1,
  LETTER_DIGIT = 2,
  LETTER_SPECIAL = 3,
}

interface EncryptionKeysProps {
  onEncryptionKeysChange?: (hasKeys: boolean) => void;
}

interface EncryptionKey {
  uuid: string;
  created_on: string;
  encryption_key: string;
  encryption_key_time_start: string;
  encryption_key_time_end: string;
  contains_encrypted_data: boolean;
  encryption_purpose: string;
  is_active: boolean;
}

const EncryptionKeys: React.FC<EncryptionKeysProps> = ({ onEncryptionKeysChange }) => {
  const context = useContext(SetupWizardContext);
  const { setSecurityKeys } = context || { setSecurityKeys: () => {} };

  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();

  const isUsingContext = Boolean(context);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [isEncryptionKeyHover, setIsEncryptionKeyHover] = useState(false);
  const [
    isEncryptionKeyCopyClipboardHover,
    setIsEncryptionKeyCopyClipboardHover,
  ] = useState(false);
  const [isMasterKeyHover, setIsMasterKeyHover] = useState(false);
  const [isMasterKeyCopyClipboardHover, setIsMasterKeyCopyClipboardHover] =
    useState(false);

  const [masterKeyVisible, setMasterKeyVisible] = useState(false);
  const [dataEncryptionKeyVisible, setDataEncryptionKeyVisible] =
    useState(false);

  const [newRecord, setNewRecord] = useState<EncryptionKey>();
  const [masterKey, setMasterKey] = useState("");
  const [dataEncryptionKey, setDataEncryptionKey] = useState("");
  const [generateMasterKeyDisabled, setGenerateMasterKeyDisabled] =
    useState(false);

  const [validatorMasterKey, setValidatorMasterKey] = useState([] as any);
  const [validatorEncryptionKey, setValidatorEncryptionKey] = useState(
    [] as any
  );

  const addButtonRef = useRef<HTMLButtonElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const [openTour, setOpenTour] = useState(false);

  // Ref to track if the Tour has been shown
  const tourShownRef = useRef(false);

  const [masterKeyScore, setMasterKeyScore] = useState({} as any);
  const [encryptionKeyScore, setEncryptionKeyScore] = useState({} as any);

  const [openPopupConfirm, setOpenPopupConfirm] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [hasErrors, setHasErrors] = useState("");

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const showPopconfirm = () => {
    setOpenPopupConfirm(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setOpenPopupConfirm(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    form.resetFields();
    setVisible(false);
  };

  const onChangeValidFrom: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
    console.log(date?.format(dateFormat));
  };

// Define Tour steps without useMemo
const steps: TourProps['steps'] = [
  {
    title: 'Add an Encryption Key',
    description: 'Click here to add a new encryption key.',
    target: () => addButtonRef.current!, // Non-null assertion
  },
  {
    title: 'Select an Encryption Key',
    description: 'Select an encryption key from the list.',
    target: () => tableRef.current!.querySelector<HTMLElement>('.ant-table-selection-column')!, // Non-null assertion
  },
];

  const initialData: any[] = [];
  const [state, setState] = useState({
    loading: true,
    hasData: false,
    data: initialData,
    showPIIsInClear: false,
  });

  const setKeyAsDefault = (uuid) => {
    const newData = { is_active: true };
    axiosApiInstance
      .patch(
        `${URL_API_V1}/r/${user.organization}/security/encryption-keys/${uuid}`,
        newData
      )
      .then((res) => {
        notification.success({
          message: "Success",
          description: "Key set as default successfully",
        });
        loadDataEncryptionKey();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
      });
  };

  const deactivateKey = (uuid: string) => {
    const newData = { is_active: false };
    axiosApiInstance
      .patch(
        `${URL_API_V1}/r/${user.organization}/security/encryption-keys/${uuid}`,
        newData
      )
      .then((res) => {
        notification.success({
          message: "Success",
          description: "Key deactivated successfully",
        });
        loadDataEncryptionKey();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
      });
  };

  const markKeyForDeletion = (uuid: string) => {
    const newData = { mark_for_deletion: true };
    axiosApiInstance
      .patch(
        `${URL_API_V1}/r/${user.organization}/security/encryption-keys/${uuid}`,
        newData
      )
      .then((res) => {
        notification.success({
          message: "Success",
          description: "Key marked for deletion successfully",
        });
        loadDataEncryptionKey();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: "Failed to mark key for deletion",
        });
      });
  };

  const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    console.log("selectedRows changed: ", selectedRows);
    //lookup masterKey if it matches the recently created key
    setSelectedRowKeys(selectedRowKeys);
    if (context && selectedRows.length > 0) {
      const selectedKey = selectedRows[0].uuid;
      context.setSecurityKeys((prevKeys) => ({
        ...prevKeys,
        masterKey: (selectedKey === newRecord?.uuid) ? masterKey : '',
        dataEncryptionKey: selectedRows[0].encryption_key,
      }));
      console.log("Selected masterKey set in context:", selectedKey);
    } else if (context) {
      // If no selection, clear the masterKey
      context.setSecurityKeys((prevKeys) => ({
        ...prevKeys,
        masterKey: '',
        dataEncryptionKey: '',
      }));
      console.log("Master key cleared in context.");
    }
  };

  const columns = [
    {
      title: "Data Encryption Key",
      dataIndex: "encryption_key",
      render: (text, row) => {
        return text.length > 12
          ? text.substring(0, 12) +
          "..."
          : "";
      },
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      render: (text: string) => (
        getDateWithTooltip(text)
      ),
    },
    {
      title: "Valid from",
      dataIndex: "encryption_key_time_start",
      render: (text: string) => (
        getDateWithTooltip(text)
      ),
    },
    {
      title: "Valid to",
      dataIndex: "encryption_key_time_end",
      render: (text: string) => (
        getDateWithTooltip(text)
      ),
    },
    {
      title: "Is used",
      dataIndex: "contains_encrypted_data",
      render: (text, row) => (
        row.contains_encrypted_data ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>
      ),
    },
    // {
    //   title: "Purpose",
    //   render: (text, row) =>
    //     <Tag color={"purple"} key={row.purpose}>
    //       {row.purpose === "DEMO_DATA_SAMPLING" ? "Demo Data Sampling" : "Data Encryption"}
    //     </Tag>
    // },
    {
      title: "Is Active",
      dataIndex: "is_active",
      render: (text, row) => {
        const handleDelete = (e) => {
          e.stopPropagation(); // Prevents row onClick
          markKeyForDeletion(row.uuid);
        };
  
        const handleDeactivate = (e) => {
          e.stopPropagation(); // Prevents row onClick
          deactivateKey(row.uuid);
        };
  
        const handleActivate = (e) => {
          e.stopPropagation(); // Prevents row onClick
          setKeyAsDefault(row.uuid);
        };
  
        if (row.encryption_key_time_end) {
          return (
            <>
              <Tag color="red">Deactivated</Tag>{" "}
              <Button 
                type="link" 
                onClick={handleDelete}
                danger
              >
                Delete
              </Button>
            </>
          );
        }
  
        if (row.is_active) {
          return (
            <>
              <Tag color="green">Yes</Tag>{" "}
              <Button 
                type="link" 
                onClick={handleDeactivate}
                danger
              >
                Deactivate
              </Button>
            </>
          );
        }
  
        if (!row.encryption_key_time_start) {
          return (
            <>
              <Tag color="purple">No</Tag>{" "}
              <Button 
                type="link" 
                onClick={handleActivate}
              >
                Activate
              </Button>
            </>
          );
        }
  
        return <Tag color="orange">Inactive</Tag>;
      },
    },
    // {
    //   title: "Action",
    //   render: (text, row) => (
    //     <Space size="middle" >
    //       <Dropdown menu={{items: items(row)}}
    //         trigger={["click"]}
    //         className="ant-dropdown-link">
    //         <Button onClick={() => setSelectedEventId(row.id)}>Action <DownOutlined /></Button>
    //       </Dropdown>
    //     </Space>
    //   ),
    // },
  ];


  const loadDataEncryptionKey = useCallback(async () => {
    // Start loading
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
  
    // Check if user and organization exist
    if (!user || !user.organization) {
      console.warn("User or organization not found.");
      setState((prevState) => ({
        ...prevState,
        loading: false,
        hasData: false,
        data: [],
      }));
      return;
    }
  
    try {
      // Make the API call
      const response = await axiosApiInstance.get(
        `${URL_API_V1}/r/${user.organization}/security/encryption-keys/`
      );
  
      // Check for successful response
      if (response.status === 200) {
        const entries = response.data?.entries || [];
        setState((prevState) => ({
          ...prevState,
          loading: false,
          hasData: entries.length > 0,
          data: entries,
        }));
      } else {
        console.warn(`Unexpected status code: ${response.status}`);
        setState((prevState) => ({
          ...prevState,
          loading: false,
          hasData: false,
          data: [],
        }));
      }
    } catch (error) {
      console.error("Failed to load data encryption keys:", error);
  
      // Reset state and data list on error
      setState((prevState) => ({
        ...prevState,
        loading: false,
        hasData: false,
        data: [],
      }));
    }
  }, [user]);


  useEffect(() => {
    if (onEncryptionKeysChange) {
      onEncryptionKeysChange(selectedRowKeys && selectedRowKeys.length > 0);
    }
  }, [state.data, onEncryptionKeysChange]);

  const encryptAndStore = (form) => {
    handleOk();

    console.log("encryptAndStore");
    let dataEncryptionKey = form.getFieldValue(DATA_ENCRYPTION_KEY);
    console.log(dataEncryptionKey);
    let masterKey = form.getFieldValue(MASTER_KEY);
    console.log(masterKey);

    let dataEncryptionKeyCT = new AES_GCM(masterKey).encrypt(dataEncryptionKey);

    console.log(dataEncryptionKeyCT);
    let pt = new AES_GCM(masterKey).decrypt(dataEncryptionKeyCT);
    console.log(pt);

    console.log("submitting..");
    form
      .validateFields()
      .then((values) => {
        if (validatorEncryptionKey.length > 0) {
          throw new Error(validatorEncryptionKey);
        }
        const data = {
          encryption_key: dataEncryptionKeyCT,
          encryption_key_time_start: values["validFrom"].format(dateFormat),
          encryption_purpose: values["encryption_purpose"],
        };
        console.log(data);
        console.log("posting now..");
        axiosApiInstance
          .post(
            `${URL_API_V1}/r/${user.organization}/security/encryption-keys/`,
            data
          )
          .then((response) => {
            //const responseData: any = response.data;
            //console.log("getPiiFields:" + JSON.stringify(responseData));
            if (response.status === 201) {
              notification.success({
                message: `Stored data encryption key in encrypted form.`,
              });
              setNewRecord(response.data);
            } else {
              console.log("create failed..");
            }
            form.resetFields();
            setOpenPopupConfirm(false);
            setVisible(false);
          })
          .catch((err) => {
            console.log("error:");
            console.log(err);
            const keys = Object.keys(err.response.data);
            console.log(err.response.data);
            if (err.response?.data?.reason) {
              console.log("error detail:");
              console.log(err.response.data.reason);
              //setHasAddConfigurationErrors(err.response.data.reason);
            } else {
              //setHasAddConfigurationErrors(err.response.data[keys[0]]);
              console.log("error detail2:");
              console.log(err.response.data[keys[0]]);
              setHasErrors(err.response.data[keys[0]]);
            }
            console.log("post failed.." + JSON.stringify(err));
          });
      })
      .catch((err) => {
        console.log("Validate Failed:", err);
        notification.error({
          message: `Failed to store data encryption key in encrypted form.`,
        });
      });
    console.log("adding new entry..");
  };

  useEffect(() => {
    if (context && masterKey) {
      console.log("setting master key..", masterKey);
      context.setSecurityKeys((prevKeys) => ({
        ...prevKeys,
        masterKey,
      }));
    }
  }, [masterKey]);

  // useEffect(() => {
  //   setGenerateMasterKeyDisabled(generateMasterKeyDisabled)
  // }, [generateMasterKeyDisabled])

  function showMasterKeyModalForm() {
    console.log("settin up");
    console.log(form.getFieldValue("validFrom"));

    setVisible(true);
  }

  function getRandomArbitrary(min, max) {
    return Math.floor(randomFloat() * (max - min + 1) + min);
  }

  const randomFloat = function () {
    const int = window.crypto.getRandomValues(new Uint32Array(1))[0];
    return int / 2 ** 32;
  };

  function generateEncryptionKeys(
    fieldName: string,
    hasSpecialCharacteres: boolean,
    minSize: number,
    maxSize: number
  ) {
    console.log("generateEncryptionKeys: " + fieldName);

    let global_counter = 1000;

    let buffer = [] as any;
    let length = getRandomArbitrary(minSize, maxSize);
    for (var i = 0; buffer.length < length; i++) {
      if (i > global_counter) {
        break;
      }

      let bytes = crypto.randomBytes(length);

      let includeSpecialCharacter = CharacterType.LETTER_SPECIAL;
      if (hasSpecialCharacteres !== true) {
        includeSpecialCharacter = CharacterType.LETTER_DIGIT;
      }

      for (var j = 0; j < bytes.length; j++) {
        let choice = getRandomArbitrary(
          CharacterType.LETTER_LOWERCASE,
          includeSpecialCharacter
        );

        if (choice === CharacterType.LETTER_SPECIAL) {
          // reduce odd of generating special characters
          choice = getRandomArbitrary(
            CharacterType.LETTER_LOWERCASE,
            CharacterType.LETTER_SPECIAL
          );
        }

        if (buffer.length === length) {
          break;
        }

        let char = -1;

        let charCodeAt = bytes.toString().charCodeAt(j);

        var value = Number(charCodeAt);

        if (value === -1 || isNaN(value)) {
          console.log("next..");
          continue;
        }

        switch (choice) {
          /* these are from ascii table character codes */
          case CharacterType.LETTER_LOWERCASE: {
            // a-z (26 # of characters)
            if (value >= 97 && value <= 122) {
              //console.log("case 0 (a-z) found");
              char = value;
              buffer.push(char);
            }
            //char = crypto.randomInt(97, 122);
            break;
          }
          case CharacterType.LETTER_UPPERCASE: {
            // A-Z (26 # of characters)
            if (value >= 65 && value <= 90) {
              //console.log("case 1 (A-Z) found");
              char = value;
              buffer.push(char);
            }
            //char = crypto.randomInt(65, 90);
            break;
          }
          case CharacterType.LETTER_DIGIT: {
            // 0-9 (10 # of characters)
            if (value >= 48 && value <= 57) {
              //console.log("case 2 (0-9)");
              char = value;
              buffer.push(char);
            }
            //char = crypto.randomInt(48, 57);
            break;
          }
          case CharacterType.LETTER_SPECIAL: {
            // special characters (33=16+7+6+4 # of characters)
            if (
              (value >= 32 && value <= 47) ||
              (value >= 58 && value <= 64) ||
              (value >= 91 && value <= 96) ||
              (value >= 123 && value <= 126)
            ) {
              //console.log("case 3 (special characters)");
              char = value;
              buffer.push(char);
            }
            //char = crypto.randomInt(33, 47);
            break;
          }
          default: {
            //fail
            break;
          }
        }
      }
    }

    let result = new TextDecoder().decode(new Uint8Array(buffer));

    form.setFieldsValue({ [fieldName]: result });

    if (fieldName === MASTER_KEY) {
      setMasterKey(result);
    } else if (fieldName === DATA_ENCRYPTION_KEY) {
      setDataEncryptionKey(result);
    }
  }

  const createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return "Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };

  const handlePurposeChange = (value: string) => {
    console.log(`Selected: ${value}`);
    if (value === "DEMO_DATA_SAMPLING") {
      notification.warning({
        message:
          "Selecting DEMO_DATA_SAMPLING mode allows you to request the system to generate " +
          " random data samples for you. We must use the identical Master Key as the system. This is useful only for testing purposes.",
        duration: 10,
      });

      //this value should be retrived from the backend.
      let secret: any = DATA_SAMPLING_MASTER_KEY;
      form.setFieldsValue({ [MASTER_KEY]: secret });
      setMasterKey(secret);
      generateEncryptionKeys(DATA_ENCRYPTION_KEY, false, 32, 32);
    }

    setGenerateMasterKeyDisabled(value === "DEMO_DATA_SAMPLING");
  };

  const handleMouseEnter = (setter) => {
    setter(true);
  };
  const handleMouseLeave = (setter) => {
    setter(false);
  };

  const desc = (
    <ul>
      <li>
        When decrypting sensitive data, you will be asked to provider the{" "}
        <u>Master Key</u>.
      </li>
      <li>
        Keep the <u>Master Key</u> and the <u>Data Encryption Keys (DEK)</u>{" "}
        <u>safe</u>.
      </li>
      <li>
        We <u>cannot</u> retrieve lost <u>Master Key</u> for you.
      </li>
      <li>
        Losing keys will result in <u>losing access to sensitive data</u> which
        was encrypted.
      </li>
    </ul>
  );

  const keyCriteriaHints = (hints) => (
    <ul>
      {hints.map((item) => (
        <li>{item}</li>
      ))}
    </ul>
  );

  const masterKeyRequirements = [
    { type: "number", value: "Numeric" },
    { type: "letterUppercase", value: "Uppercase" },
    { type: "letterLowercase", value: "Lowercase" },
    // { type: "special", value: "Special character" },
    { type: "minLength", value: "Minimum length must be greater than 16" },
  ];

  function validateMasterKey(inputValue, setter: any) {
    let newArray: any[] = masterKeyRequirements;
    const regexHasNumeric = /\d+/;
    const regexHasUppercase = /[A-Z]+/;
    const regexHasLowercase = /[a-z]+/;
    const regexHasSpecial = /[=\- ":;,.<~>()[\]{}`'!@#$%^&*]+/;
    const regexHasMinLength = /.{16,}/;

    if (regexHasNumeric.test(inputValue)) {
      newArray = newArray.filter((item) => item["type"] !== "number");
    }
    if (regexHasUppercase.test(inputValue)) {
      newArray = newArray.filter((item) => item["type"] !== "letterUppercase");
    }
    if (regexHasLowercase.test(inputValue)) {
      newArray = newArray.filter((item) => item["type"] !== "letterLowercase");
    }
    if (regexHasSpecial.test(inputValue)) {
      newArray = newArray.filter((item) => item["type"] !== "special");
    }
    if (regexHasMinLength.test(inputValue)) {
      newArray = newArray.filter((item) => item["type"] !== "minLength");
    }
    const result: string[] = newArray.map((item) => item["value"]);

    setter(result);
  }

  const dataEncryptionKeyRequirements = [
    { type: "number", value: "Numeric" },
    { type: "letterUppercase", value: "Uppercase" },
    { type: "letterLowercase", value: "Lowercase" },
    {
      type: "minLength",
      value: "The password length must be of 32 characters.",
    },
  ];

  function validateDataEncryptionKey(inputValue, setter: any) {
    let newArray: any[] = dataEncryptionKeyRequirements;
    const regexHasNumeric = /\d+/;
    const regexHasUppercase = /[A-Z]+/;
    const regexHasLowercase = /[a-z]+/;
    //const regexHasSpecial = /[=\-\ \\":;,.<~>()\[\]{}`'!@#$%^&*]+/;
    const regexHasMinLength = /.{32}/;

    if (regexHasNumeric.test(inputValue)) {
      newArray = newArray.filter((item) => item["type"] !== "number");
    }
    if (regexHasUppercase.test(inputValue)) {
      newArray = newArray.filter((item) => item["type"] !== "letterUppercase");
    }
    if (regexHasLowercase.test(inputValue)) {
      newArray = newArray.filter((item) => item["type"] !== "letterLowercase");
    }
    if (regexHasMinLength.test(inputValue)) {
      newArray = newArray.filter((item) => item["type"] !== "minLength");
    }
    const result: string[] = newArray.map((item) => item["value"]);

    setter(result);
  }

  useEffect(() => {
    console.log("useEffect");
    loadDataEncryptionKey();
  }, [loadDataEncryptionKey, visible]);

  useEffect(() => {
    const masterKeyScore = zxcvbn(masterKey);
    setMasterKeyScore(masterKeyScore);
    if (masterKey.length > 0)
      validateMasterKey(masterKey, setValidatorMasterKey);
    const encryptionKeyScore = zxcvbn(dataEncryptionKey);
    setEncryptionKeyScore(encryptionKeyScore);
    if (dataEncryptionKey.length > 0)
      validateDataEncryptionKey(dataEncryptionKey, setValidatorEncryptionKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterKey, dataEncryptionKey]);

  const longNote = (
    <Paragraph>
      <strong>Important:</strong> You are about to set up an encryption key for <em>End-to-End Encryption</em>. Please review the following:
      <ol>
        <li><strong>DEK (Data Encryption Key):</strong> Protects your sensitive data through end-to-end encryption.</li>
        <li><strong>Master Key:</strong> Secures the DEK.</li>
        <li><strong>Storage:</strong> Only the encrypted DEK is saved in our system.</li>
        <li><strong>Security:</strong> Keys are created on your device; we don’t have access to them.</li>
        <li><strong>Safety:</strong> Keep your master key safe to unlock your DEK and data.</li>
      </ol>
    </Paragraph>
  );
  

  const setupEncryptionKey = (
    <>
      <Row>
        <Col span={24}>
          <h2>Encryption keys</h2>
          {longNote}
        </Col>
        <Col span={24}>
          <div style={{ textAlign: "right" }}>
            <Popover
              content={<>Add new encryption key</>}
              placement={"left"}
              arrowContent
              trigger="hover"
            >
              <Button
                ref={addButtonRef}
                style={{ marginBottom: "1em", textAlign: "right" }}
                htmlType="button"
                type="primary"
                onClick={() => showMasterKeyModalForm()}
              >
                Add
              </Button>
            </Popover>
          </div>
        </Col>
      </Row>
    </>
  );

  function download(filename, text) {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const downloadEncryptionKey = () => {
    let encryptionKeys = JSON.stringify({
      masterKey: masterKey,
      dataEncryptionKey: dataEncryptionKey,
    });
    download("data_encryption_keys.txt", encryptionKeys);
  };

  const setupEncryptionKeyModal = (
    <Modal
      className={styles.EncryptionKeys}
      maskClosable={false}
      closable={true}
      title="Setup encryption key"
      centered
      open={visible}
      width={700}
      onOk={() => console.log("ok")}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          disabled={
            !form.getFieldValue(MASTER_KEY) ||
            !form.getFieldValue(DATA_ENCRYPTION_KEY)
          }
          key="submit"
          onClick={downloadEncryptionKey}
        >
          <DownloadOutlined />
          Download Master Key
        </Button>,
        <Popconfirm
          title="Have you stored safetly the master key? You will need it to decrypt sensitive data."
          okText="Yes"
          cancelText="No"
          open={openPopupConfirm}
          onConfirm={() => encryptAndStore(form)}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={() => setOpenPopupConfirm(false)}
        >
          <Button
            style={{ marginLeft: "8px" }}
            disabled={
              !form.getFieldValue(MASTER_KEY) ||
              !form.getFieldValue(DATA_ENCRYPTION_KEY)
            }
            key="submit"
            type="primary"
            onClick={showPopconfirm}
          >
            Save
          </Button>
        </Popconfirm>,
      ]}
    >
      <div className={styles.EncryptionKeys}>
        <Content>
          <Form
            initialValues={{
              validFrom: moment(),
              encryption_purpose: "PRODUCTION",
            }}
            form={form}
          >
            <Row gutter={48}>
              <Col span={24}>
                <Input.Group compact>
                  <Form.Item
                    tooltip={{
                      title:
                        "Master Key is used to encrypt Data Encryption Key (DEK). Keep it very safe.",
                      icon: <InfoCircleOutlined />,
                    }}
                    style={{ width: "67%" }}
                    label="Master Key"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="masterKey"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                    validateStatus={
                      validatorMasterKey.length > 0 ? "error" : ""
                    }
                  >
                    <Input
                      type={masterKeyVisible ? "text" : "password"}
                      disabled={generateMasterKeyDisabled}
                      style={{ fontSize: "large", fontFamily: "monospace" }}
                      onChange={(evt) => {
                        setMasterKey(evt.target.value);
                        validateMasterKey(
                          evt.target.value,
                          setValidatorMasterKey
                        );
                      }}
                      prefix={[
                        <ReloadOutlined
                          onMouseEnter={() =>
                            handleMouseEnter(setIsMasterKeyHover)
                          }
                          onMouseLeave={() =>
                            handleMouseLeave(setIsMasterKeyHover)
                          }
                          style={{
                            opacity: isMasterKeyHover ? "1" : "0.5",
                            paddingRight: "8px",
                          }}
                          onClick={() =>
                            !generateMasterKeyDisabled &&
                            generateEncryptionKeys(MASTER_KEY, false, 16, 32)
                          }
                          title="Generate new key"
                          className="icon"
                        />,
                        <CopyOutlined
                          onMouseEnter={() =>
                            handleMouseEnter(setIsMasterKeyCopyClipboardHover)
                          }
                          onMouseLeave={() =>
                            handleMouseLeave(setIsMasterKeyCopyClipboardHover)
                          }
                          style={{
                            opacity: isMasterKeyCopyClipboardHover
                              ? "1"
                              : "0.5",
                            paddingRight: "8px",
                          }}
                          onClick={async () => {
                            let value = form.getFieldValue(MASTER_KEY);
                            if ("clipboard" in navigator) {
                              await navigator.clipboard.writeText(value);
                              notification.info({
                                message: "Copied to clipboard",
                              });
                            } else {
                              notification.warning({
                                message: "Could not copy to clipboard",
                              });
                            }
                          }}
                          title="Copy"
                          className="icon"
                        />,
                      ]}
                    />
                  </Form.Item>
                  <Button
                    style={{
                      marginTop: "41px",
                      marginLeft: "1em",
                      textAlign: "left",
                    }}
                    onClick={() =>
                      setMasterKeyVisible((prevState) => !prevState)
                    }
                  >
                    {masterKeyVisible ? "Hide" : "Show"}
                  </Button>
                  <Button
                    disabled={generateMasterKeyDisabled}
                    style={{
                      marginTop: "41px",
                      marginLeft: "1em",
                      textAlign: "left",
                    }}
                    htmlType="button"
                    onClick={() =>
                      generateEncryptionKeys(MASTER_KEY, false, 16, 32)
                    }
                    icon={<ReloadOutlined />}
                  >
                    Generate
                  </Button>
                </Input.Group>
                <div style={{ textAlign: "center" }}>
                  <progress value={masterKeyScore.score} max="4" />
                  <div>
                    <b>Password Strength:</b>{" "}
                    {createPasswordLabel(masterKeyScore)}
                  </div>
                </div>

                {validatorMasterKey.length > 0 && (
                  <Alert
                    message="The following characters are missing: "
                    description={keyCriteriaHints(validatorMasterKey)}
                    type="error"
                    showIcon
                  />
                )}
              </Col>
              <Col span={24}>
                <Divider plain></Divider>
                <Input.Group compact>
                  <Form.Item
                    tooltip={{
                      title:
                        "Data Encryption Key (DEK) is used to encrypt data. It will reside on the server, but will be encrypted with your Master Key before its stored.",
                      icon: <InfoCircleOutlined />,
                    }}
                    style={{ width: "67%" }}
                    label="Data Encryption Key (DEK)"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="dataEncryptionKey"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                    validateStatus={
                      validatorEncryptionKey.length > 0 ? "error" : ""
                    }
                  >
                    <Input
                      // disabled={true}

                      type={dataEncryptionKeyVisible ? "text" : "password"}
                      style={{ fontSize: "large", fontFamily: "monospace" }}
                      onChange={(evt) => {
                        setDataEncryptionKey(evt.target.value);
                        validateDataEncryptionKey(
                          evt.target.value,
                          setValidatorEncryptionKey
                        );
                      }}
                      prefix={[
                        <ReloadOutlined
                          onMouseEnter={() =>
                            handleMouseEnter(setIsEncryptionKeyHover)
                          }
                          onMouseLeave={() =>
                            handleMouseLeave(setIsEncryptionKeyHover)
                          }
                          style={{
                            opacity: isEncryptionKeyHover ? "1" : "0.5",
                            paddingRight: "8px",
                          }}
                          onClick={() =>
                            generateEncryptionKeys(
                              DATA_ENCRYPTION_KEY,
                              false,
                              32,
                              32
                            )
                          }
                          title="Generate new key"
                          className="icon"
                        />,
                        <CopyOutlined
                          onMouseEnter={() =>
                            handleMouseEnter(
                              setIsEncryptionKeyCopyClipboardHover
                            )
                          }
                          onMouseLeave={() =>
                            handleMouseLeave(
                              setIsEncryptionKeyCopyClipboardHover
                            )
                          }
                          style={{
                            opacity: isEncryptionKeyCopyClipboardHover
                              ? "1"
                              : "0.5",
                            paddingRight: "8px",
                          }}
                          onClick={async () => {
                            let value =
                              form.getFieldValue(DATA_ENCRYPTION_KEY);
                            if ("clipboard" in navigator) {
                              await navigator.clipboard.writeText(value);
                              notification.info({
                                message: "Copied to clipboard",
                              });
                            } else {
                              notification.warning({
                                message: "Could not copy to clipboard",
                              });
                            }
                          }}
                          title="Copy"
                          className="icon"
                        />,
                      ]}
                    />

                    {/* <Input.Password  style={{ fontSize: "large", fontFamily: "monospace" }} onChange={e => validateKey(e, setValidatorEncryptionKey)} /> */}
                  </Form.Item>
                  <Button
                    style={{
                      marginTop: "41px",
                      marginLeft: "1em",
                      textAlign: "right",
                    }}
                    onClick={() =>
                      setDataEncryptionKeyVisible((prevState) => !prevState)
                    }
                  >
                    {dataEncryptionKeyVisible ? "Hide" : "Show"}
                  </Button>
                  <Button
                    style={{
                      marginTop: "41px",
                      marginLeft: "1em",
                      textAlign: "right",
                    }}
                    htmlType="button"
                    onClick={() =>
                      generateEncryptionKeys(
                        DATA_ENCRYPTION_KEY,
                        false,
                        32,
                        32
                      )
                    }
                    icon={<ReloadOutlined />}
                  >
                    Generate
                  </Button>
                </Input.Group>
                <div style={{ textAlign: "center" }}>
                  <progress value={encryptionKeyScore.score} max="4" />
                  <div>
                    <b>Password Strength:</b>{" "}
                    {createPasswordLabel(encryptionKeyScore)}
                  </div>
                </div>

                {validatorEncryptionKey.length > 0 && (
                  <Alert
                    message={keyCriteriaHints(validatorEncryptionKey)}
                    type="error"
                    showIcon
                  />
                )}
              </Col>
              <Divider plain></Divider>
              <Col span={12}>
                <Form.Item
                  tooltip={{
                    title:
                      "Starting date when DEK key will be used for data encryption.",
                    icon: <InfoCircleOutlined />,
                  }}
                  label="Data Encryption key valid from"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="validFrom"
                  rules={[
                    {
                      required: true,
                      message: "Missing valid date from",
                    },
                  ]}
                >
                  <DatePicker
                    defaultValue={dayjs()}
                    value={dayjs()}
                    onChange={onChangeValidFrom}
                  />

                  {/* <Input.Password  style={{ fontSize: "large", fontFamily: "monospace" }} onChange={e => validateKey(e, setValidatorEncryptionKey)} /> */}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Purpose"
                  tooltip={{
                    title:
                      'If you want to test the application and let the server generate data for your, select "DEMO_DATA-SAMPLING". Otherwise, select "PRODUCTION".',
                    icon: <InfoCircleOutlined />,
                  }}
                  name="encryption_purpose"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Missing valid date from",
                    },
                  ]}
                >
                  <Select onChange={handlePurposeChange}>
                    <Select.Option value="PRODUCTION">
                      PRODUCTION (End-To-End Encryption)
                    </Select.Option>
                    <Select.Option value="DEMO_DATA_SAMPLING">
                      DEMO_DATA_SAMPLING (Tryout mode)
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <p>
                  <Alert
                    message="Attention"
                    description={desc}
                    type="info"
                    showIcon
                    closeText="Hide"
                  />
                </p>
              </Col>
            </Row>
          </Form>
        </Content>
        <Footer style={{ background: 'transparent', padding: '24px 0' }}>
          {hasErrors.length > 0 && (
            <Alert type="error" message={hasErrors} banner />
          )}
        </Footer>
      </div>
    </Modal>
  );

  useEffect(() => {
    if(!state.loading) {
      if (state.data.length === 0 && 
        !tourShownRef.current && // Prevents the tour from showing multiple times
        tableRef.current && 
        addButtonRef.current) {
        setOpenTour(true);
        tourShownRef.current = true; 
      } else {
        setOpenTour(false);
      }
    }
  }, [state.data]);
  

  const rowSelection = {
    type: 'radio' as 'radio', // Type assertion to specify the exact type
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: !record.is_active, // Disable selection if is_active is false
    }),
  };  

  return (
    <div className={styles.EncryptionKeys}>
      <>
        
        <Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} />
          
        {
          <>
            <Content>
              {setupEncryptionKey}
              {setupEncryptionKeyModal}

              {
                <div ref={tableRef}>
                <Table
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        console.log(record);
                        if (isUsingContext) {
                          // Since rowSelection is of type 'radio', we select only one row
                          onSelectChange([record.uuid], [record]);
                        }
                        // Uncomment and implement if you need to handle view device click
                        // handleViewDeviceClick(record);
                      },
                      onDoubleClick: (event) => {
                        console.log(event);
                      }, // double click row
                      onContextMenu: (event) => { }, // right button click row
                      onMouseEnter: (event) => { }, // mouse enter row
                      onMouseLeave: (event) => { }, // mouse leave row
                      style: {
                        cursor: isUsingContext ? 'pointer' : 'default', // Conditionally set cursor
                        // Optional: Add other conditional styles here
                      },
                    };
                  }}
                  rowSelection={
                    isUsingContext
                      ?  rowSelection
                      : undefined
                  }
                  key={state.loading + ""}
                  rowKey="uuid"
                  columns={columns}
                  dataSource={state.data}
                />
                </div>
              }
            </Content>
          </>
        }
      </>
    </div>
  );
}

export default EncryptionKeys;