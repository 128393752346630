import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Layout, Modal, Row } from "antd";
import { useState } from "react";
import FullScreenSpinner from "../../common/FullScreenSpinner";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";
import useAuth from "../../hooks/auth";

const { Footer, Content } = Layout;

export default function ModalAddOrganization(props) {
  const { refreshToken } = useAuth();
  const { axiosApiInstance } = AxiosApiInstance();
  const [hasAddOrganizationErrors, setHasAddOrganizationErrors] = useState("");
  const [form] = Form.useForm();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [propsAddOrganization, setPropsAddOrganization] = useState({
    loading: false,
    hasData: false,
  });

  const cancelAddOrganization = (form) => {
    form.resetFields();
    setHasAddOrganizationErrors("");
    props.hide();
  };

  const addOrganization = (form) => {
    console.log("submitting..");
    setPropsAddOrganization({ ...propsAddOrganization, loading: true });

    form
      .validateFields()
      .then((values) => {
        const data = {
          name: values["name"],
        };
        console.log(data);

        setHasAddOrganizationErrors("");

        console.log("posting now..");
        axiosApiInstance
          .post(`${URL_API_V1}/organizations/`, data)
          .then((response) => {
            //const responseData: any = response.data;
            //console.log("getPiiFields:" + JSON.stringify(responseData));
            if (response.status === 201) {
              props.hide();
            } else {
              console.log("create failed..");
            }
            form.resetFields();
            return response;
          })
          .then((response) => {
            if (response.status === 201) {
              console.log("the status is 201!");
              props.getOrganizations();
              refreshToken();
              console.log("refreshing done..");
              setPropsAddOrganization({
                ...propsAddOrganization,
                loading: false,
              });
            }
          })
          .catch((err) => {
            console.log("an error occured..");
            if (err.response?.data?.reason) {
              console.log(err.response.data.reason);
              setHasAddOrganizationErrors(err.response.data.reason);
            } else {
              setHasAddOrganizationErrors("An unexpected error has occured");
            }
            console.log("post failed.." + JSON.stringify(err));
            setPropsAddOrganization({
              ...propsAddOrganization,
              loading: false,
            });
          });
      })
      .catch((err) => {
        console.log("Validate Failed:", err);
        setPropsAddOrganization({ ...propsAddOrganization, loading: false });
      });
    console.log("adding new organization..");
  };

  return (
    <div>
      {propsAddOrganization.loading ? <FullScreenSpinner /> : null}
      <Modal
        {...propsAddOrganization}
        title="Add organization"
        centered
        maskClosable={false}
        open={props.isShowing}
        width={500}
        onOk={() => addOrganization(form)}
        onCancel={() => cancelAddOrganization(form)}
        footer={[
          <Button key="back" onClick={() => cancelAddOrganization(form)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => addOrganization(form)}
          >
            Add
          </Button>,
        ]}
      >
        <Content>
          <Form form={form}>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Organization name"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the organization name!",
                    },
                  ]}
                >
                  <Input
                    // prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Organization name"
                    maxLength={40}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
        <Content>
          {hasAddOrganizationErrors.length > 0 && (
            <Alert type="error" message={hasAddOrganizationErrors} banner />
          )}
        </Content>
      </Modal>
    </div>
  );
}
