import axios from "axios";
import jwt from "jwt-decode";
import { useRecoilState } from "recoil";
import { userAtom } from "../common/Atoms";
import { URL_API_V1, MAX_RETRY_ATTEMPTS } from "../constants/global";
import handleLogout from "../hooks/auth";
import { Utils } from "./2FAUtils";

export default function AxiosApiInstance() {
  const [user, setUser] = useRecoilState(userAtom);

  const axiosApiInstance = axios.create();

  // Add a request interceptor
  axiosApiInstance.interceptors.request.use(
    (config) => {
      const token = user.token; // Ensure 'user' is reliably accessible here
  
      config.headers = config.headers || {};
      if (!config.headers["Content-Type"]) {
        config.headers["Content-Type"] = "application/json";
      }
      
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error); // Make sure to return the rejected promise
    }
  );

  function isTokenExpiredError(error) {
    console.log("Interceptors.response: error:", error.request.response)
    console.log("Interceptors.response: error 2:", error.response.data)
    const errorResponse = error?.request?.response;
    if ('error_code' in error.response.data && error.response.data.error_code === "token_invalid_or_expired") {
      console.log("Interceptors.response: error 3:", errorResponse.error)
      return true;
    }
    return false;
    // Check if the response matches the token expiration or invalid token conditions
    // return errorResponse === '{"detail":"Refresh token is invalid or has expired."}' ||
    //        errorResponse === "User profile has not been verified yet.";
  }
  
  axiosApiInstance.interceptors.response.use(
    response => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401) {
        // Check if the error is due to token expiration and it's the first retry attempt
        if (isTokenExpiredError(error) && !originalRequest._retry) {
          // Initialize or increment the retry attempt counter
          originalRequest._retry_attempt = originalRequest._retry_attempt || 0;
  
          // Check if we've reached the maximum retry attempts
          if (originalRequest._retry_attempt >= MAX_RETRY_ATTEMPTS) {
            console.log("Maximum retry attempts reached, redirecting to login.");
            handleLogout();
            return Promise.reject(error);
          }
  
          // Flag this request as retried and increment the attempt counter
          originalRequest._retry = true;
          originalRequest._retry_attempt++;
          console.log("Retrying request...", originalRequest._retry_attempt);
  
          try {
            const refreshToken = user.refresh;
            const refreshResponse = await axios.post(`${URL_API_V1}/auth/refresh`, { refresh: refreshToken });
  
            if (refreshResponse.status === 200) {
              console.log("Token refreshed successfully.")
              const { token } = refreshResponse.data as { token: string };
              user.token = token; // Assuming 'user' is accessible and can be updated
              axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  
              // Retry the original request with the new token
              return axiosApiInstance(originalRequest);
            }
          } catch (refreshError) {
            // If token refresh also fails, handle accordingly (e.g., redirect to login)
            handleLogout();
            return Promise.reject(refreshError);
          }
        } else {
          // For 401 errors not related to token expiration or if retry attempts exceeded
          console.log("Retry attempts exceeded or token refresh failed, redirecting to login.");
          handleLogout();
          return Promise.reject(error);
        }
      }
      console.log("Interceptors.response: error response:", error.response);
      return Promise.reject(error);
    }
  );
  
  

  return { axiosApiInstance };
}
