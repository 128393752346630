import { Image } from "antd";
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { styleModeAtom } from '../common/Atoms';

const LogoComponent = () => {
  const [themeState, setThemeState] = useRecoilState(styleModeAtom);

  const defaultLogo = '/logo/security-spectrum-high-resolution-logo.svg';
  const darkLogo = '/logo/security-spectrum-high-resolution-logo-color-on-transparent-background-dark-theme.svg';
  const [logo, setLogo] = useState(defaultLogo);


  useEffect(() => {
    console.log(`themeState: ${themeState.theme}`);
    if(themeState.theme === 'dark' || themeState.theme === 'gray') {
      setLogo(darkLogo);
    } else {
      setLogo(defaultLogo);
    }
  }, [themeState]);
  
  return (
    <div>
      <Image preview={false} width={200} height={50} src={logo} />
    </div>
  );
};

export default LogoComponent;
