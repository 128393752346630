import moment from 'moment';
import React, { createContext, FC, ReactNode, useState } from 'react';

const defaultStartDate = moment().subtract(15, 'minutes');
const defaultEndDate = moment();

const defaultContextValue: TimeRangeContextType = {
  timeRangeType: 'relative',
  setTimeRangeType: () => {},
  selectedAbsoluteDates: [defaultStartDate, defaultEndDate],
  setSelectedAbsoluteDates: () => {},
  selectedRelativeTime: '-1h',
  setSelectedRelativeTime: () => {},
  queryTerms: [],
  setQueryTerms: () => {},
};

const TimeRangeContext = createContext<TimeRangeContextType>(defaultContextValue);

export const TimeRangeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [timeRangeType, setTimeRangeType] = useState<string>('relative');

  const defaultDates: [moment.Moment, moment.Moment] = [moment().subtract(15, 'minutes'), moment()];

  const [selectedAbsoluteDates, setSelectedAbsoluteDates] = useState<[moment.Moment, moment.Moment]>(defaultDates);
  const [selectedRelativeTime, setSelectedRelativeTime] = useState<string>('-15m');
  const [queryTerms, setQueryTerms] = useState<QueryTerm[]>([]);

  return (
    <TimeRangeContext.Provider
      value={{
        timeRangeType,
        setTimeRangeType,
        selectedAbsoluteDates,
        setSelectedAbsoluteDates,
        selectedRelativeTime,
        setSelectedRelativeTime,
        queryTerms,
        setQueryTerms,
      }}
    >
      {children}
    </TimeRangeContext.Provider>
  );
};

export const useTimeRange = () => {
  const context = React.useContext(TimeRangeContext);

  if (!context) {
    throw new Error('useTimeRange must be used within a TimeRangeProvider');
  }

  return context;
};
