import React, { createContext, FC, ReactNode, useState } from 'react';

interface DeviceChartsContextType {
  updateChart: number;
  doUpdateChart: () => void
}

const defaultDeviceChartsContextValue: DeviceChartsContextType = {
  updateChart: 0,
  doUpdateChart: () => {},
};

const DeviceChartsContext = createContext<DeviceChartsContextType>(defaultDeviceChartsContextValue);

export const DeviceChartsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [updateChart, setUpdateChart] = useState<number>(0);
  const doUpdateChart = () => {
    console.log('doUpdateChart');
    setUpdateChart((updateChart) => updateChart + 1);
  }

  return (
    <DeviceChartsContext.Provider
      value={{
        updateChart,
        doUpdateChart,
      }}
    >
      {children}
    </DeviceChartsContext.Provider>
  );
};

export const useDeviceCharts = () => {
  const context = React.useContext(DeviceChartsContext);

  if (!context) {
    throw new Error('useDeviceCharts must be used within a DeviceChartsProvider');
  }

  return context;
};
