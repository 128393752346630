import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../common/Atoms';

const RedirectToDashboardOrLogin = () => {
  const user = useRecoilValue(userAtom);
  const location = useLocation();

  // Redirect to the login page if not authenticated
  if (!user.is_authenticated) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  // Redirect to the dashboard or another appropriate page if authenticated
  return <Navigate to="/dashboard" replace />;
};

export default RedirectToDashboardOrLogin;