import { UserOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Layout, Modal, notification, Row } from "antd";
import { useState } from "react";
import RenderErrorMessages from "../../common/RenderErrorMessages";
import AuthorizationHeader from "../../hooks/authHeaders";
import AxiosApiInstance from "../../common/Interceptors";
import styles from "./ModalUserInvitation.module.scss";

const { Footer, Content } = Layout;

export default function ModalUserInvitation(props) {
  const { axiosApiInstance } = AxiosApiInstance();
  const [hasInviteUserErrors, setHasInviteUserErrors] = useState("");
  const { getAuthHeader } = AuthorizationHeader();

  const [form] = Form.useForm();

  const [propsInviteUser, setPropsInviteUser] = useState({
    loading: true,
    hasData: false,
  });

  console.log(props)

  const cancelUserInvitation = (form) => {
    form.resetFields();
    setHasInviteUserErrors("");
    props.hide();
  };

  const inviteUser = (form) => {
    console.log("submitting..");

    form
      .validateFields()
      .then((values) => {
        const data = {
          invited_user_email: values["email"],
        };
        console.log(data);

        axiosApiInstance
          .post(`${process.env.REACT_APP_API_URL + "/api/v2"}/r/${props.selectedOrganization}/users/invite/`, data, getAuthHeader())
          .then((response) => {
            //const responseData: any = response.data;
            setPropsInviteUser({ ...propsInviteUser, loading: false });
            //console.log("getPiiFields:" + JSON.stringify(responseData));
            if (response.status === 201) {
              cancelUserInvitation(form);
              props.getOrganizationUsers();
              props.getOrganizationInvitedUsers();
              notification.success({
                message: `User ${values["email"]} was successfully invited to the organisation ${props.selectedOrganization}`,
              });
            } else {
              console.log("create failed..");
              notification.error({
                message: `User ${values["email"]} could not be invited to the organisation ${props.selectedOrganization}`,
              });
            }
            form.resetFields();
          })
          .catch((err) => {
            console.log("an error occured..:" + err);
            console.log("an error occured..:" + err.response.data);
            if (err.response?.data?.reason) {
              console.log(err.response.data.reason);
              //RenderErrorMessages(err.response.data.reason, form);
              // notification.error({
              //   message: `${err.response.data.reason}`,
              // });
              setHasInviteUserErrors(`${err.response.data.reason}`);

            } else {
              setHasInviteUserErrors("An unexpected error has occured");
            }
            console.log("post failed.." + JSON.stringify(err));
          });
      })
      .catch((err) => {
        console.log("Validate Failed:", err);
      });
    console.log("invited user to the organization..");
  };

  return (
    <Modal
      {...propsInviteUser}
      className={styles.Organizations}
      title="Invite user"
      centered
      open={props.isShowing}
      width={600}
      onCancel={() => cancelUserInvitation(form)}
      footer={[
        <Button key="back" onClick={() => cancelUserInvitation(form)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => inviteUser(form)}>
          Submit
        </Button>,
      ]}
    >
      <div className={styles.InviteUser}>
      <Layout>
        <Content>
          <Form form={form}>
            <Row gutter={24}>
              <Col span={10}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please email address of the user you want to invite!",
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email address" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
        <Footer>{hasInviteUserErrors.length > 0 && <Alert type="error" message={hasInviteUserErrors} banner />}</Footer>
      </Layout>
      </div>
    </Modal>
  );
}
