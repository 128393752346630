import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Table,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import FileSaver from "file-saver";
import { styleModeAtom, userAtom } from "../../common/Atoms";
import { URL_API_V1 } from "../../constants/global";
import AxiosApiInstance from "../../common/Interceptors";
import APIKeys from "components/APIKeys/APIKeys";
import { SetupWizardProvider } from "../SetupWizard/SetupWizardContext";
import { useLocation, useNavigate } from "react-router-dom";
import Certificates from "components/Certificates/Certificates";
import SetupWizard from "components/SetupWizard/SetupWizard";

interface AgentServiceProps {}

interface Certificate {
  uuid: string;
  name: string;
  created_on: string;
  principal: string;
  is_active: boolean;
  not_valid_before: string;
  not_valid_after: string;
  serial: string;
  deactivated_on: string | null;
}

const AgentService: React.FC<AgentServiceProps> = () => {
  const [themeState] = useRecoilState(styleModeAtom);
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();
  const [loading, setLoading] = useState(true);
  const [agentCerts, setAgentCerts] = useState<Certificate[]>([]);

  const [setupWizardVisible, setSetupWizardVisible] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newCertName, setNewCertName] = useState("");

  let navigate = useNavigate();
  const location = useLocation();
  const tabKey = location.pathname.split("/").pop();

  const orgKeyAPIKeys = {
    tokens: [],
    client_key: "",
    kafka: {},
    backend_server: {},
    key_server: {},
  };

  const [propsConfigs, setPropsConfigs] = useState({
    loading: true,
    hasData: false,
    data: orgKeyAPIKeys,
  });

  const handleDownload = (uuid: string) => {
    getAgentCertificateDetail(uuid);
  };

  const handleDelete = async (uuid: string) => {
    try {
      setLoading(true);
      const response = await axiosApiInstance.delete(
        `${URL_API_V1}/r/${user.organization}/kafka/agent-certs/${uuid}/`
      );

      if (response.status === 204) {
        notification.success({
          message: "Success",
          description: "Certificate deleted successfully.",
        });
        getAgentCertificates(); // Reload the certificates after deletion
      } else {
        notification.error({
          message: "Error",
          description: "Failed to delete certificate.",
        });
      }
    } catch (error) {
      console.error("Error deleting certificate:", error);
      notification.error({
        message: "Error",
        description: "Failed to delete certificate.",
      });
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const response = await axiosApiInstance.post(
        `${URL_API_V1}/r/${user.organization}/kafka/agent-certs/`,
        {
          name: newCertName,
        }
      );

      const entry: Certificate = response.data;
      if (response && response.status === 201) {
        notification.success({
          message: "Success",
          description: "Certificate created successfully.",
        });
        setIsModalVisible(false);
        setNewCertName("");
        setAgentCerts([...agentCerts, entry]); // Use array spread syntax to add the new entry
        getAgentCertificates(); // Reload the certificates after creation
      }
    } catch (error) {
      console.error("Error creating certificate:", error);
      notification.error({
        message: "Error",
        description: "Failed to create certificate.",
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const extractCN = (principal: string) => {
    const cnMatch = principal.match(/CN=([^,]+)/);
    return cnMatch ? cnMatch[1] : principal;
  };

  function getAgentConfigurations() {
    setPropsConfigs({ ...propsConfigs, loading: true });

    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/configurations/agents`)
      .then((response) => {
        setPropsConfigs({ ...propsConfigs, loading: false });
        setLoading(false);
        if (response && response.status === 200) {
          const responseData: any = response.data;
          console.log(responseData);
          setPropsConfigs({ ...propsConfigs, data: responseData });
        } else {
          setPropsConfigs({ ...propsConfigs, data: orgKeyAPIKeys });
        }
      });
  }

  function getAgentCertificates() {
    setPropsConfigs({ ...propsConfigs, loading: true });

    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/kafka/agent-certs/`)
      .then((response) => {
        setPropsConfigs({ ...propsConfigs, loading: false });
        setLoading(false);
        if (response && response.status === 200) {
          const responseData: any = response.data;
          console.log(responseData);

          setAgentCerts(responseData.certificates);
        }
      });
  }

  function getAgentCertificateDetail(uuid: string) {
    setPropsConfigs({ ...propsConfigs, loading: true });

    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/kafka/agent-certs/${uuid}/`, {
        responseType: "blob",
      })
      .then((response) => {
        setPropsConfigs({ ...propsConfigs, loading: false });
        setLoading(false);
        if (response && response.status === 200) {
          const responseData: any = response.data;
          console.log(responseData);
          const fileNameHeader = "filename";
          const suggestedFileName = response.headers[fileNameHeader];
          const effectiveFileName =
            suggestedFileName === undefined
              ? `agent-service-certificates.zip`
              : suggestedFileName;
          // Let the user save the file.
          FileSaver.saveAs(responseData, effectiveFileName);
        }
      });
  }

  useEffect(() => {
    getAgentConfigurations();
    getAgentCertificates();
    //getAgentCertificateDetail("6df2ab03-b23b-4db8-8a98-a81d046d7e3d");
  }, []);

  useEffect(() => {
    console.log("agentCerts:", agentCerts);
  }, []);

  const handleTabClick = (key) => {
    navigate(`/app-settings/agents/${key}`);
  };

  const showSetupWizard = () => {
    setSetupWizardVisible(true);
  };

  const hideSetupComplete = () => {
    setSetupWizardVisible(false);
  };

  function AgentServiceComponent() {
    return (
      <div>
        <Row>
          <Col>
            <h3>Agents</h3>
          </Col>
        </Row>
        <Flex justify="center">
          <Col style={{ textAlign: "right" }}>
            <Button size="large" type="primary" onClick={showSetupWizard}>
              Start Setup Wizard
            </Button>
          </Col>
        </Flex>
        <Row>
          <Col span={24}></Col>
        </Row>
        <Modal
          title="Add Agent"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form>
            <Form.Item label="Name">
              <Input
                value={newCertName}
                onChange={(e) => setNewCertName(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }

  let tabs = [
    {
      label: `Agents`,
      key: `agents`,
      children: <AgentServiceComponent />,
    },
    {
      label: `API Keys`,
      key: `api-keys`,
      children: <APIKeys />,
    },
    {
      label: `Certificates`,
      key: `certificates`,
      children: <Certificates />,
    },
  ];

  return (
    <Card>
      <Tabs
        tabPosition="left"
        activeKey={tabKey || "agents"}
        onChange={handleTabClick}
        items={tabs}
      />

      <SetupWizardProvider>
        <SetupWizard
          visible={setupWizardVisible}
          onComplete={hideSetupComplete}
          onCancel={() => setSetupWizardVisible(false)}
          notificationUuid={undefined}
        />
      </SetupWizardProvider>
    </Card>
  );
};

export default AgentService;
