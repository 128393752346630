import { Tabs } from "antd";

import DataUsage from "components/DataUsage/DataUsage";
import LogShippers from "components/LogShippers/LogShippers";
import PiiFields from "components/PiiFields/PiiFields";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import AgentService from "components/AgentService/AgentService";
import Certificates from "components/Certificates/Certificates";
import APIKeys from "components/APIKeys/APIKeys";
import EncryptionKeys from "components/EncryptionKeys/EncryptionKeys";

export default function Settings(props) {
  let navigate = useNavigate();
  let location = useLocation();
  const tabKey = location.pathname.split('/').pop();

  const handleTabClick = (key) => {
    console.log("Navigating to:", `/app-settings/${key}`);
    navigate(`/app-settings/${key}`);
  };

  let tabs = [
    // { label: `Privacy`, key: `privacy` },
    // { label: 'Agents', key: 'agents' },
    // { label: `Api Keys`, key: `api-keys`},
    // { label: `Certificates`, key: `certificates`},
    // { label: `Log Shippers`, key: `logshippers`},
    // { label: `Disk Usage`, key: `datausage`},
  ];
  
  useEffect(() => {
    if (!tabKey) {  // Only redirect if there is no tab key in the URL
      console.log("Redirecting to privacy: ", tabKey);
      navigate(`/app-settings/privacy`);
    }
  }, [navigate, tabKey]);

  return (
    <div className="card-container" style={{ margin: "0px 25px 0px 25px"}}>
      <div>
        {/* <Tabs  defaultActiveKey={tabKey || 'privacy'} onChange={handleTabClick} items={tabs} /> */}
        <Routes>
          <Route path="privacy/*" element={<PiiFields />} />
          <Route path="agents/*" element={<AgentService />} />
          <Route path="api-keys" element={<APIKeys />} />
          <Route path="certificates" element={<Certificates />} />
          {/* <Route path="logshippers" element={<LogShippers />} /> */}
          <Route path="datausage" element={<DataUsage />} />
        </Routes>
      </div>
    </div>
  );
}
