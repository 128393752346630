import { QueryClient } from 'react-query';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // you can set global configurations here
    },
  },
});

export default queryClient;