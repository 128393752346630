
import {
  Button,
  Card,
  Col,
  notification,
  Row,
  Table,
  Tooltip,
  Dropdown,
  Menu,
  Popconfirm,
} from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState, useContext } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import { URL_API_V1 } from "../../constants/global";
import AxiosApiInstance from "../../common/Interceptors";
import ToggleableKey from "common/ToggleableKey";
import { SetupWizardContext } from "components/SetupWizard/SetupWizardContext";
import { getDateWithTooltip } from "utils/utils";

interface APIKeysProps {
  // Optional props for standalone usage
  onAPIKeysUpdate?: (
    apiAccessKey: string,
    apiSecretKey: string,
    organizationKey: string
  ) => void;
  securityKeys?: {
    apiAccessKey: string;
    apiSecretKey: string;
    organizationKey: string;
  };
}

interface APIKey {
  uuid: string;
  access_key: string;
  secret_key: string | null;
  created_at: string;
  last_used_time: string | null;
  is_active: boolean;
}

interface PropsConfig {
  loading: boolean;
  data: APIKey[];
}

const APIKeys: React.FC<APIKeysProps> = ({
  onAPIKeysUpdate,
  securityKeys: propsSecurityKeys,
}) => {
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();
  const [loading, setLoading] = useState(true);
  const [organizationKey, setOrganizationKey] = useState("");

  const [visibleKeys, setVisibleKeys] = useState<{ [key: string]: boolean }>({});
  const [propsConfigs, setPropsConfigs] = useState<PropsConfig>({
    loading: true,
    data: [],
  });
  

  const context = useContext(SetupWizardContext);
  const { setSecurityKeys } = context || { setSecurityKeys: () => {} };

  // Determine if context is available
  const isUsingContext = Boolean(context);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: APIKey[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);

    if (context && selectedRows && selectedRows.length > 0) {
      const selectedCert = selectedRows[0];
      context.setSecurityKeys((prevKeys) => ({
        ...prevKeys,
        apiAccessKey: selectedCert.access_key,
        apiSecretKey: selectedCert.secret_key || "",
      }));
    }
  };

  // Function to fetch API keys
  const getApiKeys = async () => {
    setLoading(true);
    try {
      const response = await axiosApiInstance.get(
        `${URL_API_V1}/r/${user.organization}/security/api-keys`
      );
      console.log("APIKeys: API keys response:", response.data);
      if (response.status === 200) {
        const apiKeys: APIKey[] = response.data.results || [];
        setPropsConfigs({ loading: false, data: apiKeys });

        // Extract active API key
        const activeKey = apiKeys.find((key) => key.is_active);
        console.log("APIKeys: Active key:", activeKey);

        if (activeKey) {
          if (isUsingContext && context) {
            console.log("APIKeys: Called onAPIKeysUpdate with:", {
              apiAccessKey: activeKey.access_key,
              apiSecretKey: activeKey.secret_key || "",
              organizationKey,
            });

            onRowSelectChange([activeKey.uuid], [activeKey]);
          } else if (onAPIKeysUpdate) {
            onAPIKeysUpdate(
              activeKey.access_key,
              activeKey.secret_key || "",
              organizationKey
            );
            console.log("APIKeys: Called onAPIKeysUpdate with active API key:", {
              apiAccessKey: activeKey.access_key,
              apiSecretKey: activeKey.secret_key || "",
              organizationKey,
            });
          }
        } else {
          console.warn("APIKeys: No active API key found");
        }
      } else {
        console.warn(`APIKeys: Unexpected response status: ${response.status}`);
        setPropsConfigs({ loading: false, data: [] });
      }
    } catch (error) {
      console.error("APIKeys: Error fetching API Keys:", error);
      notification.error({
        message: "Error",
        description: "Failed to fetch API Keys.",
      });
      setPropsConfigs({ loading: false, data: [] });
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch Organization Key
  const getOrgKey = async () => {
    try {
      const response = await axiosApiInstance.get(`${URL_API_V1}/organizations/`);
      console.log("APIKeys: Organizations response:", response.data);
      if (response.status === 200) {
        const organizations = response.data.results || [];
        const organization = organizations.find(
          (org: any) => org.slug === user.organization
        );
        if (organization) {
          const uuid = organization.uuid;
          const orgDetailResponse = await axiosApiInstance.get(
            `${URL_API_V1}/r/${user.organization}/organizations/${uuid}/`
          );
          console.log(
            "APIKeys: Organization detail response:",
            orgDetailResponse.data
          );
          if (orgDetailResponse.status === 200) {
            const orgData = orgDetailResponse.data;
            const orgKey = orgData.client_key || "";
            setOrganizationKey(orgKey);
            console.log("APIKeys: Organization Key:", orgKey);

            // Update context or props with organizationKey
            if (isUsingContext && context) {
              context.setSecurityKeys((prevKeys) => ({
                ...prevKeys,
                organizationKey: orgKey,
              }));
              console.log(
                "APIKeys: Updated context with organization key:",
                orgKey
              );
            } else if (onAPIKeysUpdate) {
              onAPIKeysUpdate(
                propsSecurityKeys?.apiAccessKey || "",
                propsSecurityKeys?.apiSecretKey || "",
                orgKey
              );
              console.log(
                "APIKeys: Called onAPIKeysUpdate with organization key:",
                orgKey
              );
            }
          }
        } else {
          throw new Error("Organization not found");
        }
      } else {
        throw new Error(
          `Failed to fetch organizations, status: ${response.status}`
        );
      }
    } catch (error) {
      console.error("APIKeys: Error fetching Organization Key:", error);
      notification.error({
        message: "Error",
        description: "Failed to fetch Organization Key.",
      });
    }
  };

  // Function to create a new API key
  const createApiKey = async () => {
    try {
      const response = await axiosApiInstance.post(
        `${URL_API_V1}/r/${user.organization}/security/api-keys/`
      );

      console.log("APIKeys: Create API key response:", response.data);
      if (response.status === 201) {
        const newKey: APIKey = response.data;
        notification.success({
          message: "Success",
          description: "API Key created successfully.",
        });
        // Update the local state
        setPropsConfigs((prevState) => ({
          loading: false,
          data: [newKey, ...prevState.data],
        }));
        setVisibleKeys((prevState) => ({
          ...prevState,
          [newKey.uuid]: false,
        }));

        // Update context or props with the new API keys if applicable
        if (isUsingContext && context) {
          context.setSecurityKeys((prevKeys) => ({
            ...prevKeys,
            apiAccessKey: newKey.access_key,
            apiSecretKey: newKey.secret_key || "",
          }));
          console.log("APIKeys: Updated context with new API keys:", {
            apiAccessKey: newKey.access_key,
            apiSecretKey: newKey.secret_key || "",
          });
        } else if (onAPIKeysUpdate) {
          onAPIKeysUpdate(newKey.access_key, newKey.secret_key || "", organizationKey);
          console.log("APIKeys: Called onAPIKeysUpdate with new API keys:", {
            apiAccessKey: newKey.access_key,
            apiSecretKey: newKey.secret_key || "",
            organizationKey,
          });
        }
      }
    } catch (error) {
      console.error("APIKeys: Error creating API Key:", error);
      notification.error({
        message: "Error",
        description: "Failed to create API Key.",
      });
    }
  };

  // Function to deactivate an API key
  const deactivateApiKey = async (uuid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${URL_API_V1}/r/${user.organization}/security/api-keys/${uuid}/deactivate/`
      );

      console.log(`APIKeys: Deactivate API key ${uuid} response:`, response.data);
      if (response.status === 200) {
        notification.success({
          message: "Success",
          description: "API Key deactivated successfully.",
        });
        // Refresh the API keys list
        getApiKeys();
      } else {
        console.warn(
          `APIKeys: Unexpected response status during deactivation: ${response.status}`
        );
        notification.error({
          message: "Error",
          description: "Failed to deactivate API Key.",
        });
      }
    } catch (error) {
      console.error(`APIKeys: Error deactivating API Key ${uuid}:`, error);
      notification.error({
        message: "Error",
        description: "Failed to deactivate API Key.",
      });
    }
  };

  // Function to fetch all required keys
  const fetchAllKeys = async () => {
    await getOrgKey();
    await getApiKeys();
  };

  useEffect(() => {
    fetchAllKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to handle row selection changes
  const onRowSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: APIKey[]) => {
    setSelectedRowKeys(newSelectedRowKeys);

    if (context && selectedRows.length > 0) {
      const selectedKey = selectedRows[0];
      console.log("APIKeys: access_key:", selectedKey.access_key);
      console.log("APIKeys: secret_key:", selectedKey.secret_key);
      context.setSecurityKeys((prevKeys) => ({
        ...prevKeys,
        apiAccessKey: selectedKey.access_key || "",
        apiSecretKey: selectedKey.secret_key || "",
      }));
    }
  };

  // Columns for the API Keys table
  const columns = [
    {
      title: "API Access Key",
      dataIndex: "access_key",
      key: "access_key",
      render: (value: string) => <ToggleableKey keyValue={value} />,
    },
    {
      title: "API Secret Key",
      dataIndex: "secret_key",
      key: "secret_key",
      render: (value: string | null) => (value ? value : "***"),
    },
    {
      title: "Created On",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string) =>
        getDateWithTooltip(text)
    },
    {
      title: "Last Time Used",
      dataIndex: "last_used_time",
      key: "last_used_time",
      render: (text: string | null) =>
        getDateWithTooltip(text)
    },
    {
      title: "State",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active: boolean) => (is_active ? "Active" : "Inactive"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: APIKey) => {
        const menu = (
          <Menu>
            <Menu.Item key="deactivate">
              <Tooltip title="Deactivate the API Key" placement="right">
                <Button
                  type="text"
                  size="small"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    deactivateApiKey(record.uuid);
                  }}
                >
                  Deactivate
                </Button>
              </Tooltip>
            </Menu.Item>
            {/* Add more actions here if needed */}
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button
              size="small"
              onClick={(e) => e.stopPropagation()} // Prevent row selection on button click
            >
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Card>
      <Row>
        <Col>
          <h3>API Keys</h3>
        </Col>
      </Row>
      <Row>
        <Card
          title={"Organization Key"}
          style={{ width: "100%", marginBottom: 16 }}
        >
          <ToggleableKey keyValue={organizationKey} />
        </Card>
      </Row>
      <Row
        style={{
          marginBottom: "8px", // Reduced margin
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col span={12}></Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={createApiKey}>
            Add API Key
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            dataSource={propsConfigs.data}
            columns={columns}
            rowKey="uuid"
            loading={propsConfigs.loading}
            bordered
            rowSelection={
              isUsingContext
                ? {
                    type: "radio", // Use "checkbox" for multi-select
                    selectedRowKeys,
                    onChange: onRowSelectChange,
                  }
                : undefined
            }
            onRow={(record) => ({
              onClick: () => {
                if (isUsingContext) {
                  onRowSelectChange([record.uuid], [record]);
                }
              },
              style: {
                cursor: isUsingContext ? "pointer" : "default", // Conditionally set cursor
              },
            })}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default APIKeys;