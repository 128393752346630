import { FilterOutlined, MinusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select } from 'antd';
import TimeRangeOptions from 'components/TimeComponents/TimeRangeOptions';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
// import { useTimeRangeCalculation } from '../../hooks/useTimeRangeCalculation';
import './SearchComponent.modules.css';
import { useTimeRange } from '../../contexts/TimeRangeContext';
import { useEventFieldValuesApi } from 'hooks/getEventsFieldValues';
import { useEventsTableContext } from '../../contexts/EventsTableContext';

const booleanOperators = ['AND', 'OR'];
const defaultComparisonOperators: string[] = ['=', '!=', 'contains', '<', '<=', '>', '>=', 'is null', 'is not null'];

const SearchComponent = forwardRef<SearchComponentMethods, SearchComponentProps>(({ loadData, availableFieldsParam }, ref) => {
  const {
    queryTerms,
    setQueryTerms,
    timeRangeType,
    setTimeRangeType,
    selectedAbsoluteDates,
    setSelectedAbsoluteDates,
    selectedRelativeTime,
    setSelectedRelativeTime,
  } = useTimeRange();

  const { calculateTimeRange, getSuggestionsForField } = useEventsTableContext();

  const [suggestions, setSuggestions] = useState({});

  const [comparisonOperators, setComparisonOperators] = useState<string[]>(defaultComparisonOperators);

  useImperativeHandle(ref, () => ({
    addTermWithValues,
  }));

  const addTermWithValues = (field: string, comparisonOperator: string, value: string | string[]) => {
    setQueryTerms((prevTerms) => [...prevTerms, { booleanOperator: booleanOperators[0], field, comparisonOperator, value }]);
  };

  useEffect(() => {
    console.log('useEffect queryTerms');
    queryTerms.forEach((term, index) => {
      if (term.field) {
        getSuggestionsForField(term.field)
          .then((suggestionList) => {
            setSuggestions((prevSuggestions) => ({
              ...prevSuggestions,
              [index]: suggestionList,
            }));
          })
          .catch(console.error);
      }
    });
  }, [queryTerms]);

  const addTerm = () => {
    setQueryTerms((prevTerms: QueryTerm[]) => [
      ...prevTerms,
      {
        booleanOperator: booleanOperators[0],
        field: availableFieldsParam[0],
        comparisonOperator: comparisonOperators[0],
        value: '',
      },
    ]);
  };

  const updateTermField = (index: number, newField: string) => {
    setQueryTerms((prevTerms) => prevTerms.map((term, i) => (i === index ? { ...term, field: newField } : term)));
  };

  const updateTermComparison = (index: number, newOperator: string) => {
    setQueryTerms((prevTerms) => prevTerms.map((term, i) => (i === index ? { ...term, comparisonOperator: newOperator } : term)));
  };

  const updateTermValue = (index: number, newValue: string | string[]) => {
    setQueryTerms((prevTerms) => prevTerms.map((term, i) => (i === index ? { ...term, value: newValue } : term)));
  };

  const removeTerm = (index: number) => {
    setQueryTerms((prevTerms) => prevTerms.filter((_, i) => i !== index));
  };

  const updateTermBooleanOp = (index: number, newBooleanOp: string | undefined) => {
    setQueryTerms((prevTerms) => prevTerms.map((term, i) => (i === index ? { ...term, booleanOperator: newBooleanOp } : term)));
  };

  const validateValue = (value: string | string[]) => {
    // Example validation: check if the value is empty
    // You can extend this function to perform more complex validations
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return value !== '';
  };

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', minWidth: '600px' }}>
          <Button style={{ marginRight: 8 }} onClick={addTerm} icon={<FilterOutlined />}>
            Add filter
          </Button>
          <TimeRangeOptions
            timeRangeType={timeRangeType}
            setTimeRangeType={setTimeRangeType}
            setSelectedAbsoluteDates={setSelectedAbsoluteDates}
            selectedAbsoluteDates={selectedAbsoluteDates}
            selectedRelativeTime={selectedRelativeTime}
            setSelectedRelativeTime={setSelectedRelativeTime}
            queryTerms={queryTerms}
            setQueryTerms={setQueryTerms}
          />
          <Button style={{ marginLeft: 8 }} type="primary" onClick={loadData} icon={<SearchOutlined />}>
            Search
          </Button>
        </div>

        {queryTerms.map((term, index) => (
          <div key={index} style={{ display: 'flex', marginBottom: 10, marginTop: 10, flexWrap: 'wrap' }}>
            <Select
              value={term.booleanOperator}
              style={{ width: 80, marginRight: 8 }}
              onChange={(newBooleanOp) => updateTermBooleanOp(index, newBooleanOp)}
              disabled={index === 0}
            >
              {booleanOperators.map((op) => (
                <Select.Option key={op} value={op}>
                  {op}
                </Select.Option>
              ))}
            </Select>

            <Select
              value={term.field}
              style={{ minWidth: '150px', width: 'auto', marginRight: 8 }}
              dropdownMatchSelectWidth={false}
              showSearch={true}
              onChange={(newField) => updateTermField(index, newField)}
            >
              {availableFieldsParam.map((field) => (
                <Select.Option key={field} value={field}>
                  {field}
                </Select.Option>
              ))}
            </Select>

            <Select
              value={term.comparisonOperator}
              style={{ width: 100, marginRight: 8 }}
              onChange={(newOperator) => updateTermComparison(index, newOperator)}
            >
              {term.field === 'ANY' ? (
                <Select.Option key="=" value="=">
                  =
                </Select.Option>
              ) : (
                comparisonOperators.map((op) => (
                  <Select.Option key={op} value={op}>
                    {op}
                  </Select.Option>
                ))
              )}
            </Select>

            {term.comparisonOperator === 'is null' || term.comparisonOperator === 'is not null' ? null : (
              <Select
                showSearch
                mode="tags"
                value={term.value ? term.value.toString() : undefined}
                style={{ flex: 1, minWidth: '160px', maxWidth: 375 }}
                placeholder="Select value or type to add..."
                onChange={(newValue) => {
                  console.log('New Value:', newValue);
                  if (!newValue.length) {
                    updateTermValue(index, '');
                    return;
                  }
                  const lastTag = newValue[newValue.length - 1];
                  const words = lastTag.split(/\s+/);
                  const lastWord = words.pop() || '';
                  updateTermValue(index, lastWord);
                }}
                status={!validateValue(term.value) ? 'error' : undefined}
                filterOption={(input, option) =>
                  option?.value ? option.value.toString().toLowerCase().includes(input.toLowerCase()) : false
                }
              >
                {suggestions[index]?.map(
                  (suggestion) =>
                    suggestion.field_name && (
                      <Select.Option key={suggestion.field_name} value={suggestion.field_name}>
                        {suggestion.field_name}
                      </Select.Option>
                    )
                )}
              </Select>
            )}
            <div style={{ verticalAlign: 'middle', margin: 4 }}>
              <MinusCircleOutlined title="Remove Filter" onClick={() => removeTerm(index)} />
            </div>
          </div>
        ))}
      </Col>

      {/* Right Side */}
    </Row>
  );
});

export default SearchComponent;
