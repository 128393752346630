
import { Buffer } from "buffer";
import * as crypto from "crypto-browserify";
import CryptoJS2 from 'crypto-js';
import * as ipaddr from "ipaddr.js";
import { KEY_NAME_IP_DECRYPTION } from "../common/PseudonymizerService";

const CryptoJSCMAC = (window as any).CryptoJS

const IV_LENGTH = 16;
const TAG_LENGTH = 16;
const MAX_CLASS_BITS = 4;
const ALGORITHM = 'aes-128-gcm';

//const DECRYPTION_KEYS = {}

// export const AVAILABLE_MASKING_TECHNIQUES = [
//   {name: "AES-GCM", value: "aes_gcm", is_two_way: true, description: "AES-GCM (Galois/Counter Mode) is the standard encryption scheme. It does not preserve utility or searching capability of the data because every ciphertext is unique."}, 
//   {name: "AES-GCM DET", value: "aes_gcm_det", is_two_way: true, description: "AES-GCM deterministic. It preserves the searching functionality of the encrypted values."},
//   {name: "CryptopANT IPv4/IPv6", value: "cryptopant_ipv4_ipv6", is_two_way: true, description: "IPv4/IPv6 encryption scheme built on top of open-source CryptopANT encryption algorithm. For more information see: https://ant.isi.edu/software/cryptopANT/index.html"},
//   {name: "HMAC-SHA256", value: "hmac_sha256", is_two_way: false, description: "HMAC uses on the SHA-256 hash function which is a one way function implying that plaintext cannot be decrypted or visible in clear."}
// ];

var JsonFormatter = {
  stringify: function (cipherParams) {
    // create json object with ciphertext
    var jsonObj = {
      ct: cipherParams.ciphertext.toString(CryptoJS2.enc.Base64),
      iv: "",
      s: "",
    };
    // optionally add iv or salt
    if (cipherParams.iv) {
      jsonObj.iv = cipherParams.iv.toString();
    }
    if (cipherParams.salt) {
      jsonObj.s = cipherParams.salt.toString();
    }
    // stringify json object
    return JSON.stringify(jsonObj);
  },
  parse: function (jsonStr) {
    // parse json string
    var jsonObj = JSON.parse(jsonStr);
    // extract ciphertext from json object, and create cipher params object
    var cipherParams = CryptoJS2.lib.CipherParams.create({
      ciphertext: CryptoJS2.enc.Base64.parse(jsonObj.ct),
    });
    // optionally extract iv or salt
    if (jsonObj.iv) {
      cipherParams.iv = CryptoJS2.enc.Hex.parse(jsonObj.iv);
    }
    if (jsonObj.s) {
      cipherParams.salt = CryptoJS2.enc.Hex.parse(jsonObj.s);
    }
    return cipherParams;
  },
};


export class EncryptDecrypt {
  static encrypt(maskingTechnique, cmacEncKey, plaintext): string {
    if (maskingTechnique === "aes_gcm") {
      //let field_name = piiFieldToDecrypt["fieldName"];
      // warning - searching on AES_GCM is not possible.
    } else if (maskingTechnique === "aes_gcm_det") {
      //let field_name = piiFieldToDecrypt["fieldName"];
      let message = "";
      try {
        return EncryptionUtils.encrypt(cmacEncKey, plaintext);
      } catch (error) {
        message = "unable to encrypt:" + plaintext;
        console.log(message);
        console.error("an error has occurred!");
        console.error(error);
      }
    } else if (maskingTechnique === "cryptopant_ipv4_ipv6") {
      //let field_name = piiFieldToDecrypt["fieldName"];
      let ip_address = plaintext;
      try {
        if (ipaddr.IPv4.isValid(ip_address)) {
          return EncryptionUtils.encrypt_ip(cmacEncKey, ip_address);
        } else if (ipaddr.IPv6.isValid(ip_address)) {
          return EncryptionUtils.encrypt_ipv6(cmacEncKey, ip_address);
        }
      } catch (error) {
        let message = "unable to encrypt:" + plaintext;
        console.log(message);
        console.error(error);
      }
    }
    return ""
  }

  static decrypt(maskingTechnique, cmacEncKey, ciphertext) {
    if (maskingTechnique === "aes_gcm") {
      //let field_name = piiFieldToDecrypt["fieldName"];
      let message = "";
      try {
        message = EncryptionUtils.decrypt(cmacEncKey, ciphertext);
        return message;
      } catch (error) {
        message = "unable to decrypt:" + ciphertext;
        console.log(message);
        console.error("an error has occurred!");
        console.error(error);
      }
    } else if (maskingTechnique === "aes_gcm_det") {
      //let field_name = piiFieldToDecrypt["fieldName"];
      let message = "";
      try {
        return EncryptionUtils.decrypt(cmacEncKey, ciphertext);
      } catch (error) {
        message = "unable to decrypt:" + ciphertext;
        console.log(message);
        console.error("an error has occurred!");
        console.error(error);
      }
    } else if (maskingTechnique === "cryptopant_ipv4_ipv6") {
      //let field_name = piiFieldToDecrypt["fieldName"];
      let ip_address = ciphertext;
      try {
        if (ipaddr.IPv4.isValid(ip_address)) {
          return EncryptionUtils.decrypt_ip(cmacEncKey, ip_address);
        } else if (ipaddr.IPv6.isValid(ip_address)) {
          return EncryptionUtils.decrypt_ipv6(cmacEncKey, ip_address);
        }
      } catch (error) {
        let message = "unable to decrypt:" + ciphertext;
        console.log(message);
        console.error(error);
      }
    }
  }
}

export enum EncryptionAlgorithm {
  AES_ECB = "AES_ECB",
  HMAC_SHA256 = "HMAC_SHA256",
}

export class EncryptionUtils {
  static doDebug = false;
  static doDebugAES = false;
  static doDebugNewFeature = false;

  static encryptionAlgorithm = EncryptionAlgorithm.HMAC_SHA256

  static generatePBKDF2Input(message, iterations, saltSize, keySize) {
    const salt = Buffer.from(crypto.randomBytes(saltSize))
    return crypto.pbkdf2Sync(message, salt, iterations, keySize, 'sha512')
  }

  static base64ToHex(str) {
    const raw = atob(str);
    let result = "";
    for (let i = 0; i < raw.length; i++) {
      const hex = raw.charCodeAt(i).toString(16);
      result += hex.length === 2 ? hex : "0" + hex;
    }
    return result;
  }

  static concatTypedArrays(a, b) {
    // a, b TypedArray of same type
    var c = new a.constructor(a.length + b.length);
    c.set(a, 0);
    c.set(b, a.length);
    return c;
  }

  static concatBuffers(a, b) {
    return EncryptionUtils.concatTypedArrays(new Uint8Array(a.buffer || a), new Uint8Array(b.buffer || b)).buffer;
  }

  static generatePBKDF2Encryption(key_as_hex, salt_as_hex) {
    let encryption_key_b64 = EncodingUtils.convertFromHexToBase64(key_as_hex);
    const keyK = CryptoJS2.PBKDF2(encryption_key_b64, EncodingUtils.convertFromHexToString(salt_as_hex), {
      iterations: 2,
      keySize: 128 / 32,
      hasher: CryptoJS2.algo.SHA1,
    });
    return keyK;
  }

  static generate_key_from_master_key(master_password) {
    if (EncryptionUtils.doDebugAES) console.log("\n\n generate_key_from_master_key()");
    if (EncryptionUtils.doDebugAES) console.log("master_password:"+master_password);
    var master_password_hex = EncodingUtils.convertStringToHex(master_password);
    if (EncryptionUtils.doDebugAES) console.log("master_password_hex:"+master_password_hex);
    let master_password_b64 = EncodingUtils.convertFromHexToBase64(master_password_hex);
    if (EncryptionUtils.doDebugAES) console.log("master_password_b64:"+master_password_b64);
    const master_key = CryptoJS2.PBKDF2(CryptoJS2.enc.Utf8.parse(master_password), "", {
      iterations: 100,
      keySize: 256 / 32,
      hasher: CryptoJS2.algo.SHA1,
    });
    if (EncryptionUtils.doDebugAES) console.log("master_key as b64: " + EncodingUtils.convertFromHexToBase64(master_key));
    if (EncryptionUtils.doDebugAES) console.log("master_key as hex: " + master_key.toString());
    return master_key.toString();
  }

  static generate_cmac(key, message) {
    if (EncryptionUtils.doDebugAES) console.log("\n\n generate_cmac()");
    let key_b64 = EncodingUtils.convertFromHexToBase64(key);
    if (EncryptionUtils.doDebugAES) console.log("key:" + key);
    if (EncryptionUtils.doDebugAES) console.log("key_b64:" + key_b64);
    if (EncryptionUtils.doDebugAES) console.log("message:" + message);
    let parsed_key = CryptoJS2.enc.Base64.parse(key_b64)
    let cmac = CryptoJSCMAC.CMAC(parsed_key, message);
    let cmac_b64 = EncodingUtils.convertFromHexToBase64(cmac);
    if (EncryptionUtils.doDebugAES) console.log("cmac in b64:" + cmac_b64);
    if (EncryptionUtils.doDebugAES) console.log("cmac in hex:" + cmac);
    return cmac;
  }

  static encrypt(key, plaintext) {
    let key_hex = EncodingUtils.convertStringToHex(key)
    let hashed_value = CryptoJS2.HmacSHA256(plaintext,  CryptoJS2.enc.Hex.parse(key_hex)).toString(CryptoJS2.enc.Hex).toUpperCase()
    const iv = hashed_value.slice(0, IV_LENGTH*2)

    let ivBuffer = Buffer.from(iv, 'hex')

    let key16FirstBytes = key.slice(0, 16)
    
    const cipher = crypto.createCipheriv(ALGORITHM, key16FirstBytes, ivBuffer);
    const encrypted = Buffer.concat([
      cipher.update(String(plaintext), 'utf8'),
      cipher.final(),
    ]);

    console.log(encrypted.toString('hex'))

    const tag = cipher.getAuthTag();

    return Buffer.concat([ivBuffer, encrypted, tag]).toString('base64');
  }



  static decrypt(key, ciphertext_b64) {
    if (EncryptionUtils.doDebugAES) console.log("\n\n decrypt()");
    if (EncryptionUtils.doDebugAES) console.log("key:" + key);
    if (EncryptionUtils.doDebugAES) console.log("ciphertext_b64:" + ciphertext_b64);

    let key16FirstBytes = key.slice(0, 16)

    console.log("key16FirstBytes: " + key16FirstBytes)

    const ciphertext_hex = Buffer.from(String(ciphertext_b64), 'base64');

    // 4. transform derived key to base64
    const encryption_key_b64 = EncodingUtils.convertFromHexToBase64(key16FirstBytes);


    // 5. decode base64 to hex
    //const ciphertext_hex = EncodingUtils.convertBase64ToHex(ciphertext_b64);
    const iv = ciphertext_hex.subarray(0, IV_LENGTH);
    // console.log("IV:" + iv.toString('hex'))
    const tag = ciphertext_hex.subarray(ciphertext_hex.length - TAG_LENGTH, ciphertext_hex.length);
    // console.log("tag:" + tag.toString('hex'))
    const encrypted = ciphertext_hex.subarray(IV_LENGTH,ciphertext_hex.length - TAG_LENGTH);

    // console.log("ciphertext:" +encrypted.toString('hex'))
    // 6. Extract ciphertext from iv_ciphertext
    //let ciphertext_only = ciphertext_hex.replace(iv, "");

    if (EncryptionUtils.doDebugAES) console.log("encryption_key_b64:" + encryption_key_b64);

    if (EncryptionUtils.doDebugAES) console.log("iv hex:" + iv);
    if (EncryptionUtils.doDebugAES) console.log("ciphertext b64:" + ciphertext_b64);
    //if (EncryptionUtils.doDebugAES) console.log("ciphertext hex:"+decode(ciphertext))
    // if (EncryptionUtils.doDebugAES) console.log("ciphertext hex:" + ciphertext_hex);
    
    // 7. Generate encryption key from derviedKeyBase64 and salt
    //let enc_key_b64 = EncryptionUtils.generate_key_pbkdf2(encryption_key_b64, "1234");

    // convert encryption key from base64 to hex
    //let enc_key_hex = this.base64ToHex(enc_key_b64);
    //if (EncryptionUtils.doDebugAES) console.log("enc_key_b64: "+enc_key_b64);
    //if (EncryptionUtils.doDebugAES) console.log("enc_key_hex: "+enc_key_hex);

    // 8. Decryption with AES

    // convert iv, ciphertext from hex to base64
    //var iv_b64 = EncodingUtils.convertFromHexToBase64(iv);
    //if (EncryptionUtils.doDebugAES) console.log("iv_b64:" + iv_b64);
    //let ciphertext_only_b64 = EncodingUtils.convertFromHexToBase64(ciphertext_only);
    //if (EncryptionUtils.doDebugAES) console.log("ciphertext_only b64:" + ciphertext_only_b64);
    //if (EncryptionUtils.doDebugAES) console.log("ciphertext_only hex:" + ciphertext_only);

    // var decrypted = CryptoJS2.AES.decrypt(
    //   { ciphertext: CryptoJS2.enc.Base64.parse(ciphertext_only_b64) },
    //   CryptoJS2.enc.Hex.parse(enc_key_hex),
    //   {
    //     mode: CryptoJS2.mode.CTR,
    //     iv: CryptoJS2.enc.Base64.parse(iv_b64),
    //     padding: CryptoJS2.pad.NoPadding,
    //   }
    // );

    const decipher = crypto.createDecipheriv(ALGORITHM, key16FirstBytes, iv);

    decipher.setAuthTag(tag);

    const outputs = decipher.update(encrypted) + decipher.final('utf8');
    if (EncryptionUtils.doDebugAES) console.log(outputs)
    let message = CryptoJS2.enc.Utf8.stringify(outputs);

    if (EncryptionUtils.doDebugAES) console.log("message:" + message);
    // if (EncryptionUtils.doDebugAES) console.log("Data decrypted: " + CryptoJS2.enc.Utf8.stringify(decrypted));

    return outputs;
  }

  static generate_key_pbkdf2(keyB64, salt) {
    // if (EncryptionUtils.doDebug) console.log("\n\n generate_key_pbkdf2()");
    //let keyB64 = "8KL3kR18x0nEn0+5jqt91Q=="
    // if (EncryptionUtils.doDebug) console.log("keyB64:" + keyB64);
    //let keyAsHex = CryptoJS2.enc.Hex.parse(this.base64ToHex(keyB64))
    //if (EncryptionUtils.doDebug) console.log("keyAsHex:"+keyAsHex)
    let saltb64 = EncodingUtils.encodeToBase64(salt);
    let salt_in_hex = this.base64ToHex(saltb64);
    if (EncryptionUtils.doDebugAES) console.log("saltb64:" + saltb64);
    if (EncryptionUtils.doDebugAES) console.log("salt_in_hex:" + salt_in_hex);
    var salt_parsed = CryptoJS2.enc.Hex.parse(salt_in_hex); //this.base64ToHex("MTIzNA==")

    const key = CryptoJS2.PBKDF2(keyB64, salt_parsed, {
      iterations: 2,
      keySize: 128 / 32,
      hasher: CryptoJS2.algo.SHA1,
    });
    let keyBase64 = EncodingUtils.convertFromHexToBase64(key);
    // if (EncryptionUtils.doDebug) console.log(">>> key SHA1 b64:" + keyBase64);
    // if (EncryptionUtils.doDebug) console.log("key SHA1 hex:" + key);
    return keyBase64;
  }


  static decrypt_ipv6(encryptionKey, ip_address) {
    if (EncryptionUtils.doDebug) console.log("decrypting ipv6=" + ip_address);
    let ipv6_in_four_blocks = EncryptionUtils.convert_ipv6_to_four_bytes(ip_address);

    let inputs = new Array<number>(4);
    let guess = new Array<number>(4);
    let res = new Array<number>(4);

    let pass_bits = 0;
    let r = 0;

    for (let i = 0; i < 4; i++) {
      inputs[i] = ipv6_in_four_blocks[i];
    }

    for (let i = 0; i < 4; i++) {
      guess[i] = inputs[i];
    }

    for (let i = 0; i < 4; ++i) {
      for (; ;) {
        res = guess;
        res = EncryptionUtils.scramble_ip6(res, pass_bits, encryptionKey);
        r = res[i] ^ inputs[i];
        if (r === 0) break;
        guess[i] ^= r;
      }
    }

    let byte_arrays = EncryptionUtils.extract4BlocksOfIntToByteArrays(guess);

    let array_of_bytes_from_dec_to_hex: any[] = [];
    for (let i = 0; i < byte_arrays.length; i++) {
      array_of_bytes_from_dec_to_hex[i] = byte_arrays[i] < 0 ? 256 + byte_arrays[i] : byte_arrays[i];
    }

    var addr = ipaddr.fromByteArray(array_of_bytes_from_dec_to_hex);
    return addr.toString();
  }

  static encrypt_ipv6(encryptionKey, ip_address) {
    if (EncryptionUtils.doDebug) console.log("encrypting ipv6=" + ip_address);
    let ipv6_in_four_blocks = EncryptionUtils.convert_ipv6_to_four_bytes(ip_address);

    let pass_bits = 0;
    let encrypted_ipv6 = EncryptionUtils.scramble_ip6(ipv6_in_four_blocks, pass_bits, encryptionKey);
    let byte_arrays = EncryptionUtils.extract4BlocksOfIntToByteArrays(encrypted_ipv6);
    
    let array_of_bytes_from_dec_to_hex: any[] = [];
    for (let i = 0; i < byte_arrays.length; i++) {
      array_of_bytes_from_dec_to_hex[i] = byte_arrays[i] < 0 ? 256 + byte_arrays[i] : byte_arrays[i];
    }
    //console.log("array_of_bytes_from_dec_to_hex="+array_of_bytes_from_dec_to_hex)
    var addr = ipaddr.fromByteArray(array_of_bytes_from_dec_to_hex);
    return addr.toString();
  }

  static scramble_ip6(input, pass_bits, encryptionKey) {
    let key = encryptionKey;

    if (EncryptionUtils.doDebug) console.log("using encryption key: " + key);

    if (EncryptionUtils.doDebug) console.log("key:" + key);
    let key_hex = EncodingUtils.convertStringToHex(key);
    if (EncryptionUtils.doDebug) console.log("key as hex value:" + key_hex);
    let k_as_hex_value = key_hex.substring(0, 64);
    let key_as_hex = CryptoJS2.enc.Hex.parse(k_as_hex_value);

    let b6In = new Array<number>(4);
    b6In[0] = 0;
    b6In[1] = 0;
    b6In[2] = 0;
    b6In[3] = 0;

    let b6Out = new Array<number>(4);
    let output: any[] = [];

    let pbits = pass_bits;

    for (let w = 0; w < 4; ++w) {
      let m = 0xffffffff << 1;

      let x = EncodingUtils.swap32(input[w]);
      //console.log("x="+x);
      let hpad = 0;
      output[w] = 0;
      if (EncryptionUtils.doDebug) console.log("\nw=" + w);
      for (let i = 31; i > pbits - 1; --i) {
        if (EncryptionUtils.doDebug) console.log("w=" + w + ", i=" + i);
        x &= m;
        if (EncryptionUtils.doDebug) console.log("x=" + x + ",m=" + m);
        x |= hpad >> i;
        b6In[w] = EncodingUtils.swap32(x);
        if (EncryptionUtils.doDebug) console.log("b6In[w]=" + b6In[w]);
        if (EncryptionUtils.doDebug) console.log("before encryption b6out[0]=%d, [1]=%d, [2]=%d, [3]=%d", b6Out[0], b6Out[1], b6Out[2], b6Out[3]);
        if (EncryptionUtils.doDebug) console.log("encrypting:" + x);

        let message_to_encrypt = this.extract4BlocksOfIntToByteArrays(b6In);

        let message_as_hex = EncodingUtils.convertFromBytesToHex(message_to_encrypt);

        //let ip_numeric_as_hex =  EncodingUtils.decimalHexTwosComplement(168430090)
        //let ipp_numeric_in_hex = CryptoJS2.enc.Hex.parse(ip_numeric_as_hex)

        if (EncryptionUtils.doDebug) console.log("encrypting inuput:" + message_as_hex);
        if (EncryptionUtils.doDebug) console.log("encrypting with key:" + key_as_hex);
        
        let cipher_text_hex = CryptoJS2.HmacSHA256(CryptoJS2.enc.Hex.parse(message_as_hex), CryptoJS2.enc.Hex.parse(key_hex)).toString(CryptoJS2.enc.Hex).toUpperCase()

        if (EncryptionUtils.doDebug) console.log("\nEncrypted..");
        if (EncryptionUtils.doDebug) console.log("ciphertext in hex:" + cipher_text_hex);
        if (EncryptionUtils.doDebug) console.log("\n");

        let cipher_text_bytes = EncodingUtils.convertFromHexToBytes(cipher_text_hex);
        //if (EncryptionUtils.doDebug) console.log("ip out base64:"+cipher_text_base64)
        if (EncryptionUtils.doDebug) console.log("ciphertext in hex:" + cipher_text_hex);
        if (EncryptionUtils.doDebug) console.log("ciphertext in bytes:" + cipher_text_bytes);
        let message_in_byte_array = EncodingUtils.convertFromHexToBytes(cipher_text_hex.toString());
        if (EncryptionUtils.doDebug) console.log("message_in_byte_array:" + message_in_byte_array);

        b6Out[0] = new Uint32Array(new Uint8Array(message_in_byte_array.slice(0, 4)).buffer.slice(-4))[0];
        b6Out[1] = new Uint32Array(new Uint8Array(message_in_byte_array.slice(4, 8)).buffer.slice(-4))[0];
        b6Out[2] = new Uint32Array(new Uint8Array(message_in_byte_array.slice(8, 12)).buffer.slice(-4))[0];
        b6Out[3] = new Uint32Array(new Uint8Array(message_in_byte_array.slice(12, 16)).buffer.slice(-4))[0];

        if (EncryptionUtils.doDebug) console.log(b6Out);

        if (EncryptionUtils.doDebug) console.log("before output[w]=" + output[w]);
        output[w] |= (EncodingUtils.swap32(b6Out[3]) & 1) << (31 - i);

        if (EncryptionUtils.doDebug)
          console.log(
            "after output[w]=" +
            output[w] +
            ", output[1]=" +
            output[1] +
            ", output[2]=" +
            output[2] +
            ", output[3]=" +
            output[3]
          );
        m <<= 1;
      }
      pbits = pbits >= 32 ? pbits - 32 : 0;
      output[w] = EncodingUtils.swap32(output[w]) ^ input[w];

      if (EncryptionUtils.doDebug)
        console.log(
          "outer after output[w]=" +
          output[w] +
          ", output[1]=" +
          output[1] +
          ", output[2]=" +
          output[2] +
          ", output[3]=" +
          output[3]
        );
      if (EncryptionUtils.doDebug) console.log("output[" + w + "]=" + output[w]);

      b6In[w] = input[w];
    }

    return output;
  }

  static extract4BlocksOfIntToByteArrays(b6In: number[]) {
    let bytes_per_int32 = 4;
    let number_of_blocks = 4;

    let message_to_encrypt: any[] = [];
    for (let block_number = 0; block_number < number_of_blocks; block_number++) {
      let b6In8byteArray = EncodingUtils.toBytesInt32(b6In[block_number], bytes_per_int32);

      for (let index = 0; index < b6In8byteArray.byteLength; index++) {
        let value = new DataView(b6In8byteArray).getInt8(index);
        message_to_encrypt.push(value);
      }
    }
    return message_to_encrypt;
  }

  static normalize_to_ipv6(ip_string) {
    ip_string = ip_string.replace(/^:|:$/g, "");

    var ipv6 = ip_string.split(":");

    for (var i = 0; i < ipv6.length; i++) {
      var hex = ipv6[i];
      if (hex !== "") {
        // normalize leading zeros
        ipv6[i] = ("0000" + hex).substr(-4);
      } else {
        // normalize grouped zeros ::
        hex = [];
        for (var j = ipv6.length; j <= 8; j++) {
          hex.push("0000");
        }
        ipv6[i] = hex.join(":");
      }
    }
    return ipv6.join(":");
  }

  static convert_ipv6_to_four_bytes(ip_string) {
    var bytes = ipaddr.parse(ip_string).toByteArray();

    var ipv6 = new Array<number>(3);

    for (let i = 0; i < 4; i++) {
      ipv6[i] = Buffer.from(bytes.slice(4 * i, 4 * (i + 1))).readInt32LE(0);
    }
    return ipv6;
  }

  static decrypt_ip(encryptionKey, ip_address) {
    if (EncryptionUtils.doDebug) console.log("decrypting ip_address: " + ip_address)
    let ip_numeric = EncryptionUtils.transform_ip_as_numeric(ip_address);
    let ip_numeric_decrypted = EncryptionUtils.decrypt_ipv4_numeric(encryptionKey, ip_numeric);
    let ip_text = EncodingUtils.fromIntToIPv4String(ip_numeric_decrypted);
    if (EncryptionUtils.doDebug) console.log("decrypted ip_text: " + ip_text)
    return ip_text;
  }

  static encrypt_ip(encryptionKey, ip_address) {
    let ip_numeric = EncryptionUtils.transform_ip_as_numeric(ip_address);
    let ip_numeric_encrypted = EncryptionUtils.encrypt_ipv4_numeric(encryptionKey, ip_numeric);
    return EncodingUtils.fromIntToIPv4String(ip_numeric_encrypted);
  }

  static decrypt_ipv4_numeric(encryptionKey, ip_numeric) {
    let guess = 0;
    let res = 0;
    guess = ip_numeric;
    for (let i = 32; i > 0; --i) {
      res = EncryptionUtils.encrypt_ipv4_numeric(encryptionKey, guess);
      if (EncryptionUtils.doDebug) console.log("guess:" + guess);
      if (EncryptionUtils.doDebug) console.log("res:" + res);
      if (EncryptionUtils.doDebug) console.log("ip_numeric:" + ip_numeric);
      res ^= ip_numeric;
      if (res === 0) {
        return guess;
      }
      guess ^= res;
      if (EncryptionUtils.doDebug) console.log("after guess:" + guess);
      if (EncryptionUtils.doDebug) console.log("after res:" + res);
      if (EncryptionUtils.doDebug) console.log("after ip_numeric:" + ip_numeric);
    }
    return guess;
  }

  static transform_ip_as_numeric(ip_address) {
    if (EncryptionUtils.doDebug) console.log("Decrypting input:" + ip_address);
    if (EncryptionUtils.doDebug) console.log("inputs as hex: " + EncodingUtils.convertStringToHex(ip_address));
    let ip_numeric = EncodingUtils.fromIPv4StringToInt(ip_address);
    if (EncryptionUtils.doDebug) console.log("ip address numeric = " + ip_numeric);
    return ip_numeric;
  }

  static encrypt_ipv4_numeric(encryptionKey, ip_numeric) {
    if (EncryptionUtils.doDebug) console.log("ip address numeric = " + ip_numeric);
    let ip = EncodingUtils.fromIntToIPv4String(ip_numeric);
    if (EncryptionUtils.doDebug) console.log("ip address back to string = " + ip);

    let _classBits = [
      1, 1, 1, 1, 1, 1, 1, 1, /* class A: preserve 1 bit  */
      2, 2, 2, 2,     /* class B: preserve 2 bits */
      3, 3,         /* class C: preserve 3 bits */
      4,         /* class D: preserve 4 bits */
      32         /* class bad, preserve all  */
    ]

    let output = 0;
    let m = 0xffffffff << 1;
    if (EncryptionUtils.doDebug) console.log("m 0xffffffff << 1: " + m);

    let new_ip_address = 0;
    let i = 31;
    let pbits = 0;
    let pass_bits = 0;
    let class_bits = 0;


    // Note: byte shifting on bigint (or long in java). 
    // Had to change tsconfig.json from compilerOptions.target=es2017 to compilerOptions.target=es2020
    let intarray = new Uint32Array([ip_numeric]) //converts to unsigned
    let ip_long_unsigned = Number(BigInt(intarray[0]) >> (32n - BigInt(MAX_CLASS_BITS)));

    if (EncryptionUtils.doDebug) console.log("few tests:")
    if (EncryptionUtils.doDebug) console.log(ip_long_unsigned)

    class_bits = _classBits[ip_long_unsigned];
    //1.missing ntohl function: input = ntohl(input);

    if (EncryptionUtils.doDebug) console.log("class_bits r:"+class_bits)

    let input = ip_numeric;

    if (EncryptionUtils.doDebug) console.log("pass_bits: "+pass_bits)
    pbits = Math.max(pass_bits, class_bits);
    
    if (EncryptionUtils.doDebug) console.log("pbits:"+pbits)

    //let key = DECRYPTION_KEYS[KEY_NAME_IP_DECRYPTION];
    let key = encryptionKey;
    if (EncryptionUtils.doDebug) console.log("key:" + key);
    let k_as_hex_value = EncodingUtils.convertStringToHex(key);
    if (EncryptionUtils.doDebug) console.log("key as hex value:" + k_as_hex_value);
    let key_as_hex = CryptoJS2.enc.Hex.parse(k_as_hex_value);

    let padding = 0; //TODO: to fix hard-coded value

    if (EncryptionUtils.doDebug) console.log("padding:" + padding)

    for (i = 31; i > pbits - 1; --i) {
      if (EncryptionUtils.doDebug) console.log("\nNew round #" + i + "\n");
      ip_numeric &= m;
      // b4_in = EncodingUtils.swap32(b4_in);
      if (EncryptionUtils.doDebug) console.log("ip_numeric = " + ip_numeric);

      ip_numeric |= (padding & ~m)

      //let ip_before_swap = EncodingUtils.fromIntToIPv4String(ip_numeric);
      // console.log("ip_numeric before swap:"+ip_before_swap)

      //console.log("ip_numeric (" + ip_numeric + "), ip (" + ip_before_swap + ") before swap")
      // ip_numeric = EncodingUtils.reverseBits(ip_numeric)

      ip_numeric = EncodingUtils.swap32(ip_numeric);
      if (EncryptionUtils.doDebug) console.log("ip_numeric after swap:" + ip_numeric)

      if (EncryptionUtils.doDebug) console.log("bits revrsed: " + ip_numeric);
      let ip = EncodingUtils.fromIntToIPv4String(ip_numeric);
      if (EncryptionUtils.doDebug) console.log("ip4 reversed: " + ip);
      //let ip_numeric_as_hex = EncodingUtils.numericToHex(ip_numeric)

      let ip_numeric_as_hex = EncodingUtils.decimalHexTwosComplement(ip_numeric);
      let ip_numeric_in_hex = CryptoJS2.enc.Hex.parse(ip_numeric_as_hex);

      //let msg_parsed_in_b64 = CryptoJS2.enc.Base64.parse(EncodingUtils.convertFromHexToBase64(ip_numeric.toString(16)));
      //let msg_parsed_in_bytes = EncodingUtils.convertFromHexToBytes(ip_numeric.toString(16));

      if (EncryptionUtils.doDebug) console.log("ip4 hex:" + ip_numeric_in_hex);
      if (EncryptionUtils.doDebug) console.log("ip4 hex:"+ip_numeric.toString(16))
      if (EncryptionUtils.doDebug) console.log("ip4 hex:"+EncodingUtils.decimalHexTwosComplement(ip_numeric))
      // console.log("encrypting value in b64:"+EncodingUtils.convertFromHexToBase64(msg_parsed_in_hex))
      // console.log("encrypting value in bytes:"+msg_parsed_in_bytes)

      // if (msg_parsed_in_hex == "97ae00") {
      //   console.log("appending some bytes..")
      //   msg_parsed_in_hex = CryptoJS2.enc.Hex.parse("0097ae00")
      // }


      // Encrypt
      let ciphertext_hex = EncryptionUtils.encrypt_core(ip_numeric_in_hex, key_as_hex);

      if (EncryptionUtils.doDebug) console.log("\nEncrypted..");
      // let cipher_text_bytes = EncodingUtils.convertFromHexT41881e3coBytes(ciphertext);
      if (EncryptionUtils.doDebug) console.log("ciphertext in hex:" + ciphertext_hex);
      // if (EncryptionUtils.doDebug) console.log("ciphertext in hex:" + EncodingUtils.fromBytesInt32(cipher_text_bytes));

      if (EncryptionUtils.doDebug) console.log("ciphertext in decimal:" + parseInt((ciphertext_hex as any), 16));

      // taking first 8 bytes
      let ciphertext_cut = (ciphertext_hex as any).toString().substring(0, 8);

      if (EncryptionUtils.doDebug) console.log("ciphertext in hex cut (8 first bytes):" + ciphertext_cut);
      if (EncryptionUtils.doDebug) console.log("ciphertext in decimal cut (8 first bytes):" + parseInt(ciphertext_cut, 16));

      if (EncryptionUtils.doDebug) console.log("ciphertext in decimal cut IP = " + EncodingUtils.fromIntToIPv4String(parseInt(ciphertext_cut, 16)));
      var reversed_ciphertext = parseInt("0x" + ciphertext_cut.match(/../g).reverse().join(""));
      if (EncryptionUtils.doDebug) console.log("ip4 in decimal:" + reversed_ciphertext);
      if (EncryptionUtils.doDebug) console.log("ip4 hex:" + reversed_ciphertext.toString(16));

      let low_byte_in_hex = ciphertext_cut.substring(0, 2);
      let low_decimal = parseInt(low_byte_in_hex, 16);
      if (EncryptionUtils.doDebug) console.log("lowByte:" + low_decimal);
      if (EncryptionUtils.doDebug) console.log("lowByte && 0xFF:" + CryptoJS2.enc.Hex.parse(low_decimal));

      //let high = (low_decimal >> 8).toString(16);
      //if (EncryptionUtils.doDebug) console.log("high:"+high)
      if (EncryptionUtils.doDebug) console.log("(lowByte & 1):" + (low_decimal & 1));
      if (EncryptionUtils.doDebug) console.log("((lowByte & 1) << (31 - i)):" + ((low_decimal & 1) << (31 - i)));

      output |= (low_decimal & 1) << (31 - i);
      if (EncryptionUtils.doDebug) console.log("output after output |=.." + output);
      if (EncryptionUtils.doDebug) console.log("output after output to hex |=.." + EncodingUtils.decimalHexTwosComplement(output));

      if (EncryptionUtils.doDebug) console.log("i = " + i);

      ip_numeric = EncodingUtils.swap32(ip_numeric);

      if (EncryptionUtils.doDebug) console.log("ip_numeric afterswap = " + ip_numeric);
      if (EncryptionUtils.doDebug) console.log("before m <<= 1: " + m);
      m <<= 1;
      if (EncryptionUtils.doDebug) console.log("after m <<= 1: " + m);

      if (EncryptionUtils.doDebug) console.log("output:" + output);

      if (EncryptionUtils.doDebug) console.log("ip_numeric = " + ip_numeric);
      if (EncryptionUtils.doDebug) console.log("output (" + output + ") ^ input (" + input + ")");

      new_ip_address = output ^ input;

      if (EncryptionUtils.doDebug) console.log("new_ip_address:" + new_ip_address);

      if (EncryptionUtils.doDebug) console.log("new_ip_address as hex:" + new_ip_address.toString(16));

      let new_ip_address_text = EncodingUtils.fromIntToIPv4String(new_ip_address);
      if (EncryptionUtils.doDebug) console.log("ip address back to string = " + new_ip_address_text);
    }
    new_ip_address = output ^ input;
    if (EncryptionUtils.doDebug) console.log("outer new_ip_address:" + new_ip_address);
    if (EncryptionUtils.doDebug) console.log("outer new_ip_address as hex:" + new_ip_address.toString(16));
    let new_ip_address_text = EncodingUtils.fromIntToIPv4String(new_ip_address);
    if (EncryptionUtils.doDebug) console.log("outer ip address back to string = " + new_ip_address_text);
    return new_ip_address;
  }

  static encrypt_core(ip_numeric_in_hex, key_as_hex) {
    let ciphertext = null;
    let ciphertext_hex = null;
    //console.log("EncryptionUtils.encryptionAlgorithm: "+EncryptionUtils.encryptionAlgorithm)
    switch(EncryptionUtils.encryptionAlgorithm) {
      case EncryptionAlgorithm.AES_ECB:
        ciphertext = CryptoJS2.AES.encrypt(ip_numeric_in_hex, key_as_hex, {
          format: JsonFormatter,
          mode: CryptoJS2.mode.ECB,
          padding: CryptoJS2.pad.ZeroPadding,
        });
        let cipher_text_base64 = (ciphertext as any).toString();
        if (EncryptionUtils.doDebug) console.log("ip out base64:" + cipher_text_base64)
        
        ciphertext_hex = (ciphertext as any).ciphertext;

        break;
      case EncryptionAlgorithm.HMAC_SHA256:
        if (EncryptionUtils.doDebug) console.log("Encrypting with HMAC_SHA256")
        if (EncryptionUtils.doDebug) console.log("ip_numeric_in_hex:"+ip_numeric_in_hex)
        if (EncryptionUtils.doDebug) console.log("key_as_hex:"+key_as_hex)
        //ciphertext = CryptoJS2.HmacSHA256(CryptoJS2.enc.Hex.parse("0402a8c0"), CryptoJS2.enc.Hex.parse(key_as_hex)).toString(CryptoJS2.enc.Hex)
        //ciphertext = CryptoJS.HmacSHA256(CryptoJS.enc.Hex.parse("0402a8c0"), CryptoJS.enc.Hex.parse("64666665313836333432376465353330")).toString(CryptoJS.enc.Hex).toUpperCase()
        ciphertext = CryptoJS2.HmacSHA256(ip_numeric_in_hex, key_as_hex).toString(CryptoJS2.enc.Hex).toUpperCase()

        if (EncryptionUtils.doDebug) console.log("ciphertext:"+ciphertext)
        ciphertext_hex = (ciphertext as any);
        break;
    }
    return ciphertext_hex;
  }
}

export class Crypter {
  public static encrypt(key, value) {
    key = CryptoJS2.enc.Utf8.parse(key);
    let ciphertext = CryptoJS2.AES.encrypt(value, key, { iv: key }).toString();
    return ciphertext;
  }

  public static decrypt(key, value) {
    key = CryptoJS2.enc.Utf8.parse(key);
    let decryptedData = CryptoJS2.AES.decrypt(value, key, {
      iv: key,
    });
    return decryptedData.toString(CryptoJS2.enc.Utf8);
  }
}

export class EncodingUtils {
  static decode = (str: string): string => Buffer.from(str, "base64").toString("binary"); // or atob('')
  static encode = (str: string): string => Buffer.from(str, "binary").toString("base64"); // or btoa('')

  static swap16(val) {
    return ((val & 0xff) << 8) | ((val >> 8) & 0xff);
  }

  static swap32(val) {
    return ((val & 0xff) << 24) | ((val & 0xff00) << 8) | ((val >> 8) & 0xff00) | ((val >> 24) & 0xff);
  }

  static toBytesInt32(num, size) {
    let arr = new ArrayBuffer(size); // an Int32 takes 4 bytes
    new DataView(arr).setUint32(0, num, true); // byteOffset = 0; litteEndian = false
    return arr;
  }

  // static toBytesInt32 (num) {
  //   let arr = new Uint8Array([
  //        (num & 0xff000000) >> 24,
  //        (num & 0x00ff0000) >> 16,
  //        (num & 0x0000ff00) >> 8,
  //        (num & 0x000000ff)
  //   ]);
  //   return arr.buffer;
  // }

  static fromBytesInt32(numString) {
    var result = 0;
    for (let i = 3; i >= 0; i--) {
      result += numString.charCodeAt(3 - i) << (8 * i);
    }
    return result;
  }

  static reverseBits(num) {
    let reversed = num.toString(2);
    const padding = "0";
    reversed = padding.repeat(32 - reversed.length) + reversed;
    return parseInt(reversed.split("").reverse().join(""), 2);
  }

  static fromIPv4StringToInt(ip_address) {
    var ip_address_numeric = 0;
    ip_address.split(".").forEach(function (octet) {
      ip_address_numeric <<= 8;
      ip_address_numeric += parseInt(octet);
    });
    return ip_address_numeric >>> 0;
  }
  static fromIntToIPv4String(ip_address) {
    return (
      (ip_address >>> 24) +
      "." +
      ((ip_address >> 16) & 255) +
      "." +
      ((ip_address >> 8) & 255) +
      "." +
      (ip_address & 255)
    );
  }

  static encodeToBase64(value) {
    return btoa(value);
  }

  static decodeFromBase64(value) {
    return atob(value);
  }

  static convertStringToHex(raw) {
    console.log("raw:" + raw);
    let result = "";
    for (let i = 0; i < raw.length; i++) {
      const hex = raw.charCodeAt(i).toString(16);
      result += hex.length === 2 ? hex : "0" + hex;
    }
    return result;
  }

  static convertStringToHex2(input) {
    input.split("")
     .map(c => c.charCodeAt(0).toString(16).padStart(2, "0"))
     .join("");
  }

  static convertFromHexToBase64(value) {
    return Buffer.from(value.toString().toUpperCase(), "hex").toString("base64");
  }

  static convertBase64ToHex(value) {
    return EncodingUtils.convertStringToHex(EncodingUtils.decode(value));
  }

  static convertFromHexToString(value) {
    return Buffer.from(value, "hex").toString("utf8");
  }

  // refer to: https://developer.mozilla.org/en-US/docs/Web/API/TextDecoder
  static convertFromStringToUtf8Bytes(value) {
    let utf8Encoder = new TextEncoder();
    return utf8Encoder.encode(value);
  }

  static convertFromUtf8BytesToString(value) {
    let utf8Decoder = new TextDecoder();
    return utf8Decoder.decode(value);
  }

  static convertFromStringToUft16Bytes(value) {
    const bytes: any[] = []
    for (let i = 0; i < value.length; i++) {
      const code = value.charCodeAt(i); // x00-xFFFF
      bytes.push(code & 255, code >> 8); // low, high
    }
    return bytes;
  }

  static convertFromBytesToString(value) {
    return String.fromCharCode.apply(null, value);
  }

  static convertFromHexToBytes(hex) {
    for (var bytes: any[] = [], c = 0; c < hex.length; c += 2) bytes.push(parseInt(hex.substr(c, 2), 16));
    return bytes;
  }

  static convertFromBytesToHex(bytes) {
    for (var hex: any[] = [], i = 0; i < bytes.length; i++) {
      var current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
      hex.push((current >>> 4).toString(16));
      hex.push((current & 0xf).toString(16));
    }
    return hex.join("");
  }

  static convertFromHex(hex) {
    var hexToString = hex.toString();
    var str = "";
    for (var i = 0; i < hexToString.length; i += 2) str += String.fromCharCode(parseInt(hexToString.substr(i, 2), 16));
    return str;
  }

  static numericToHex(d) {
    var s = (+d).toString(16);
    if (s.length < 8) {
      for (let i = s.length; i < 8; i++) {
        s = "0" + s;
      }
    }
    return s;
  }

  static decimalHexTwosComplement(decimal) {
    var size = 8;

    if (decimal >= 0) {
      var hexadecimal = decimal.toString(16);

      while (hexadecimal.length % size !== 0) {
        hexadecimal = "" + 0 + hexadecimal;
      }

      return hexadecimal;
    } else {
      let hexadecimal = Math.abs(decimal).toString(16);
      while (hexadecimal.length % size !== 0) {
        hexadecimal = "" + 0 + hexadecimal;
      }

      var output = "";
      for (let i = 0; i < hexadecimal.length; i++) {
        output += (0x0f - parseInt(hexadecimal[i], 16)).toString(16);
      }

      output = (0x01 + parseInt(output, 16)).toString(16);
      return output;
    }
  }
}
