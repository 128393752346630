// SetupWizardContext.tsx

import React, { createContext, useState } from 'react';
import { userAtom } from "../../common/Atoms";
import { useRecoilState } from "recoil";

interface SecurityKeysState {
  masterKey: string;
  dataEncryptionKey: string;
  apiAccessKey: string;
  apiSecretKey: string;
  organizationKey: string;
  tokenKey: string;
  certificateUuid?: string;
}

interface SetupWizardContextProps {
  securityKeys: SecurityKeysState;
  setSecurityKeys: React.Dispatch<React.SetStateAction<SecurityKeysState>>;
  resetSecurityKeys: () => void;
}

export const SetupWizardContext = createContext<SetupWizardContextProps | null>(null);


export const SetupWizardProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [user] = useRecoilState(userAtom);
  const [securityKeys, setSecurityKeys] = useState<SecurityKeysState>({
    masterKey: '',
    dataEncryptionKey: '',
    apiAccessKey: '',
    apiSecretKey: '',
    organizationKey: '',
    tokenKey: user.token,
    certificateUuid: ''
  });

  const resetSecurityKeys = () => {
    setSecurityKeys({
      masterKey: '',
      dataEncryptionKey: '',
      apiAccessKey: '',
      apiSecretKey: '',
      organizationKey: '',
      tokenKey: user.token,
      certificateUuid: '',
    });
  };

  return (
    <SetupWizardContext.Provider value={{ securityKeys, setSecurityKeys, resetSecurityKeys }}>
      {children}
    </SetupWizardContext.Provider>
  );
};
