import { Button, Dropdown, Layout, notification } from 'antd';
import { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { URL_API_V1 } from '../constants/global';
import { userAtom } from './Atoms';
import AxiosApiInstance from './Interceptors';

const OrganizationMenu = () => {
  const { axiosApiInstance } = AxiosApiInstance();
  const [menuItems, setMenuItems] = useState<any>([]);
  const [user, setUser] = useRecoilState(userAtom);
  const [organizations, setOrganizations] = useState<any>([]);

  async function getUserProfile() {
    return axiosApiInstance
      .get(`${URL_API_V1}/profile`)
      .then((response: any) => {
        if (response && response.status === 200) {
          const organizationSlug = response.data['default_organization_slug'];
          const userUuid = response.data['uuid'];
          setUser({ ...user, uuid: userUuid, organization: organizationSlug });
          setOrganizations(response.data['organizations']);
        }
      })
      .catch((exception) => {
        console.log('Exception in getUserProfile:', exception);
      });
  }

  const getOrganisationBySlug = (organization_slug) => {
    console.log('getOrganisationBySlug');
    const selectedOrganization = organizations.find(
      (item) => item.slug === organization_slug
    );
    if (selectedOrganization) {
      console.log('Match found:', selectedOrganization);
    }
    return selectedOrganization;
  };

  const setDefaultOrganization = (organization_slug) => {
    console.log('setDefaultOrganization');
    console.log('organization_slug:', organization_slug);

    const selectedOrganization = getOrganisationBySlug(organization_slug);

    if (!selectedOrganization) {
      console.error(`Organization with slug ${organization_slug} not found`);
      notification.error({
        message: `The organization '${organization_slug}' could not be found.`,
        duration: 1.5,
      });
      return;
    }

    const newDefaultOrgToUpdate = {
      default_organization: selectedOrganization.uuid,
    };
    console.log('Data:', JSON.stringify(newDefaultOrgToUpdate));

    axiosApiInstance
      .patch(`${URL_API_V1}/profile/${user.uuid}/`, newDefaultOrgToUpdate)
      .then((response) => {
        if (response.status === 200) {
          notification.success({
            message: `The organization '${organization_slug}' has been set as default.`,
            duration: 1.5,
          });
          getUserProfile();
        } else {
          notification.error({
            message: 'The update failed - an unexpected error has occurred.',
          });
        }
      })
      .catch((error) => {
        console.error('Error updating default organization:', error);
        notification.error({
          message: 'An error occurred while updating the default organization.',
        });
      });
  };

  const handleMenuClick = ({ key }) => {
    console.log('Selected organization slug:', key);
    setDefaultOrganization(key);
    setUser({ ...user, organization: key });
    window.location.reload();
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    if (organizations.length > 0) {
      const items = organizations.map((item: any) => ({
        key: item.slug,
        label: item.name,
      }));
      setMenuItems(items);
    }
  }, [organizations]);

  return (
    <div>
      <Dropdown menu={{ items: menuItems, onClick: handleMenuClick }}>
        <Button>
          {user.organization ? user.organization : 'Organization'}{' '}
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default OrganizationMenu;
