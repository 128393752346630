const API_VERSION_1 = "/api/v1"
const API_VERSION_2 = "/api/v2"
let REACT_APP_URL = process.env.REACT_APP_API_URL
const URL_CHARTS = process.env.REACT_APP_API_URL_CHART
const URL_API_V1 = REACT_APP_URL + API_VERSION_1
const URL_API_V2 = REACT_APP_URL + API_VERSION_2
const REACT_APP_RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY
const DATA_SAMPLING_MASTER_KEY = process.env.REACT_APP_DEMO_DATA_SAMPLING_MASTER_KEY
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN_REACT
const ENV = process.env.REACT_APP_ENV
const ENABLE_SOCIAL_LOGIN = process.env.REACT_APP_ENABLE_SOCIAL_LOGIN
const MAX_RETRY_ATTEMPTS = 3
let tmp_host = ""
let tmp_port = "443"
if (process.env.REACT_APP_API_URL !== undefined) {
    let index = process.env.REACT_APP_API_URL.indexOf('://')
    if (index === undefined) {
        index = 0
    }
    index +=3
    tmp_host = process.env.REACT_APP_API_URL.substring(index, process.env.REACT_APP_API_URL?.length)

    let indexSemiColon = tmp_host.lastIndexOf(':')
    if (indexSemiColon < 0) {
        if (tmp_host === 'localhost') {
            tmp_port = "80"
        }
    } else {
        tmp_port = tmp_host.substring(indexSemiColon + 1, process.env.REACT_APP_API_URL?.length)
        tmp_host = tmp_host.substring(0, indexSemiColon)
    }
}

const HOST = tmp_host
const PORT = tmp_port

console.debug(`URL => ${URL_API_V1}`)
console.debug(`HOST => ${HOST}`)
console.debug(`URL_CHARTS => ${URL_CHARTS}`)
console.debug(`REACT_APP_RECAPTCHA_KEY => ${REACT_APP_RECAPTCHA_KEY}`)

let reCAPTCHAKEY: string;
reCAPTCHAKEY = process.env.REACT_APP_RECAPTCHA_KEY ? process.env.REACT_APP_RECAPTCHA_KEY : ""
console.debug(`reCAPTCHAKEY => ${reCAPTCHAKEY}`)

console.debug(`SENTRY_DSN => ${SENTRY_DSN}`)
console.debug(`Recaptcha => ${reCAPTCHAKEY}`)
console.debug(`ENV => ${ENV}`)
console.debug(`ENABLE_SOCIAL_LOGIN => ${ENABLE_SOCIAL_LOGIN}`)
console.debug(`DATA_SAMPLING_MASTER_KEY => ${DATA_SAMPLING_MASTER_KEY}`)
export { URL_API_V1, URL_API_V2, HOST, PORT, URL_CHARTS, reCAPTCHAKEY, ENV, ENABLE_SOCIAL_LOGIN, SENTRY_DSN, DATA_SAMPLING_MASTER_KEY, MAX_RETRY_ATTEMPTS }
