import { Card, Col, Row } from 'antd';
import { Suspense, lazy } from 'react';
import styles from './Dashboard.module.scss';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../common/Atoms';

// import ThroughputChart from 'components/Charts/ThroughputChart';
// import StorageUsageChart from 'components/Charts/StorageUsageChart';

const ThroughputChart = lazy(() => import('components/Charts/ThroughputChart'));
const StorageUsageChart = lazy(() => import('components/Charts/StorageUsageChart'));

function Dashboard() {
  const [user] = useRecoilState(userAtom);

  return (
    <>
      <Card title="Dashboard" style={{ margin: '0px 25px 0 25px' }}>
        <Row>
          <Col span={24} order={1}>
            <Card title="Events & Volume in bytes" style={{ margin: '5px' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <ThroughputChart />
              </Suspense>
            </Card>
          </Col>

          <Col span={12} order={2}>
            <Card title="Data storage usage" style={{ margin: '5px' }}>
              <Suspense fallback={<div>Loading...</div>}>
                {/* <StorageUsageChart /> */}
              </Suspense>
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
}
export default Dashboard;
