import React, { useEffect, useContext, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Table,
  Tooltip,
  Dropdown,
  Menu,
  Popconfirm,
} from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useRecoilState } from "recoil";
import FileSaver from "file-saver";
import { styleModeAtom, userAtom } from "../../common/Atoms";
import { URL_API_V1 } from "../../constants/global";
import AxiosApiInstance from "../../common/Interceptors";
import { SetupWizardContext } from "../SetupWizard/SetupWizardContext";
import { getDateWithTooltip } from "utils/utils";

interface AgentServiceProps {}

interface Certificate {
  uuid: string;
  name: string;
  created_on: string;
  principal: string;
  is_active: boolean;
  not_valid_before: string;
  not_valid_after: string;
  serial: string;
  deactivated_on: string | null;
}

const Certificates: React.FC<AgentServiceProps> = () => {
  const [themeState] = useRecoilState(styleModeAtom);
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();
  const [loading, setLoading] = useState(true);
  const [agentCerts, setAgentCerts] = useState<Certificate[]>([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newCertName, setNewCertName] = useState("");

  const context = useContext(SetupWizardContext);
  const isUsingContext = Boolean(context);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: Certificate[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);

    if (context && selectedRows && selectedRows.length > 0) {
      const selectedCert = selectedRows[0];
      context.setSecurityKeys((prevKeys) => ({
        ...prevKeys,
        certificateUuid: selectedCert.uuid, // Assuming you have a certificate field
      }));
    }
  };

  const orgKeyAPIKeys = {
    tokens: [],
    client_key: "",
    kafka: {},
    backend_server: {},
    key_server: {},
  };

  const [propsConfigs, setPropsConfigs] = useState({
    loading: true,
    hasData: false,
    data: orgKeyAPIKeys,
  });

  const handleDownload = (uuid: string) => {
    getAgentCertificateDetail(uuid);
  };

  const handleDelete = async (uuid: string) => {
    try {
      setLoading(true);
      const response = await axiosApiInstance.delete(
        `${URL_API_V1}/r/${user.organization}/kafka/agent-certs/${uuid}/`
      );

      if (response.status === 204) {
        notification.success({
          message: "Success",
          description: "Certificate deleted successfully.",
        });
        getAgentCertificates(); // Reload the certificates after deletion
      } else {
        notification.error({
          message: "Error",
          description: "Failed to delete certificate.",
        });
      }
    } catch (error) {
      console.error("Error deleting certificate:", error);
      notification.error({
        message: "Error",
        description: "Failed to delete certificate.",
      });
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const response = await axiosApiInstance.post(
        `${URL_API_V1}/r/${user.organization}/kafka/agent-certs/`,
        {
          name: newCertName,
        }
      );

      const entry: Certificate = response.data;
      if (response && response.status === 201) {
        notification.success({
          message: "Success",
          description: "Certificate created successfully.",
        });
        setIsModalVisible(false);
        setNewCertName("");
        setAgentCerts([...agentCerts, entry]); // Use array spread syntax to add the new entry
        getAgentCertificates(); // Reload the certificates after creation
      }
    } catch (error) {
      console.error("Error creating certificate:", error);
      notification.error({
        message: "Error",
        description: "Failed to create certificate.",
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "uuid",
      key: "uuid",
      render: (uuid: string) => uuid.substring(0, 5),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => name,
    },
    {
      title: "Principal",
      dataIndex: "principal",
      key: "principal",
      render: (text: string) => extractCN(text),
    },
    {
      title: "Created",
      dataIndex: "created_on",
      key: "created_on",
      render: (text: string) => (
        getDateWithTooltip(text)
      ),
    },
    {
      title: "Not valid before",
      dataIndex: "not_valid_before",
      key: "not_valid_before",
      render: (text: string) => (
        getDateWithTooltip(text)
      ),
    },
    {
      title: "Not valid after",
      dataIndex: "not_valid_after",
      key: "not_valid_after",
      render: (text: string) => (
        getDateWithTooltip(text)
      ),
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active: boolean) => (is_active ? "Yes" : "No"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: Certificate) => {
        const menu = (
          <Menu>
            <Menu.Item key="download">
              <Tooltip title="Download the certificate" placement="right">
                <Button
                  type="text"
                  size="small"
                  icon={<DownloadOutlined />}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    handleDownload(record.uuid);
                  }}
                >
                  Download
                </Button>
              </Tooltip>
            </Menu.Item>
            <Menu.Item key="delete">
              <Popconfirm
                title="Are you sure you want to delete this certificate?"
                onConfirm={(e) => {
                  e?.stopPropagation();
                  handleDelete(record.uuid);
                }}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined /> Delete
              </Popconfirm>
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button size="small" onClick={(e) => e.stopPropagation()}>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const extractCN = (principal: string) => {
    const cnMatch = principal.match(/CN=([^,]+)/);
    return cnMatch ? cnMatch[1] : principal;
  };

  function getAgentConfigurations() {
    setPropsConfigs({ ...propsConfigs, loading: true });

    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/configurations/agents`)
      .then((response) => {
        setPropsConfigs({ ...propsConfigs, loading: false });
        setLoading(false);
        if (response && response.status === 200) {
          const responseData: any = response.data;
          console.log(responseData);
          setPropsConfigs({ ...propsConfigs, data: responseData });
        } else {
          setPropsConfigs({ ...propsConfigs, data: orgKeyAPIKeys });
        }
      });
  }

  function getAgentCertificates() {
    setPropsConfigs({ ...propsConfigs, loading: true });

    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/kafka/agent-certs/`)
      .then((response) => {
        setPropsConfigs({ ...propsConfigs, loading: false });
        setLoading(false);
        if (response && response.status === 200) {
          const responseData: any = response.data;
          console.log(responseData);

          setAgentCerts(responseData.certificates);
        }
      });
  }

  function getAgentCertificateDetail(uuid: string) {
    setPropsConfigs({ ...propsConfigs, loading: true });

    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/kafka/agent-certs/${uuid}/`, {
        responseType: "blob",
      })
      .then((response) => {
        setPropsConfigs({ ...propsConfigs, loading: false });
        setLoading(false);
        if (response && response.status === 200) {
          const responseData: any = response.data;
          console.log(responseData);
          const fileNameHeader = "filename";
          const suggestedFileName = response.headers[fileNameHeader];
          const effectiveFileName =
            suggestedFileName === undefined
              ? `agent-service-certificates.zip`
              : suggestedFileName;
          // Let the user save the file.
          FileSaver.saveAs(responseData, effectiveFileName);
        }
      });
  }

  useEffect(() => {
    getAgentConfigurations();
    getAgentCertificates();
    //getAgentCertificateDetail("6df2ab03-b23b-4db8-8a98-a81d046d7e3d");
  }, []);

  useEffect(() => {
    console.log("agentCerts:", agentCerts);
  }, [agentCerts]);

  return (
    <Card>
      <Row>
        <Col>
          <h3>Agent Certificates</h3>
        </Col>
      </Row>
      <Row
        style={{
          marginBottom: "8px", // Reduced margin
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col span={12}>
          {/* You can add additional controls here if needed */}
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={showModal}>
            Add Certificate
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            dataSource={agentCerts}
            columns={columns}
            rowKey="uuid"
            bordered
            loading={loading}
            rowSelection={
              isUsingContext
                ? {
                    type: "radio", // Use "checkbox" for multi-select
                    selectedRowKeys,
                    onChange: onSelectChange,
                  }
                : undefined
            }
            onRow={(record) => ({
              onClick: () => {
                if (isUsingContext) {
                  onSelectChange([record.uuid], [record]);
                }
              },
              style: {
                cursor: isUsingContext ? "pointer" : "default", // Conditionally set cursor
              },
            })}
          />
        </Col>
      </Row>
      <Modal
        title="Add Certificate"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Create"
        cancelText="Cancel"
      >
        <Form layout="vertical">
          <Form.Item
            label="Name"
            required
            tooltip="Enter a name for the certificate."
          >
            <Input
              value={newCertName}
              onChange={(e) => setNewCertName(e.target.value)}
              placeholder="Certificate Name"
            />
          </Form.Item>
          {/* Add more form fields here if needed */}
        </Form>
      </Modal>
    </Card>
  );
};

export default Certificates;
