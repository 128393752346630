import { MinusCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { userSecretsAtom } from "common/Atoms";
import { DEFAULT_DATETIME_EVENTS_FORMAT, TIMEZONE } from "constants/user";
import useEventFields from "hooks/getEventsAllFields";
import { useEventFieldValuesApi } from "hooks/getEventsFieldValues";
import { useTimeRangeCalculation } from "hooks/useTimeRangeCalculation";
import moment from "moment";
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useRecoilState } from "recoil";
import { genericSorter } from "utils/sortingUtils";
import { EventDetailsRenderer } from "../components/Events/EventDetailsRenderer";
import styles from "../components/Events/Events.module.scss";
import { TableCellRenderer } from "../components/Events/TableCellRenderer";
import { usePIIFields } from "../contexts/PIIFieldsContext";
import { useTimeRange } from "../contexts/TimeRangeContext";

type EventsTableContextType = {
  searchComponentRef: React.MutableRefObject<SearchComponentMethods | null>;
  tableFields: TableField[];
  setTableFields: React.Dispatch<React.SetStateAction<TableField[]>>;
  tableColumns: EventData[];
  setTableColumns: React.Dispatch<React.SetStateAction<EventData[]>>;
  selectedEntry: any;
  eventsFields: any[];
  setEventsFields: React.Dispatch<React.SetStateAction<any[]>>;
  handleIconAddField: (fieldName: string) => void;
  handleIconRemoveField: (fieldName: string) => void;
  appendEqualsFilter: (fieldName: string, fieldValue: string) => void;
  getSuggestionsForField: (field: string) => Promise<FieldValue[]>;
  calculateTimeRange: (
    timeRangeType: string,
    selectedRelativeTime: string,
    selectedAbsoluteDates: moment.Moment[]
  ) => { startTime: number; endTime: number };
  continuousView: boolean;
  setContinuousView: React.Dispatch<React.SetStateAction<boolean>>;
  isSelectFieldsViewVisible: boolean;
  setIsSelectFieldsViewVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isEventDetailViewVisible: boolean;
  setIsEventDetailViewVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
};

const FIELD_NAME_TIMESTAMP = "_timestamp";
const FIELD_NAME_EVENT_TYPE = "meta_ss_event_type";
const FIELD_NAME_INGESTION_ID = "ingestion_id";

const renderEpoch = (epoch: number) => {
  const date = moment(epoch / 1000).tz(TIMEZONE);

  const formattedDate = date.isValid()
    ? date.format(DEFAULT_DATETIME_EVENTS_FORMAT)
    : "-";

  return <div className={styles.columnHeader}>{formattedDate}</div>;
};

interface EventDetailsRendererProps {
  text: string;
  event: Event;
  setSelectedEntry: (event: Event) => void;
}

const EventsTableContext = createContext<EventsTableContextType | undefined>(
  undefined
);

export const EventsTableProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [continuousView, setContinuousView] = useState(false);
  const [isSelectFieldsViewVisible, setIsSelectFieldsViewVisible] =
    useState(false);
  const [isEventDetailViewVisible, setIsEventDetailViewVisible] =
    useState(false);
  const {
    maskingTechniques,
    piiFields,
    decryptedPiiFields,
    isLoading,
    getIconIfPiiField,
  } = usePIIFields();
  const [userSecrets] = useRecoilState(userSecretsAtom);
  const [key, setKey] = useState(0);
  const defaultTableColumns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "seq",
        key: "seq",
      },
      {
        title: "Timestamp",
        showSorterTooltip: false,
        dataIndex: FIELD_NAME_TIMESTAMP,
        key: FIELD_NAME_TIMESTAMP,
        sorter: (a: EventData, b: EventData) =>
          genericSorter(a, b, FIELD_NAME_TIMESTAMP),
        render: renderEpoch,
        width: 240,
        fixed: "left",
      },
      {
        title: "Event Type",
        showSorterTooltip: false,
        dataIndex: FIELD_NAME_EVENT_TYPE,
        key: FIELD_NAME_EVENT_TYPE,
        sorter: (a: EventData, b: EventData) =>
          genericSorter(a, b, FIELD_NAME_EVENT_TYPE),
        width: 150,
      },
      {
        title: "Events",
        showSorterTooltip: false,
        dataIndex: "other",
        key: "other",
        render: (text: string, event: any) => (
          <EventDetailsRenderer
            text={text}
            event={event}
            setSelectedEntry={setSelectedEntry}
          />
        ),
      },
    ],
    [maskingTechniques, decryptedPiiFields, userSecrets]
  );

  useEffect(() => {
    console.log("EventsTableContext useEffect()")
  }, []);

  useEffect(() => {
    setKey((prev) => prev + 1); // Every time decryptedPiiFields updates, change the key
  }, [decryptedPiiFields]);

  const { timeRangeType, selectedAbsoluteDates, selectedRelativeTime } =
    useTimeRange();

  const calculateTimeRange = useTimeRangeCalculation();
  const { startTime, endTime } = calculateTimeRange(
    timeRangeType,
    selectedRelativeTime,
    selectedAbsoluteDates
  );

  const { getEventsFieldValuesApi, loading: eventFieldsValuesLoading } =
    useEventFieldValuesApi();
  const { eventFields: eventFieldsValues, getAllFields } = useEventFields();
  const [tableFields, setTableFields] = useState<TableField[]>([]);
  const [tableColumns, setTableColumns] = useState<EventData[]>([]);
  const [allColumns, setAllColumns] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState<any>({});
  const [eventsFields, setEventsFields] = useState<any[]>(eventFieldsValues);
  const [fieldAddCount, setFieldAddCount] = useState(0);
  const [isDefaultTableColumns, setIsDefaultTableColumns] = useState(true);
  const searchComponentRef = useRef<SearchComponentMethods>(null);

  useEffect(() => {
    getAllFields();
    setTableFields(eventFieldsValues);
  }, []);

  const appendEqualsFilter = (fieldName: string, fieldValue: string) => {
    searchComponentRef.current?.addTermWithValues(fieldName, "=", fieldValue);
  };

  const handleIconRemoveField = (fieldName: string) => {
    setTableColumns((prevColumns) => {
      const updatedColumns = prevColumns.filter(
        (column) => column.dataIndex !== fieldName
      );

      // Check if columns are empty after removal and reset if necessary
      if (updatedColumns.length === 0) {
        setIsDefaultTableColumns(true);
        return defaultTableColumns; // Reset to default columns if all dynamic columns are removed
      }

      return updatedColumns;
    });
  };

  useEffect(() => {
    if (isDefaultTableColumns) {
      setTableColumns(defaultTableColumns);
    }
  }, [defaultTableColumns]);

  const updateColumns = useCallback(
    (newColumn) => {
      setTableColumns((prevColumns) => {
        if (isDefaultTableColumns) {
          setIsDefaultTableColumns(false);
          return [newColumn]; // Resets to only the new column
        } else {
          return [...prevColumns, newColumn]; // Appends the new column
        }
      });
    },
    [isDefaultTableColumns, decryptedPiiFields]
  );

  const handleIconAddField = useCallback(
    (fieldName) => {
      console.log("triggered handleIconAddField", fieldName);
      const newColumn = {
        title: (
          <Tooltip
            title={
              <div className={styles.columnHeader}>
                <div className={styles.cellContent}>{fieldName}</div>
                <MinusCircleOutlined
                  className={styles.hoverIconTableColumns}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleIconRemoveField(fieldName);
                  }}
                />
              </div>
            }
          >
            <div className={styles.cellContent}>{fieldName}</div>
          </Tooltip>
        ),
        dataIndex: fieldName,
        key: fieldName,
        showSorterTooltip: false,
        sorter: (a, b) => genericSorter(a, b, fieldName),
        flex: 1,
        maxWidth: 250,
        render: (text: string, event: any) => (
          <TableCellRenderer
            fieldName={fieldName}
            text={text}
            recordId={event[FIELD_NAME_INGESTION_ID]}
          />
        ),
      };

      updateColumns(newColumn);
    },
    [tableColumns, decryptedPiiFields]
  );

  const getSuggestionsForField = (field: string): Promise<FieldValue[]> => {
    const { startTime, endTime } = calculateTimeRange(
      timeRangeType,
      selectedRelativeTime,
      selectedAbsoluteDates
    );
  
    return getEventsFieldValuesApi({
      startTime,
      endTime,
      page: 0,
      fieldName: field,
    })
    .then((response) => {
      if (response.aggs && response.aggs.histogram && response.aggs.histogram.length > 0) {
        return response.aggs.histogram[0].values.map((value) => ({
          field_name: value.zo_sql_key,
          value: value.zo_sql_num,
        }));
      } else {
        throw new Error("No histogram data found in response");
      }
    })
    .catch((err) => {
      console.error("Error fetching field values:", err);
      throw err;
    });
  };
  

  const value = {
    searchComponentRef,
    tableFields,
    setTableFields,
    tableColumns,
    setTableColumns,
    selectedEntry,
    eventsFields,
    setEventsFields,
    handleIconAddField,
    handleIconRemoveField,
    appendEqualsFilter,
    getSuggestionsForField,
    calculateTimeRange,
    continuousView,
    setContinuousView,
    isSelectFieldsViewVisible,
    setIsSelectFieldsViewVisible,
    isEventDetailViewVisible,
    setIsEventDetailViewVisible,
    isLoading,
  };

  return (
    <EventsTableContext.Provider value={value}>
      {children}
    </EventsTableContext.Provider>
  );
};

export const useEventsTableContext = () => {
  const context = React.useContext(EventsTableContext);
  if (!context) {
    throw new Error(
      "useEventsTable must be used within an EventsTableProvider"
    );
  }
  return context;
};

// If other specific functions or components are needed, they should be defined and exported here.
