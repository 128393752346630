import { Button, Card, Col, Form, notification, Row, Space, Table, Tooltip } from 'antd';
import FullScreenSpinner from 'common/FullScreenSpinner';
import 'moment-timezone';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { getDateWithTooltip } from 'utils/utils';
import { userAtom } from '../../common/Atoms';
import AxiosApiInstance from '../../common/Interceptors';
import { URL_API_V1 } from '../../constants/global';
import useAuth from '../../hooks/auth';
import ModalAddOrganization from './ModalAddOrganization';
import ModalDeleteOrganization from './ModalDeleteOrganization';

interface IOrganization {
  name: string;
  slug: string;
  uuid: string;
}

export default function Organizations() {
  const { axiosApiInstance } = AxiosApiInstance();
  const { refreshToken } = useAuth();
  const [user, setUser] = useRecoilState(userAtom);
  const [form] = Form.useForm();

  const [showAddOrganization, setShowAddOrganization] = useState(false);

  const [showDeleteOrgConfirmation, setShowDeleteOrgConfirmation] = useState(false);

  const initialData: any[] = [];
  const [propsOrgs, setPropsOrgs] = useState({ loading: false, hasData: false, data: initialData });

  const [users, setUsers] = useState({ loading: false, hasData: false, data: initialData });
  const [invitedUsers, setInvitedUsers] = useState({ loading: false, hasData: false, data: initialData });

  const [selectedOrganization, setSelectedOrganization] = useState<IOrganization>();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text) => {
        return text;
      },
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      render: (text) => {
        return text;
      },
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      render: (text) => {
        return text;
      },
    },
    {
      title: 'Created on',
      dataIndex: 'created_on',
      render: (text) => {
        return getDateWithTooltip(text)
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      search: false,
      render: (text, row) => (
        <>
          <Space>
            <Tooltip title="Delete organization">
              <Button type="link" onClick={() => showModalDeleteOrgConfirmation(row)}>
                Delete
              </Button>
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];

  async function getUserProfile() {
    return axiosApiInstance
      .get(`${URL_API_V1}/profile`)
      .then((response: any) => {
        if (response && response.status === 200) {
          let organization = response.data['default_organization_slug'];

          if (!organization) {
            console.log('user default organization not set');
          }

          setUser({ ...user, organization: organization });
        }
      })
      .catch((exception) => {
        console.log('exception getUserProfile..' + exception);
      });
  }

  const showModalDeleteOrgConfirmation = (row) => {
    setSelectedOrganization(row);
    setShowDeleteOrgConfirmation(true);
  };

  const deleteOrganization = () => {
    setPropsOrgs({ ...propsOrgs, loading: true });
    axiosApiInstance
      .delete(`${URL_API_V1}/r/${selectedOrganization?.slug}/organizations`)
      .then((response) => {
        // const responseData: any = response.data;
        setPropsOrgs({ ...propsOrgs, loading: false });
        // console.log("response:" + JSON.stringify(responseData));
        if (response.status === 200) {
          getOrganizations();
          refreshToken();
          getUserProfile();
          setSelectedOrganization(undefined);
          notification.success({ message: `The organisation '${selectedOrganization?.name}' has been deleted.` });
        } else {
          setPropsOrgs({ ...propsOrgs, loading: false, data: [] });
        }
      })
      .catch(() => {
        console.log('an error has occurred..');
      });
  };

  function getOrganizations() {
    setPropsOrgs({ ...propsOrgs, loading: true });
    axiosApiInstance.get(`${URL_API_V1}/organizations`).then((response) => {
      const responseData: any = response.data;
      setPropsOrgs({ ...propsOrgs, loading: false });
      let data = [];
      if (response.status === 200) {
        data = responseData['results'];
      } else {
        data = [];
      }
      setPropsOrgs({ ...propsOrgs, loading: false, data: data });
    });
  }

  useEffect(() => {
    getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.organizations]);

  useEffect(() => {
    form
      .validateFields()
      .then(() => {
        getOrganizations();
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <>
      {propsOrgs.loading || users.loading || invitedUsers.loading ? <FullScreenSpinner /> : null}
      <div>
        <Card title="Organizations" style={{ margin: "0px 25px 0px 25px" }}>
          <Form form={form}>
            <Row>
              <Col style={{ width: '100%' }}>
                <div style={{ textAlign: 'right' }}>
                  <Button
                    style={{ marginBottom: '1em', textAlign: 'right' }}
                    htmlType="button"
                    type="primary"
                    onClick={() => setShowAddOrganization(!showAddOrganization)}
                  >
                    Add
                  </Button>
                  <Table
                    key="slug"
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          console.log('clicked event..' + JSON.stringify(record));
                        }, // click row
                      };
                    }}
                    rowKey="slug"
                    pagination={false}
                    columns={columns}
                    dataSource={propsOrgs.data}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </Card>

        <ModalAddOrganization
          isShowing={showAddOrganization}
          hide={() => setShowAddOrganization(!showAddOrganization)}
          getOrganizations={getOrganizations}
        />

        <ModalDeleteOrganization
          isShowing={showDeleteOrgConfirmation}
          hide={() => setShowDeleteOrgConfirmation(!showDeleteOrgConfirmation)}
          selectedOrganization={selectedOrganization}
          deleteOrganization={() => deleteOrganization()}
        />
      </div>
    </>
  );
}
