import * as crypto from "crypto-browserify";
import { Buffer } from 'buffer';
window.Buffer = Buffer;

const ALGORITHM = "aes-256-gcm";
const IV_LENGTH = 16;
const SALT_LENGTH = 64;
const TAG_LENGTH = 16;
const TAG_POSITION = SALT_LENGTH + IV_LENGTH;
const ENCRYPTED_POSITION = TAG_POSITION + TAG_LENGTH;

const DO_DEBUG = true;

export class AES_GCM {
  constructor(private secret: string) {}

  getKey(salt: Buffer) {
    return crypto.pbkdf2Sync(this.secret, salt, 100000, 32, "sha512");
  }

  encrypt(plainText: string) {
    const iv = Buffer.from(crypto.randomBytes(IV_LENGTH))
    const salt = Buffer.from(crypto.randomBytes(SALT_LENGTH))

    const key = this.getKey(salt);
    const cipher = crypto.createCipheriv(ALGORITHM, key, iv);
    const plainTextCipher = Buffer.concat([cipher.update(String(plainText), "utf8"), cipher.final()]);

    const tag = cipher.getAuthTag();

    const data = Buffer.concat([salt, iv, tag, plainTextCipher]);
    const base64Data = data.toString("base64");

    if (DO_DEBUG) {
      console.log(`Salt (${salt.length} bytes): ${salt.toString('hex')}`);
      console.log(`IV (${iv.length} bytes): ${iv.toString('hex')}`);
      console.log(`Tag (${tag.length} bytes): ${tag.toString('hex')}`);
      console.log(`Derived key: ${key.toString('hex')}`);
      console.log(`Encrypted Data base64: ${base64Data}`);
      console.log(`Encrypted data length: ${data.length} bytes, hex ${data.toString('hex')}`);
    }

    return base64Data;
  }

  decrypt(cipherText: string) {    
    const stringValue = Buffer.from(String(cipherText), "base64");
    const salt = stringValue.subarray(0, SALT_LENGTH);
    const iv = stringValue.subarray(SALT_LENGTH, TAG_POSITION);
    const tag = stringValue.subarray(TAG_POSITION, ENCRYPTED_POSITION);
    const encrypted = stringValue.subarray(ENCRYPTED_POSITION);
    const key = this.getKey(salt);
    const decipher = crypto.createDecipheriv(ALGORITHM, key, iv);
  
    if (DO_DEBUG) {
      console.log("Decipher initialized with algorithm:", ALGORITHM);
      console.log(`Salt (${salt.length} bytes): ${salt.toString('hex')}`);
      console.log(`IV (${iv.length} bytes): ${iv.toString('hex')}`);
      console.log(`Tag (${tag.length} bytes): ${tag.toString('hex')}`);
      console.log(`Encrypted Data (${encrypted.length} bytes): ${encrypted.toString('hex')}`);
      console.log(`Derived Key: ${key.toString('hex')}`);
    }


    decipher.setAuthTag(tag);
  
    try {
      const decrypted = decipher.update(encrypted) + decipher.final("utf8");
      console.log("Decrypted Text:", decrypted);
      return decrypted;
    } catch (error) {
      console.error("Decryption failed:", error);
      throw error;
    }
  }
  
}
