import {
  Button,
  Card,
  Col,
  Form,
  notification,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { FC, useEffect, useState } from "react";

import ModalLeaveOrganization from "./ModalLeaveOrganization";
import ModalUserInvitation from "./ModalUserInvitation";

import { DEFAULT_DATETIME_FORMAT, TIMEZONE } from "constants/user";
import moment from "moment";
import "moment-timezone";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";

interface UsersProps {}

const StepsEnum = {
  STEP_1_USER_INVITED: "STEP_1_USER_INVITED",
  STEP_2_EMAIL_LINK_ACCESSED: "STEP_2_EMAIL_LINK_ACCESSED",
  STEP_3_USER_ACCOUNT_DOES_NOT_EXIST: "STEP_3_USER_ACCOUNT_DOES_NOT_EXIST",
  STEP_4_USER_ALREADY_EXISTS_IN_ORGANIZATION:
    "STEP_4_USER_ALREADY_EXISTS_IN_ORGANIZATION",
  STEP_4_USER_ACCEPTED_INVITATION: "STEP_4_USER_ACCEPTED_INVITATION",
  STEP_4_USER_REJECTED_INVITATION: "STEP_4_USER_REJECTED_INVITATION",
  STEP_4_OWNER_CANCELED_INVITATION: "STEP_4_OWNER_CANCELED_INVITATION",
  STEP_4_EXPIRED_INVITATION: "STEP_4_EXPIRED_INVITATION",
};

function getDateFormat(text) {
  return moment(text).tz(TIMEZONE).format(DEFAULT_DATETIME_FORMAT);
}

function renderBoolean(booleanValue) {
  let color = booleanValue ? "green" : "geekblue";
  return (
    <Tag color={color} key={booleanValue}>
      {booleanValue ? "Yes" : "No"}
    </Tag>
  );
}

const Users: FC<UsersProps> = () => {
  const [form] = Form.useForm();
  const initialData: any[] = [];
  const [propsOrgs, setPropsOrgs] = useState({
    loading: false,
    hasData: false,
    data: initialData,
  });

  const [showLeaveOrgConfirmation, setShowLeaveOrgConfirmation] =
    useState(false);

  const { axiosApiInstance } = AxiosApiInstance();
  const [user] = useRecoilState(userAtom);
  const [users, setUsers] = useState({
    loading: false,
    hasData: false,
    data: initialData,
  });
  const [invitedUsers, setInvitedUsers] = useState({
    loading: false,
    hasData: false,
    data: initialData,
  });
  const [showUserInvitation, setShowUserInvitation] = useState(false);

  function getOrganizationInvitedUsers() {
    setInvitedUsers({ ...invitedUsers, loading: true });
    axiosApiInstance
      .get(`${URL_API_V1}/r/${user?.organization}/users/invite/`)
      .then((response) => {
        const responseData: any = response.data;
        setInvitedUsers({ ...invitedUsers, loading: false });
        // console.log("getOrganizations:" + JSON.stringify(responseData));
        if (response.status === 200) {
          setInvitedUsers({
            ...invitedUsers,
            loading: false,
            data: responseData["results"],
          });
        } else {
          setInvitedUsers({ ...invitedUsers, loading: false, data: [] });
        }
      });
  }

  const removeUserFromOrganization = (userToRemove) => {
    console.log("user:" + JSON.stringify(user));

    setPropsOrgs({ ...propsOrgs, loading: true });

    axiosApiInstance
      .delete(
        `${URL_API_V1}/r/${user?.organization}/users/assignment/${userToRemove.uuid}/`
      )
      .then((response) => {
        // const responseData: any = response.data;
        setPropsOrgs({ ...propsOrgs, loading: false });
        // console.log("response:" + JSON.stringify(responseData));
        if (response.status === 204) {
          notification.success({
            message: `User ${userToRemove.email} has been removed from the organization '${user?.organization}'`,
          });
        } else {
          setPropsOrgs({ ...propsOrgs, data: [] });
          notification.error({
            message: `User ${userToRemove.email} could not be removed from the organisation '${user?.organization}'`,
          });
        }
      })
      .then(() => {
        getOrganizationUsers();
      })
      .catch(() => {
        console.log("an error has occurred..");
      });
  };

  const cancelUserInvitation = (userInvitationRow) => {
    console.log("user:" + JSON.stringify(userInvitationRow));

    setPropsOrgs({ ...propsOrgs, loading: true });

    axiosApiInstance
      .patch(
        `${URL_API_V1}/r/${user.organization}/users/invite/cancel/${userInvitationRow.id}/`
      )
      .then((response) => {
        // const responseData: any = response.data;
        setPropsOrgs({ ...propsOrgs, loading: false });
        // console.log("response:" + JSON.stringify(responseData));
        if (response.status === 200) {
          notification.success({
            message: `Invitation for user ${userInvitationRow.invited_user_email} has been canceled`,
          });
        } else {
          setPropsOrgs({ ...propsOrgs, data: [] });
          notification.error({
            message: `Invitation for user ${userInvitationRow.invited_user_email} could not be canceled`,
          });
        }
      })
      .then(() => {
        getOrganizationInvitedUsers();
      })
      .catch(() => {
        console.log("an error has occurred..");
      });
  };

  function getOrganizationUsers() {
    console.log("getOrganizationUsers()");
    setPropsOrgs({ ...propsOrgs, loading: true });

    axiosApiInstance
      .get(`${URL_API_V1}/r/${user?.organization}/users/`)
      .then((response) => {
        const responseData: any = response.data;
        setUsers({ ...users, loading: false });
        setPropsOrgs({ ...propsOrgs, loading: false });
        // console.log("getOrganizations:" + JSON.stringify(responseData));
        if (response.status === 200) {
          setUsers({ ...users, loading: false, data: responseData["results"] });
        } else {
          setUsers({ ...users, loading: false, data: [] });
        }
      });
  }

  useEffect(() => {
    getOrganizationUsers();
    getOrganizationInvitedUsers();
  }, []);

  const userColumns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Is active",
      dataIndex: "is_active",
      render: (text, row) => renderBoolean(row.is_active),
    },
    {
      title: "Is verified",
      dataIndex: "is_verified",
      render: (text, row) => renderBoolean(row.is_verified),
    },
    {
      title: "Last login",
      dataIndex: "last_login",
      render: (text, row) => getDateFormat(text),
    },
    {
      title: "Action",
      dataIndex: "action",
      search: false,
      render: (text, row) => (
        <>
          <Space size="middle">
            <Tooltip title="Remove user from organization">
              <Button
                type="link"
                onClick={() => {
                  removeUserFromOrganization(row);
                }}
              >
                Remove
              </Button>
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];

  const invitedUserColumns = [
    {
      title: "Invited user email",
      dataIndex: "invited_user_email",
      key: "invited_user_email",
    },
    {
      title: "Invited by user",
      dataIndex: "invited_by_username",
      key: "invited_by_username",
    },
    {
      title: "Invited on",
      dataIndex: "invited_on",
      render: (text) => {
        return getDateFormat(text);
      },
    },
    {
      title: "Status",
      dataIndex: "status_name",
      render: (text, row) => {
        return text;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      search: false,
      render: (text, row) => (
        <>
          <Space size="middle">
            {[
              StepsEnum.STEP_1_USER_INVITED,
              StepsEnum.STEP_2_EMAIL_LINK_ACCESSED,
              StepsEnum.STEP_3_USER_ACCOUNT_DOES_NOT_EXIST,
            ].includes(row["status"]) ? (
              <Button
                type="link"
                onClick={() => {
                  cancelUserInvitation(row);
                }}
              >
                {" "}
                Cancel
              </Button>
            ) : (
              <Space>No action</Space>
            )}
          </Space>
        </>
      ),
    },
  ];

  return (
    <div>
      <Card title="Users" style={{ margin: "0px 25px 0px 25px" }}>
        <Row>
          <Col style={{ width: "100%" }}>
            <div
              style={{
                padding: "0px 10px 0px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3>List of users</h3>
              <div style={{ marginBottom: "1em", textAlign: "right" }}>
                <Button
                  style={{ textAlign: "right" }}
                  htmlType="button"
                  type="default"
                  onClick={() => setShowLeaveOrgConfirmation(true)}
                >
                  Leave organization
                </Button>
              </div>
            </div>
            {/* <Form form={form}> */}
            <Table
              rowKey="id"
              pagination={false}
              columns={userColumns}
              dataSource={users.data}
            />
            {/* </Form> */}
          </Col>
        </Row>
        <Row>
          <Col style={{ width: "100%" }}>
            <div
              style={{
                padding: "50px 10px 0px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3>List of active user invitation</h3>

              <div style={{ marginBottom: "1em", textAlign: "right" }}>
                <Button
                  style={{ textAlign: "right" }}
                  htmlType="button"
                  type="primary"
                  onClick={() => {
                    setShowUserInvitation(!showUserInvitation);
                    console.log("setShowUserInvitation");
                  }}
                >
                  Invite user
                </Button>
              </div>
            </div>
            <Table
              rowKey="id"
              pagination={false}
              columns={invitedUserColumns}
              dataSource={invitedUsers.data}
            />
          </Col>
        </Row>
      </Card>
      <ModalLeaveOrganization
        isShowing={showLeaveOrgConfirmation}
        hide={() => setShowLeaveOrgConfirmation(!showLeaveOrgConfirmation)}
        removeUserFromOrganization={() => removeUserFromOrganization(user)}
      />
      <ModalUserInvitation
        isShowing={showUserInvitation}
        hide={() => setShowUserInvitation(!showUserInvitation)}
        getOrganizationUsers={getOrganizationUsers}
        getOrganizationInvitedUsers={getOrganizationInvitedUsers}
        selectedOrganization={user.organization}
      />
    </div>
  );
};

export default Users;
