import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { userSecretsAtom } from "./Atoms";

import { notification } from "antd";
import { userAtom } from "../common/Atoms";
import AxiosApiInstance from "../common/Interceptors";
import { URL_API_V1 } from "../constants/global";

export let KEY_NAME_IP_DECRYPTION = "CRYPTOPANT_JAVA";
export let KEY_NAME_TEXT_DECRYPTION = "AES_DET";
export let KEY_NAME_TEXT_DECRYPTION_KEY = "AES_DET_KEY";
export let KEY_NAME_TEXT_DECRYPTION_SALT = "AES_DET_KEY_SALT";
export let KEY_NAME_TEXT_DECRYPTION_ENC_KEY = "AES_DET_KEY_ENC";
export let KEY_NAME_TEXT_DECRYPTION_ENC_SALT = "AES_DET_KEY_ENC_SALT";

//export const DECRYPTION_KEYS = {};

export function PseudonymizationService() {
  const { axiosApiInstance } = AxiosApiInstance();
  const [userSecrets, setUserSecrets] = useRecoilState(userSecretsAtom);
  const [user] = useRecoilState(userAtom);

  useEffect(() => {
    const getEncryptedKeys = () => {
      if (user && user.organization) {
        axiosApiInstance
          .get(`${URL_API_V1}/r/${user.organization}/security/encryption-keys/?is_default=true`)
          .then((response) => {
            const responseData: any = response.data;
            let ddk_first = { encryption_key: "", encryption_key_time_start: "" };
            if (response.status === 200) {
              if ("entries" in responseData && responseData["entries"].length > 0) {
                ddk_first = responseData["entries"][0];
                //console.log(ddk_first);
              }
            } else {
              notification.error({
                message: `Unable to retrieve data decryption keys.`,
              });
            }
  
            setUserSecrets({
              ...userSecrets,
              ENCRYPTED_DDK: {
                encryption_key: ddk_first["encryption_key"],
                encryption_key_time_start: ddk_first["encryption_key_time_start"],
              },
            });

          })
          .catch((exception) => {
            console.log("PseudonymizerService: exception in useEffect of getEncryptedKeys(): " + exception);
          });
      }
    };

    getEncryptedKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
}
