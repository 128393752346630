import { useRecoilState } from "recoil";
import { userAtom } from "../common/Atoms";

export default function AuthorizationHeader() {
  const [user] = useRecoilState(userAtom);

  const getAuthHeader = () => {
    if (user?.token) {
      return { headers: { Authorization: "Bearer " + user?.token } }; // for Spring Boot back-end
    }
    console.log("Warning: AuthHeader empty");
    return {};
  };

  return { getAuthHeader };
}
