import React, { useState, useEffect } from 'react';

const Version = () => {
  const [version, setVersion] = useState('');
  useEffect(() => {
    // Fetch the version.json file from the public directory
    fetch('/version.json')
      .then(response => response.json())
      .then(data => {
        // Set the version information in state
        setVersion(data['version']);
      })
      .catch(error => {
        console.error('Error loading version information:', error);
      });
  }, []); // The empty array causes this effect to only run on mount

  return (<>{version}</>)
};

export default Version;
