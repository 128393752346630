
export class Utils {

    static isJWT2FAAuthenticated(jwt_decoded) {
        let is_2fa_authenticated = false;

        if("otp_device_id" in jwt_decoded) {
            let otp_device_id = jwt_decoded["otp_device_id"]

            if(otp_device_id) {
                is_2fa_authenticated = true;
            }
        }
        return is_2fa_authenticated;
    }

}