import { Modal } from "antd";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import styles from "./Users.module.scss";


export default function ModalLeaveOrganization(props) {

  const [user] = useRecoilState(userAtom);

  const [propsLeaveOrganization] = useState({
    loading: true,
    hasData: false,
  });

  const cancelLeaveOrganization = () => {
    props.hide();
  };

  const leaveOrganization = () => {
    console.log("user:" + JSON.stringify(user));
    props.removeUserFromOrganization(user);
    props.hide();
  };

  return (
    <Modal
      {...propsLeaveOrganization}
      className={styles.Organizations}
      title="Confirmation"
      open={props.isShowing}
      onOk={() => leaveOrganization()}
      onCancel={() => cancelLeaveOrganization()}
      okText="Ok"
      cancelText="Cancel"
    >
      <p style={{ fontSize: "large" }}>
        Are you sure you want to leave the organization: <b>{props.selectedOrganization?.name}</b> ?
      </p>
    </Modal>
  );
}
