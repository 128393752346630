import React, { FC } from 'react';
import { Row, Col, Alert, Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

interface StepStatusIndicatorProps {
  isComplete: boolean;
  checking: boolean;
  onCheck: () => void;
}

const StepStatusIndicator: FC<StepStatusIndicatorProps> = ({ isComplete, checking, onCheck }) => (
  <Row style={{ marginTop: 16 }}>
    <Col span={24}>
      <Alert
        message={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>
              {isComplete 
                ? 'Step completed successfully' 
                : 'This step needs to be completed  (waiting for events to be sent)'}
            </span>
            <div>
              <Button 
                type="link" 
                onClick={onCheck} 
                loading={checking}
                icon={isComplete ? <CheckCircleOutlined style={{ color: '#52c41a' }} /> : undefined}
              >
                {checking ? 'Checking...' : 'Check Status'}
              </Button>
            </div>
          </div>
        }
        type={isComplete ? 'success' : 'info'}
        showIcon
      />
    </Col>
  </Row>
);

export default StepStatusIndicator;
