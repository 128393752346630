import { Card } from "antd";
import { Content } from "antd/lib/layout/layout";
import ModalPromptMasterKey from "common/ModalPromptMasterKey";
import { FIELD_TO_EXCLUDE, useFetchEvents } from "hooks/getEvents";
import useEventFields from "hooks/getEventsAllFields";
import { useEffect, useState } from "react";
import EventDetailsDrawer from "./EventDetailsDrawer";
import EventSelectFieldsViewDrawer from "./EventSelectFieldsViewDrawer";
import EventTable from "./EventsTable";
import { useEventsTableContext } from "../../contexts/EventsTableContext";
import HistogramV3 from "./HistogramV3";
import { usePIIFields } from "../../contexts/PIIFieldsContext";
import SearchComponent from "./SearchComponent";
import { useTimeRange } from "../../contexts/TimeRangeContext";

const EventsV3 = () => {
  const [searchVersion, setSearchVersion] = useState(0);
  //const searchComponentRef = useRef<SearchComponentMethods>(null);

  const {
    queryTerms,
    setQueryTerms,
    timeRangeType,
    setTimeRangeType,
    selectedAbsoluteDates,
    setSelectedAbsoluteDates,
    selectedRelativeTime,
  } = useTimeRange();

  // const calculateTimeRange = useTimeRangeCalculation();

  const {
    selectedEntry,
    searchComponentRef,
    calculateTimeRange,
    appendEqualsFilter,
  } = useEventsTableContext();

  const { startTime: startTimeInital, endTime: endTimeInitial } =
    calculateTimeRange(
      timeRangeType,
      selectedRelativeTime,
      selectedAbsoluteDates
    );
  const [startTime, setStartTime] = useState(startTimeInital);
  const [endTime, setEndTime] = useState(endTimeInitial);

  const { events, error, histogram, fetchEvents, hasMore } =
    useFetchEvents(queryTerms);
  const { eventFields, getAllFields } = useEventFields();
  const { piiFields, decryptedPiiFields, showPIIsInClear, setShowPIIsInClear, decryptValue } =
    usePIIFields();

  const { showModalMasterKey, setShowModalMasterKey } = usePIIFields();
  const { isSelectFieldsViewVisible, setIsSelectFieldsViewVisible } =
    useEventsTableContext();
  const [isEventDetailViewVisible, setIsEventDetailViewVisible] =
    useState(false);

  const [filterFieldsText, setFilterFieldsText] = useState("");

  const [tableData, setTableData] = useState<any[]>([]);
  const [continuousView, setContinuousView] = useState(false);

  useEffect(() => {
    console.log(
      "EventsV3 useEffect events decryptedPiiFields: ",
      decryptedPiiFields
    );

    events.forEach((row) => {
      let rowIngestionId = row["ingestion_id"];
      //console.log("EventTable row: ", rowIngestionId, " => ", row)

      if(showPIIsInClear) {
        console.log("showPIIsInClear: ", showPIIsInClear)
        console.log("piiFields: ", piiFields)
        // Decrypt All PII fields
        piiFields.forEach((piiField) => {
          console.log("piiField: ", piiField)
          let fieldName = "payload" + "_" + piiField["fieldName"];
          if (fieldName in row) {
            console.log("fieldName: ", fieldName)
            const decryptedValue =  decryptValue(piiField["fieldName"], row[fieldName])
            row[fieldName] = decryptedValue;
          }
        });
      } else {
        // Decrypt PII fields
        let decryptedValues = decryptedPiiFields.filter(
          (item) => item["recordId"] == rowIngestionId
        );
        for (let decryptedPiiFieldValue of decryptedValues) {
          let fieldName = "payload" + "_" + decryptedPiiFieldValue["fieldName"];
          if (fieldName in row) {
            const showInClear = decryptedPiiFieldValue.showInClear;
            const clearValue = decryptedPiiFieldValue.decryptedValue;
            const hiddenValue = decryptedPiiFieldValue.encryptedValue;
            if (showInClear) {
              row[fieldName] = clearValue;
            } else {
              row[fieldName] = hiddenValue;
            }
          }
        }
      } 
    });

    const updatedData = events.map((item, idx) => {
      const other = Object.entries(item)
        .filter(([key]) => !FIELD_TO_EXCLUDE.includes(key))
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ");
      const seq = 1 + idx;
      return { ...item, key: item.ingestion_id, other, seq };
    });
    setTableData([...updatedData]);
  }, [events, decryptedPiiFields]);


  const performSearch = () => {
    console.log("loadData");
    const { startTime, endTime } = calculateTimeRange(
      timeRangeType,
      selectedRelativeTime,
      selectedAbsoluteDates
    );
    setStartTime(startTime);
    setEndTime(endTime);
    fetchEvents(startTime, endTime, 0, 10, queryTerms);
  }

  useEffect(() => {
    getAllFields();
    performSearch();
  }, []);

  useEffect(() => {
    const isEmptyObject = (obj: object) =>
      Object.keys(obj).length === 0 && obj.constructor === Object;

    if (!isEmptyObject(selectedEntry)) {
      setIsEventDetailViewVisible(true);
    } else {
      setIsEventDetailViewVisible(false);
    }
  }, [selectedEntry]);

  return (
    <>
      <Card title="Events" style={{ margin: "0px 25px 0px 25px" }}>
        <Content>
          <Card>
            <SearchComponent
              ref={searchComponentRef}
              loadData={performSearch}
              onTimeChange={() => console.log("onTimeChange")}
              availableFieldsParam={["ANY", ...eventFields]}
              queryTerms={queryTerms}
              setQueryTerms={setQueryTerms}
            />
          </Card>
          <Content>
            <div
              style={{
                padding: 4,
                position: "absolute",
                display: "flex",
                width: "100%",
              }}
            >
              <EventSelectFieldsViewDrawer
                isVisible={isSelectFieldsViewVisible}
                onClose={() => setIsSelectFieldsViewVisible(false)}
                filterFieldsText={filterFieldsText}
                setFilterFieldsText={setFilterFieldsText}
              />
              <EventDetailsDrawer
                isVisible={isEventDetailViewVisible}
                onClose={() => setIsEventDetailViewVisible(false)}
                selectedEntry={selectedEntry}
              />
            </div>
            <Content style={{ padding: "0px", minHeight: 100 }}>
              <HistogramV3
                startTime={startTime}
                endTime={endTime}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                currentData={histogram}
                setTableData={setTableData}
                fetchEvents={fetchEvents}
              />
              <EventTable
                fetchEvents={fetchEvents}
                setTableData={setTableData}
                tableData={tableData}
                startTime={startTime}
                endTime={endTime}
                continuousView={continuousView}
                searchVersion={searchVersion}
              />
              <ModalPromptMasterKey
                isShowing={showModalMasterKey}
                hide={() => setShowModalMasterKey(!showModalMasterKey)}
              />
            </Content>
          </Content>
        </Content>
      </Card>
    </>
  );
};

export default EventsV3;
