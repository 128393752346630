import React, { useState, useContext, useEffect } from 'react';
import { Descriptions, Typography, Button, Space, Tooltip, Collapse, Input, message } from 'antd';
import { CopyOutlined, EyeOutlined, EyeInvisibleOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { SetupWizardContext } from 'components/SetupWizard/SetupWizardContext';

const { Text } = Typography;
const { Panel } = Collapse;

interface SecurityKey {
  label: string;
  value: string;
  description?: string;
}

export interface SecurityKeysProps {
  masterKey: string;
  apiAccessKey: string;
  apiSecretKey: string;
  organizationKey: string;
  tokenKey: string;
  onUpdate: (label: string, value: string) => void;
}

const SecurityKeys: React.FC<SecurityKeysProps> = ({
  masterKey,
  apiAccessKey,
  apiSecretKey,
  organizationKey,
  tokenKey,
  onUpdate,
}) => {
  // Context usage
  const context = useContext(SetupWizardContext);
  const isUsingContext = Boolean(context);

  // State to manage visibility and editability of each key
  const [visibleKeys, setVisibleKeys] = useState<Record<string, boolean>>({});
  const [editableKeys, setEditableKeys] = useState<Record<string, boolean>>({});
  const [expanded, setExpanded] = useState(false);

  // Array of security keys with labels and descriptions
  const securityKeys: SecurityKey[] = [
    { label: 'Master Encryption Key', value: masterKey, description: 'Used for encrypting sensitive data' },
    { label: 'API Access Key', value: apiAccessKey, description: 'Used for API authentication' },
    { label: 'API Secret Key', value: apiSecretKey, description: 'Used in combination with Access Key' },
    { label: 'Organization Key', value: organizationKey, description: 'Unique identifier for your organization' },
    { label: 'Token Key', value: tokenKey, description: 'Used for session authentication' },
  ];

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).catch((err) => {
      console.error('Failed to copy!', err);
      message.error('Failed to copy to clipboard');
    });
  };

  const toggleVisibility = (label: string) => {
    setVisibleKeys((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  const toggleEditable = (label: string) => {
    setEditableKeys((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  useEffect(() => {
    // Initialize editableKeys: set to true for keys with empty values
    const initialEditableKeys: Record<string, boolean> = {};
    securityKeys.forEach((key) => {
      if (!key.value) {
        initialEditableKeys[key.label] = true;
      }
    });
    setEditableKeys(initialEditableKeys);

    // Expand Collapse if any key is empty
    const hasEmptyValue = securityKeys.some((key) => !key.value);
    setExpanded(hasEmptyValue);
  }, []); // Run only once on mount

  useEffect(() => {
    // Expand Collapse if any key becomes empty
    const hasEmptyValue = securityKeys.some((key) => !key.value);
    if (hasEmptyValue) {
      setExpanded(true);
    }
    // Do not collapse automatically
  }, [securityKeys]);

  const maskValue = (value: string) => '•'.repeat(Math.min(value.length, 20));

  return (
    <Collapse
      activeKey={expanded ? ['1'] : []}
      onChange={(keys) => setExpanded(keys.includes('1'))}
    >
      <Panel header="Security Keys" key="1">
        <Descriptions column={1}>
          {securityKeys.map((key) => {
            const isEditable = editableKeys[key.label] || !key.value;
            return (
              <Descriptions.Item
                key={key.label}
                label={
                  <Tooltip title={key.description}>
                    <Text>{key.label}</Text>
                  </Tooltip>
                }
              >
                <Space>
                  <Input
                    value={key.value}
                    onChange={(e) => onUpdate(key.label, e.target.value)}
                    disabled={!isEditable}
                    placeholder={isEditable ? 'Enter value' : ''}
                  />
                  <Space size="small">
                    <Tooltip title={isEditable ? 'Save' : 'Edit'}>
                      <Button
                        type="text"
                        icon={isEditable ? <CheckOutlined /> : <EditOutlined />}
                        onClick={() => toggleEditable(key.label)}
                      />
                    </Tooltip>
                    <Tooltip title={visibleKeys[key.label] ? 'Hide' : 'Show'}>
                      <Button
                        type="text"
                        icon={visibleKeys[key.label] ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                        onClick={() => toggleVisibility(key.label)}
                      />
                    </Tooltip>
                    <Tooltip title="Copy">
                      <Button
                        type="text"
                        icon={<CopyOutlined />}
                        onClick={() => copyToClipboard(key.value)}
                      />
                    </Tooltip>
                  </Space>
                </Space>
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      </Panel>
    </Collapse>
  );
};

export default SecurityKeys;
