
let TIMEZONE = 'America/New_York';
let DEFAULT_TIME_FORMAT = 'HH:mm:ss';
let DEFAULT_DATE_FORMAT = 'DD/MM/YYYY'
let DEFAULT_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
let DEFAULT_DATETIME_EVENTS_FORMAT = 'DD/MM/YYYY HH:mm:ss.SSS';
let DEFAULT_DATETIME_FORMAT_APEX_CHARTS = 'dd/MM/yyyy HH:mm:ss';
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
if (timezone) {
    TIMEZONE = timezone;
}

export { TIMEZONE, DEFAULT_TIME_FORMAT, DEFAULT_DATE_FORMAT, DEFAULT_DATETIME_FORMAT, DEFAULT_DATETIME_EVENTS_FORMAT, DEFAULT_DATETIME_FORMAT_APEX_CHARTS }