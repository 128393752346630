 import AnimatedRoutes from "./AnimatedRoutes";
import { ThemeProvider } from "./common/ThemeSwitcherContext";

function App() {
  return (
    <ThemeProvider>
        <div className="auth-wrapper">
          <div className="auth-inner">
            <AnimatedRoutes />
          </div>
        </div>
    </ThemeProvider>
  );
}

export default App;
