import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const RecaptchaV3Component = ({ reCAPTCHAKEY, useRecaptchaNet, useEnterprise, scriptProps, children }) => {
  if (reCAPTCHAKEY) {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={reCAPTCHAKEY}
        useRecaptchaNet={useRecaptchaNet || false}
        useEnterprise={useEnterprise || false}
        scriptProps={{
          async: false, 
          defer: false, 
          appendTo: 'head', 
          nonce: undefined, 
          ...scriptProps, // Spread operator to merge any additional scriptProps passed
        }}
      >
        {children}
      </GoogleReCaptchaProvider>
    );
  }
  return <>{children}</>;
};

export default RecaptchaV3Component;
