import React, { FC, useState } from "react";
import { Button, Space, Drawer, Checkbox, notification } from "antd";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";
import styles from "./LimitationsNotice.module.scss";

interface LimitationsNoticeProps {
  open: boolean;
  onClose: () => void;
  notificationUuid: string;
}

interface ConfigurationType {
  id: number;
  configuration_name: string;
  configuration_value: JSON;
}

const LimitationsNotice: FC<LimitationsNoticeProps> = ({ open, onClose, notificationUuid }) => {
  const [readNotice, setReadNotice] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [configuration, setConfiguration] = useState<ConfigurationType>({'id':-1, 'configuration_name': '', 'configuration_value': JSON.parse("{}")})

  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();

  const saveConfigurations = () => {
    axiosApiInstance.patch(`${URL_API_V1}/r/${user.organization}/user-notifications/${notificationUuid}`, { state: "completed" })
    .then(() => {
      notification.success({ message: "Notification state updated to completed" });
      onClose(); // Close the drawer after saving
    })
    .catch((error) => {
      console.error('Error updating notification state:', error);
      notification.error({ message: "Failed to update notification state" });
    });
  };

  return (
    <div className={styles.LimitationsNotice}>
      <Drawer
        title={`DISCLAIMERS & LIMITATIONS`}
        placement="top"
        closable={false}
        maskClosable={false}
        onClose={onClose}
        open={open}
        width={600}
        height={500}
        extra={
          <>
          </>
        }
      >
        <div
          className={styles.LimitationsNotice}
          style={{ fontSize: "medium" }}
        >
          <h3>We describe few limitations in this web-application: </h3>
          <ul>
            <li>This application is still in experimental state.</li>
            <li>The collected logs are kept only for 10 days or 1GB.</li>
            <li>
              We support only the following types of event logs: Zeek, Suricata,
              OSSEC, Nessus and OpenVAS.
            </li>
            <li>
              The user or the owner of the organization has full control of the
              data and can decide to delete the data at any time he desires.
            </li>
            <li>
              SecuritySpectrum is not responsible for any: non-detected attacks,
              any data loss or any data corruption.
            </li>
            <li>
              We do not keep any personal information except the email address.
            </li>
          </ul>
          <div className={styles.LimitationsNotice}>
          <div style={{ paddingLeft: "25px"  }}>
                  <div><Checkbox checked={readNotice} onChange={() => setReadNotice(!readNotice)}>I have read the limitations & disclaimer</Checkbox></div>
                  <div><Checkbox checked={!showNotice} onChange={() => setShowNotice(!showNotice)}>Don't show this message again</Checkbox></div>
                </div>
                <div style={{padding: "25px"}}>
              <Space.Compact size="middle">
                <Button disabled={!(readNotice)} type="primary" onClick={saveConfigurations}>
                  OK
                </Button>

              </Space.Compact>
              </div>
            </div>
        </div>
      </Drawer>
    </div>
  );
};

export default LimitationsNotice;
