import { HeartTwoTone, MessageTwoTone } from "@ant-design/icons";
import { Button, Col, Form, Input, Layout, Modal, notification, Rate, Row } from "antd";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";
import { FC, useState } from "react";
import styles from "./LeaveReview.module.scss";

const { Content } = Layout;
const { TextArea } = Input;

const LeaveReviewModal: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [form] = Form.useForm();
  const { axiosApiInstance } = AxiosApiInstance();

  const open = () => {
    setIsOpen(true);
  };

  const cancel = () => {
    setIsOpen(false);
  };

  const submit = () => {
    form.validateFields()
      .then((values) => {
        const data = {
          rate: values.rate,
          comment: values.comment,
        };
        console.log(data);
        return axiosApiInstance.post(`${URL_API_V1}/reviews/`, data);
      })
      .then((response) => {
        if (response.status === 201) {
          notification.success({
            message: "The review has been saved! Thanks for the review!",
            duration: 2,
          });
          form.resetFields();
          setIsOpen(false);
        } else {
          notification.error({
            message: "Something unexpected happened, the review was not saved.",
            duration: 2.5,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          // Handle API errors
          notification.error({
            message: "Something unexpected happened, the review was not saved.",
            duration: 2.5,
          });
        } else {
          // Handle form validation errors
          console.log("Validate Failed:", err);
        }
      });
  };

  return (
    <>
      <Button type="text" icon={<HeartTwoTone />} onClick={open}>
        Leave a review <MessageTwoTone />
      </Button>
      <span>
        <Modal
          open={isOpen}
          title="Leave a review"
          centered
          onCancel={cancel}
          footer={[
            <Button type="default" onClick={cancel}>
              Cancel
            </Button>,
            <Button type="primary" onClick={() => submit()}>
              Submit
            </Button>,
          ]}
        >
        <div className={styles.LeaveReview}>
            <Content>
              <Form form={form} initialValues={{ rate: 2.5, comment: "" }}>
                <Row gutter={24}>
                  <Col span={12}>
                    {/* <div>Which score would you give it?</div> */}
                    <Form.Item
                      label="Rate the product"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 28 }}
                      name="rate"
                      rules={[
                        {
                          required: true,
                          message: "Give it a rate!",
                        },
                      ]}
                    >
                      <Rate allowHalf />
                    </Form.Item>
                  </Col>
                  <Col>
                    <div style={{ paddingTop: "15px" }}>
                      <Form.Item
                        label="Enter any comments/feedbacks"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 28 }}
                        name="comment"
                        rules={[
                          {
                            required: true,
                            message: "Enter the comment!",
                          },
                        ]}
                      >
                        <TextArea
                          allowClear
                          maxLength={1000}
                          style={{ width: 470, height: 120, marginBottom: 24 }}
                          // prefix={<UserOutlined className="site-form-item-icon" />}
                          placeholder="Enter any comment"
                        />
                      </Form.Item>
                    </div>
                    <div>Comments are stored anonymously.</div>
                  </Col>
                </Row>
              </Form>
            </Content>
        </div>
        </Modal>
      </span>
    </>
  );
};

export default LeaveReviewModal;
