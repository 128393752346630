// SetupAgentsCommands.tsx

import React, { FC, useContext, useEffect } from 'react';
import { Tabs, Button, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import StepStatusIndicator from '../SetupWizard/StepStatusIndicator';
import SecurityKeys from 'components/SecurityKeys/SecurityKeys';
import styles from './SetupAgentsCommands.module.scss';
import { StepStatus } from '../SetupWizard/SetupWizard.types';
import { SetupWizardContext } from 'components/SetupWizard/SetupWizardContext';
import { userAtom, notificationsRefreshAtom } from '../../common/Atoms';
import { useRecoilState } from 'recoil';

const { TabPane } = Tabs;

interface SecurityKeysState {
  masterKey: string;
  apiAccessKey: string;
  apiSecretKey: string;
  organizationKey: string;
  tokenKey: string;
}

interface SetupAgentsCommandsProps {
  checking: boolean;
  stepStatus: StepStatus;
  onCheck: () => void;
  securityKeys?: SecurityKeysState;
  onUpdate?: (label: string, value: string) => void;
}

const SetupAgentsCommands: React.FC<SetupAgentsCommandsProps> = ({
  checking,
  stepStatus,
  onCheck,
  securityKeys: propsSecurityKeys,
  onUpdate: propsOnUpdate,
}) => {
  const context = useContext(SetupWizardContext);

  const [user] = useRecoilState(userAtom);
  // Determine which securityKeys to use
  const securityKeys = context
    ? context.securityKeys
    : propsSecurityKeys || {
        masterKey: '',
        apiAccessKey: '',
        apiSecretKey: '',
        organizationKey: '',
        tokenKey: '',
      };

  // Define the onUpdate function based on context or props
  const onUpdate = context
    ? (label: string, value: string) => {
        const labelToKeyMap: Record<string, keyof SecurityKeysState> = {
          'Master Encryption Key': 'masterKey',
          'API Access Key': 'apiAccessKey',
          'API Secret Key': 'apiSecretKey',
          'Organization Key': 'organizationKey',
          'Token Key': 'tokenKey',
        };
        const key = labelToKeyMap[label];
        if (key) {
          context.setSecurityKeys((prevKeys) => ({
            ...prevKeys,
            [key]: value,
          }));
        }
      }
    : propsOnUpdate || (() => {});

  const linuxCommands = `
# Set required environment variables
export ORG_SLUG="${user.organization}"
export ORG_KEY="${securityKeys.organizationKey}"
export API_ACCESS_KEY="${securityKeys.apiAccessKey}"
export API_SECRET_KEY="${securityKeys.apiSecretKey}"
export JWT_TOKEN="${securityKeys.tokenKey}"
export MASTER_KEY="${securityKeys.masterKey}"

# Download and run the installation script
curl -sL https://github.com/securityspectrum/ss-install-scripts/raw/main/install.sh | bash`;

  const windowsCommands = `
# Set required environment variables
$env:ORG_SLUG = "${user.organization}"
$env:ORG_KEY = "${securityKeys.organizationKey}"
$env:API_ACCESS_KEY = "${securityKeys.apiAccessKey}"
$env:API_SECRET_KEY = "${securityKeys.apiSecretKey}"
$env:JWT_TOKEN = "${securityKeys.tokenKey}"
$env:MASTER_KEY = "${securityKeys.masterKey}"

# Download and run the installation script
Invoke-WebRequest -Uri "https://github.com/securityspectrum/ss-install-scripts/raw/main/install.ps1" -OutFile "install.ps1"
powershell -ExecutionPolicy Bypass -File .\\install.ps1`;

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).catch((err) => {
      console.error('Failed to copy text:', err);
    });
  };

  return (
    <div className={styles.wrapper}>
      <SecurityKeys
        masterKey={securityKeys.masterKey}
        apiAccessKey={securityKeys.apiAccessKey}
        apiSecretKey={securityKeys.apiSecretKey}
        organizationKey={securityKeys.organizationKey}
        tokenKey={securityKeys.tokenKey}
        onUpdate={onUpdate}
      />
      <Tabs defaultActiveKey="linux">
        <TabPane tab="Linux/MacOS" key="linux">
          <div className={styles.codeBlockContainer}>
            <Tooltip title="Copy Code">
              <Button
                icon={<CopyOutlined />}
                onClick={() => copyToClipboard(linuxCommands)}
                className={styles.copyButton}
              />
            </Tooltip>
            <pre className={styles.codeBlock}>{linuxCommands}</pre>
          </div>
        </TabPane>
        <TabPane tab="Windows (PowerShell)" key="windows">
          <div className={styles.codeBlockContainer}>
            <Tooltip title="Copy Code">
              <Button
                icon={<CopyOutlined />}
                onClick={() => copyToClipboard(windowsCommands)}
                className={styles.copyButton}
              />
            </Tooltip>
            <pre className={styles.codeBlock}>{windowsCommands}</pre>
          </div>
        </TabPane>
      </Tabs>
      <StepStatusIndicator
        isComplete={stepStatus.hasAgentRunning}
        checking={checking}
        onCheck={onCheck}
      />
    </div>
  );
};

export default SetupAgentsCommands;
