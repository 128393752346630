import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { SENTRY_DSN } from './constants/global';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider } from 'react-query';
import queryClient from './react-query-client';
import { createRoot } from 'react-dom/client';
//import * as Sentry from '@sentry/react';
//import { BrowserTracing } from '@sentry/tracing';
// import * as Sentry from "@sentry/browser";
import App from './App';
import './index.css';

let is_sentry_enabled = SENTRY_DSN ? true : false;

if (is_sentry_enabled) {
  // Sentry.init({
  //   dsn: SENTRY_DSN,
  //   integrations: [new BrowserTracing()],
  //   // integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  //   // attachStacktrace: true,
  //   // logLevel: 1,
  // });
}

if (process.env.NODE_ENV === 'prod') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const container = document.getElementById('root');
if (!container) {
  throw new Error('Root element not found');
}
const root = createRoot(container); // Assert that container is not null.

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
