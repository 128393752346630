import React, { createContext, useContext, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { ConfigProvider, theme as antdTheme } from 'antd';
import { styleModeAtom } from './Atoms';

const { darkAlgorithm, defaultAlgorithm } = antdTheme;

const ThemeContext = createContext({
  theme: 'light',
  toggleTheme: () => {},
});

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [themeState, setThemeState] = useRecoilState(styleModeAtom);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--ant-color-bg-base', themeState.theme === 'light' ? '#f0f2f5' : '#141414');
  }, [themeState]);

  const toggleTheme = () => {
    setThemeState({ theme: themeState.theme === 'dark' ? 'light' : 'dark' });
  };

  const themeConfig = {
    algorithm: themeState.theme === 'dark' ? [darkAlgorithm] : [defaultAlgorithm],
    token: {
      colorPrimary: themeState.theme === 'light' ? '#1890ff' : '#4096ff',
      colorBgContainer: themeState.theme === 'light' ? '#ffffff' : '#141414',
      colorPrimaryBg: themeState.theme === 'light' ? '#e6f7ff' : '#111a2c',
      colorBgLayout: themeState.theme === 'light' ? '#f0f2f5' : '#141414',
      colorText: themeState.theme === 'light' ? 'rgba(0, 0, 0, 0.85)' : 'rgba(255, 255, 255, 0.75)',
      colorBgMask: themeState.theme === 'light' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
      colorBgElevated: themeState.theme === 'light' ? '#ffffff' : '#222222',
      colorBgBase: themeState.theme === 'light' ? '#f5f5f5' : '#1b1b1b',
      colorBorderBase: themeState.theme === 'light' ? '#d9d9d9' : '#333333',
      colorTextBase: themeState.theme === 'light' ? 'rgba(0, 0, 0, 0.65)' : 'rgba(255, 255, 255, 0.65)',
      colorLink: themeState.theme === 'light' ? '#1890ff' : '#fff',
      fontFamily: "Darker Grotesque",
      fontSize: 18,
    },
  };

  return (
    <ThemeContext.Provider value={{ theme: themeState.theme, toggleTheme }}>
      <ConfigProvider theme={themeConfig}>
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
