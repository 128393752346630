import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Layout, Modal, notification, Row } from "antd";
import { useCallback, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AxiosApiInstance from "../../common/Interceptors";
import RenderErrorMessages from "../../common/RenderErrorMessages";
import { URL_API_V1, reCAPTCHAKEY } from "../../constants/global";
import styles from "./Profile.module.scss";

const { Footer, Content } = Layout;

export default function UpdatePasswordModal(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { axiosApiInstance } = AxiosApiInstance();
  const [hasErrors, setErrors] = useState("");

  const [form] = Form.useForm();

  const cancelPasswordChange = useCallback((form) => {
    form.resetFields();
    setErrors("");
    props.hide();
  }, [props]);
  
  const updatePassword = useCallback((form, recaptchaToken) => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          currentPassword: values["currentPassword"],
          newPassword: values["newPassword"],
          newPasswordConfirmation: values["newPasswordConfirmation"],
          response: recaptchaToken,
        };

        axiosApiInstance
          .post(`${URL_API_V1}/auth/password/update/`, data)
          .then((response) => {
            console.log(response.status);
            if (response.status === 200) {
              cancelPasswordChange(form);
              notification.success({
                message: `Password updated with success.`,
              });
            } else {
              console.log("create failed..");
              notification.error({
                message: `An unexpeceted error has occured. Could not update the password.`,
              });
            }
            form.resetFields();
          })
          .catch((err) => {
            console.log("an error occured..:" + err);
            if (err.response?.data) {
              console.log(err.response.data);
              RenderErrorMessages(err.response.data, form);
            } else {
              setErrors("An unexpected error has occured");
            }
            console.log("post failed.." + JSON.stringify(err));
          });
      })
      .catch((err) => {
        console.log("Validate Failed:", err);
      });
    console.log("invited user to the organization..");
  }, [axiosApiInstance, cancelPasswordChange]);

  const handleReCaptchaVerify = useCallback(
    async (form) => {

      let token = '';
      if(reCAPTCHAKEY) {
        if (!executeRecaptcha) {
          console.log("Execute recaptcha not yet available");
          return;
        }

        token = await executeRecaptcha("login");
      }
      updatePassword(form, token);
    },
    [executeRecaptcha, updatePassword]
  );


  return (
    <Modal
      className={styles.Profile}
      cancelButtonProps={{ disabled: true }}
      keyboard={false}
      title="Update password"
      centered
      open={props.isShowing}
      width={600}
      onCancel={() => cancelPasswordChange(form)}
      footer={[
        <Button key="back" onClick={() => cancelPasswordChange(form)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => handleReCaptchaVerify(form)}>
          Submit
        </Button>,
      ]}
    >
      <Layout>
        <Content>
          <Form form={form}>
            <p>Current Password</p>
            <Row gutter={24}>
              <Col span={10}>
                <Form.Item
                  name="currentPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the current password",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Current password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <p>Password Confirmation</p>
            <Row gutter={24}>
              <Col span={10}>
                <Form.Item
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "Enter the new password",
                    },
                  ]}
                >
                  <Input.Password placeholder="New password" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="newPasswordConfirmation"
                  rules={[
                    {
                      required: true,
                      message: "Enter the new password confirmation",
                    },
                  ]}
                >
                  <Input.Password placeholder="New password confirmation" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
        <Footer>{hasErrors.length > 0 && <Alert type="error" message={hasErrors} banner />}</Footer>
      </Layout>
    </Modal>
  );
}
