import { LockTwoTone, UnlockTwoTone } from "@ant-design/icons";
import {
  AutoComplete,
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Switch,
  Table,
  Tag,
} from "antd";
import { KEY_NAME_IP_DECRYPTION } from "common/PseudonymizerService";
import { DEFAULT_DATETIME_FORMAT, TIMEZONE } from "constants/user";
import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { userAtom, userSecretsAtom } from "../../common/Atoms";
import { getDateWithTooltip } from "../../utils/utils"
import { EncryptDecrypt } from "../../common/Encryption";
import AxiosApiInstance from "../../common/Interceptors";
import ModalPromptMasterKey from "../../common/ModalPromptMasterKey";
import { URL_API_V1 } from "../../constants/global";
import DevicesModalComponent from "./DeviceModalViewForm";
import { usePIIFields } from "../../contexts/PIIFieldsContext";
import { useDeviceCharts } from "contexts/DeviceChartsContext";
import { useDevice } from "contexts/DeviceContext";
import { DeviceRenderer } from "./DeviceRenderer";
import { EventsTableProvider } from "contexts/EventsTableContext";
import { TimeRangeProvider } from "contexts/TimeRangeContext";
import ScatterChartECharts from "./ScatterChartECharts";

const { Panel } = Collapse;

function getIPAddressVersion(row) {
  let color = row.isIPv4 ? "geekblue" : "green";
  return (
    <Tag key={row.ipVersion}>
      {row.ipVersion === "ipV4"
        ? "IPv4"
        : row.ipVersion === "ipV6"
        ? "IPv6"
        : "Unknown"}
    </Tag>
  );
}

const DECRYPTION_KEYS = {};

const initialData: any[] = [];
const options = [{ value: "ipV4" }, { value: "ipV6" }];

function Devices() {
  const { axiosApiInstance } = AxiosApiInstance();
  const [user] = useRecoilState(userAtom);
  const [userSecrets, setUserSecrets] = useRecoilState(userSecretsAtom);
  const [ipAddressOptions, setIpAddressOptions] = useState<string[]>([]);
  const { selectedItem, setSelectedItem, selectedEntry, setSelectedEntry, showDeviceModal, setShowDeviceModal, selectedHost, setSelectedHost } = useDevice();
  const { doUpdateChart } = useDeviceCharts();
  const { maskingTechniques, getIconIfPiiFieldDevices, decryptedPiiFieldsDevices } = usePIIFields();
  const [columns, setColumns] = useState<TableColumns[]>([]);
  const { showModalMasterKey, setShowModalMasterKey } = usePIIFields();
  const [form] = Form.useForm();
  const [filters, setFilters] = useState({
    ipVersion: ["ipV4", "ipV6"],
    ipAddress: "",
  });

  function handleViewDeviceClick(deviceRow) {
    setSelectedItem(deviceRow);
    setShowDeviceModal((prev) => !prev);
    setSelectedHost(deviceRow.ipAddress); // Sets selectedHost to the clicked IP
    console.log("handleViewDeviceClick: ", deviceRow);
  }

  const defaultColumns = useMemo(
    () => [
      {
        title: "IP Address",
        dataIndex: "ipAddress",
        key: "ipAddress",
        sorter: (first, second) => first.ipAddress.localeCompare(second.ipAddress),
        render: (text, row) => (
          <span
            // style={{ border: "none", padding: "5px 15px 5px 15px" }}
            // className="selectable"
          >
            {text}
            {/* <DeviceRenderer
              fieldName="ipAddress"
              fieldValue={text}
              row={row}
              setSelectedEntry={setSelectedEntry}
            /> */}
          </span>
        ),
      },
      {
        title: "IP Version",
        render: (text, row) => {
          return getIPAddressVersion(row);
        },
      },
      {
        title: "Open ports",
        dataIndex: "openPortsCount",
        key: "openPortsCount",
        render: (text, row) => {
          return text;
        },
        sorter: (a, b) => a.openPortsCount - b.openPortsCount,
      },
      {
        title: "First seen",
        dataIndex: "createdAt",
        render: (text) => ( 
          getDateWithTooltip(text)
        ),
        sorter: (a, b) =>
          new Date(a.createdAt["$date"]).getTime() -
          new Date(b.createdAt["$date"]).getTime(),
      },
      {
        title: "Last seen",
        dataIndex: "updatedAt",
        render: (text) => ( 
          getDateWithTooltip(text)
        ),
        sorter: (a, b) =>
          new Date(a.updatedAt["$date"]).getTime() -
          new Date(b.updatedAt["$date"]).getTime(),
      },
      {
        title: "Actions",
        key: "actions",
        render: (text, record) => (
          <Button
            type="link"
            onClick={() => handleViewDeviceClick(record)}
          >
            View
          </Button>
        ),
      }
    ],
    [maskingTechniques, decryptedPiiFieldsDevices, userSecrets]
  );

  useEffect(() => {
    setColumns(defaultColumns);
  }, [decryptedPiiFieldsDevices, defaultColumns]);

  const [props, setProps] = useState({
    loading: true,
    hasData: false,
    data: initialData,
    showPIIsInClear: false,
  });

  const handleFormValuesChange = (changedValues: any, allValues: any) => {
    if (!allValues.ipAddress) {
      setSelectedHost(undefined);
    }
  };

  const handleFormSubmit = (values: any) => {
    if (values.ipAddress) {
      setSelectedHost(values.ipAddress);
    } else {
      setSelectedHost(undefined);
    }
    fetchDevices(values);
  };

  const fetchDevices = useCallback(
    (values) => {
      doUpdateChart();
      setProps((prevProps) => ({ ...prevProps, loading: true }));
      if (props.showPIIsInClear) {
        if (values["ipAddress"]) {
          let ipAddress = values["ipAddress"];
          // Handle IP address encryption if necessary
        }
      }
      const data = { params: values };

      axiosApiInstance
        .get(`${URL_API_V1}/r/${user.organization}/devices/`, data)
        .then((response) => {
          let data = [];
          if (response && response.status === 200) {
            data = response.data;
          }
          setProps((prevProps) => ({ ...prevProps, data, loading: false }));
        })
        .catch((error) => {
          console.error("Error fetching devices:", error);
          setProps((prevProps) => ({ ...prevProps, loading: false }));
        });
    },
    [props.showPIIsInClear, user.organization, axiosApiInstance, doUpdateChart]
  );

  useEffect(() => {
    props.data.forEach((row) => {
      let piiDeviceMatch = decryptedPiiFieldsDevices.filter(
        (item) =>
          item.encryptedValue === row.ipAddress ||
          item.decryptedValue === row.ipAddress
      );

      if (piiDeviceMatch.length > 0) {
        if (piiDeviceMatch[0].showInClear === false) {
          row.ipAddress = piiDeviceMatch[0].encryptedValue;
        } else {
          row.ipAddress = piiDeviceMatch[0].decryptedValue;
        }
      }
      return row;
    });

    setProps((prevProps) => ({ ...prevProps }));
  }, [decryptedPiiFieldsDevices]);

  const handleSearch = (value: string) => {
    const uniqueData = props.data.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.ipAddress === item.ipAddress)
    );

    const ipAddressSuggestions = uniqueData
      .map((row) => row.ipAddress)
      .filter((ipAddress) => ipAddress.includes(value));
    setIpAddressOptions(ipAddressSuggestions);
  };

  useEffect(() => {
    setFilters({ ...filters, ipVersion: ["ipV4", "ipV6"] });
    form
      .validateFields()
      .then((vals) => {
        fetchDevices(vals);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  function onShowInClear(checked: boolean) {
    if (checked && userSecrets.DECRYPTION_KEYS_OK !== true) {
      setShowModalMasterKey(true);
    }

    setProps((prevProps) => ({ ...prevProps, showPIIsInClear: checked }));
  }

  const promptMasterKey = () => {
    if (userSecrets.DECRYPTION_KEYS_OK !== true) {
      setShowModalMasterKey(true);
    }
  };

  const clearMasterKey = () => {
    setUserSecrets({
      ...userSecrets,
      DECRYPTION_KEYS_OK: false,
      DECRYPTION_KEYS: {},
    });
  };

  const showLockIcon =
    userSecrets.DECRYPTION_KEYS_OK !== true ? (
      <LockTwoTone onClick={promptMasterKey} />
    ) : (
      <UnlockTwoTone twoToneColor="#52c41a" onClick={clearMasterKey} />
    );

  return (
    <>
      <Card title="Devices" style={{ margin: "0px 25px 0px 25px" }}>
        <Row>
          <Col span={6}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Form
                form={form}
                onFinish={handleFormSubmit} // Updated handler
                onValuesChange={handleFormValuesChange} // Added handler
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '20px',
                }}
              >
                <Form.Item
                  style={{ marginRight: 8, marginBottom: 0 }}
                  name="ipAddress"
                  rules={[{ required: false }]}
                >
                  <AutoComplete
                    onSearch={handleSearch}
                    placeholder="IP Address"
                    options={ipAddressOptions.map((ip) => ({ value: ip }))}
                    allowClear // Enables the clear (×) button
                  >
                    <Input />
                  </AutoComplete>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
              </Form>
            </div>
          </Col>
          <Col span={18}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                width: '100%',
              }}
            >
              <ScatterChartECharts
                functionName="Devices"
                selectedPort={undefined}
              />
            </div>
          </Col>
        </Row>
        <div style={{ margin: "1em", textAlign: "right" }}>
          <span style={{ marginRight: "2em" }}>
            Show in clear:{" "}
            <Switch
              defaultChecked={props.showPIIsInClear}
              onChange={onShowInClear}
            />
          </span>
          <span style={{ marginRight: "2em" }}>{showLockIcon}</span>
          <span>
            Total:{" "}
            <Badge
              showZero
              count={props.data.length}
              overflowCount={10000000}
              style={{ backgroundColor: "gray" }}
            />
          </span>
        </div>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: (e: React.MouseEvent<HTMLElement>) => {
                console.log("record: ", record);
                // const target = e.target as HTMLElement;
                // const isIpAddress =
                //   target.classList.contains("selectable") ||
                //   target.parentElement?.classList.contains("selectable");
                // if (!isIpAddress) {
                //   handleViewDeviceClick(record);
                //   setSelectedHost(record.ipAddress);
                //   console.log("selectedHost: ", record.ipAddress);
                // }
              },
              onDoubleClick: (event) => {
                console.log(event);
              },
              onContextMenu: (event) => {},
              onMouseEnter: (event) => {},
              onMouseLeave: (event) => {},
            };
          }}
          key={props.loading + ""}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={props.data}
          // rowClassName="table-row"
        />
      </Card>

      <EventsTableProvider>
        <TimeRangeProvider>
          <DevicesModalComponent
            device={{
              ipAddress: selectedItem["ipAddress"],
              createdAt: selectedItem["createdAt"],
              updatedAt: selectedItem["updatedAt"],
            }}
          />
        </TimeRangeProvider>
      </EventsTableProvider>
      <ModalPromptMasterKey
        isShowing={showModalMasterKey}
        hide={() => setShowModalMasterKey(!showModalMasterKey)}
      />
    </>
  );
}

export default Devices;