import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAtom } from "./Atoms";

interface PrivateRouteProps {
  element: React.ComponentType<any>;  // You can specify more specific types based on what props `Element` accepts
  [key: string]: any;  // For additional props
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element: Element, ...rest }) => {
  const location = useLocation();
  const [user] = useRecoilState(userAtom);

  if (!user.is_authenticated) {
    return <Navigate to="/login" replace />;
  }

  return <Element {...rest} />;
};

export default PrivateRoute;
