import moment from "moment";

export class Utils {
  static getUUID() {
    return (String(1e7) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        Number(c) ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))
      ).toString(16)
    );
  }

  static titleCase = (inputStr) =>
    inputStr
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
      .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());

  static parseTimeToMinutes = (timeString: string): number => {
    // Extract the unit and the value from the time string
    const unit = timeString.slice(-1); // 'm' for minutes, 'h' for hours
    const value = parseInt(timeString.slice(0, -1)); // The numerical part of the time string

    let duration = moment.duration(value, unit as moment.unitOfTime.Base);
    return Math.abs(duration.asMinutes()); // Converts the duration to minutes
  }
  
  static formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

}