import { useQuery, UseQueryResult } from 'react-query';
import { useRecoilState } from 'recoil';
import { userAtom } from 'common/Atoms';
import AxiosApiInstance from 'common/Interceptors';
import { URL_API_V1 } from 'constants/global';

interface PiiField {
  fieldName: string;
  maskingTechnique: string;
}

// export function usePiiFields(): UseQueryResult<PiiField[], Error> {
//   const [user] = useRecoilState(userAtom);
//   const { axiosApiInstance } = AxiosApiInstance();
//   const endpoint = user?.organization ? `${URL_API_V1}/r/${user.organization}/events/pii/fields/` : null;

//   const fetchPiiFields = async (): Promise<PiiField[]> => {
//     if (!endpoint) {
//       throw new Error('Endpoint is undefined');
//     }
//     const response = await axiosApiInstance.get<{ entries: PiiField[] }>(endpoint);
//     if (response.status === 200 && response.data.entries) {
//       return response.data.entries.reduce((acc: PiiField[], item) => {
//         if (!acc.some((entry) => entry.fieldName === item.fieldName)) {
//           acc.push(item);
//         }
//         return acc;
//       }, []);
//     } else {
//       throw new Error(`Failed with status: ${response.status}`);
//     }
//   };

//   return useQuery(['piiFields', endpoint], fetchPiiFields, {
//     enabled: !!endpoint,
//   });
// }

export function usePiiFields() {
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();

  const aggregateResults = (entries) => {
    return entries.reduce((acc, entry) => {
      const key = `${entry.fieldName}_${entry.fieldType}_${entry.piiField}_${entry.maskingTechnique}`;
      if (!acc[key]) {
        acc[key] = {
          fieldName: entry.fieldName,
          fieldType: entry.fieldType,
          piiField: entry.piiField,
          maskingTechnique: entry.maskingTechnique,
          entries: []
        };
      }
      acc[key].entries.push(entry);
      return acc;
    }, {});
  };

  const endpoint = user?.organization ? `${URL_API_V1}/r/${user.organization}/events/pii/fields/` : null;

  // Function to fetch PII fields
  const fetchPiiFields = async () => {
    if (!endpoint) throw new Error('Endpoint is undefined');
    const response = await axiosApiInstance.get(endpoint);
    if (response.status === 200) {
      const entries = response.data.entries || response.data;
      return aggregateResults(entries);
    } else {
      throw new Error('Failed to fetch PII fields');
    }
  };

  const query = useQuery(['piiFields', endpoint], fetchPiiFields, {
    enabled: !!endpoint,
    onError: (error) => console.error('Error fetching PII fields:', error),
  });

  return { ...query, fetchPiiFields };
}

export function useMaskingTechniques() {
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();

  const endpoint = user?.organization ? `${URL_API_V1}/r/${user.organization}/events/masking-techniques/` : null;

  // Function to fetch masking techniques
  const fetchMaskingTechniques = async () => {
    if (!endpoint) throw new Error('Endpoint is undefined');
    const response = await axiosApiInstance.get(endpoint);
    if (response.status === 200) {
      return response.data.entries || response.data;
    } else {
      throw new Error('Failed to fetch masking techniques');
    }
  };

  const query = useQuery(['maskingTechniques', endpoint], fetchMaskingTechniques, {
    enabled: !!endpoint,
    onError: (error) => console.error('Error fetching masking techniques:', error),
  });

  return { ...query, fetchMaskingTechniques };
}

// export function useMaskingTechniques() {
//   const [user] = useRecoilState(userAtom);
//   const { axiosApiInstance } = AxiosApiInstance(); // Assuming axiosApiInstance is prepared elsewhere.

//   const endpoint = user?.organization ? `${URL_API_V1}/r/${user.organization}/events/masking-techniques/` : null;
//   const fetchMaskingTechniques = async () => {
//     if (!endpoint) {
//       throw new Error('Endpoint is undefined');
//     }
//     const response: any = await axiosApiInstance.get(endpoint);
//     if (response.status === 200) {
//       let results = response.data;
//       if ('entries' in results) {
//         results = results['entries'];
//       }
//       return results;
//     } else {
//       throw new Error('Failed to fetch masking techniques');
//     }
//   };

//   const {
//     data,
//     error,
//     isLoading,
//   } = useQuery('maskingTechniques', fetchMaskingTechniques, {
//     enabled: !!endpoint, // Ensures the query does not run until user's organization is defined
//     onError: (error) => {
//       console.error('Error fetching masking techniques:', error);
//     },
//   });

//   return {
//     data,
//     isLoading,
//     isError: error,
//   };
// }
