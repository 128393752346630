import { CloseCircleOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Drawer, Input, Table } from 'antd';
import useEventFields from 'hooks/getEventsAllFields';
import React, { useEffect, useState } from 'react';
import styles from './Events.module.scss';
import { useEventsTableContext } from '../../contexts/EventsTableContext';
import { useEventFieldValuesApi } from 'hooks/getEventsFieldValues';
import { useTimeRange } from '../../contexts/TimeRangeContext';
import { LuCircleEqual } from 'react-icons/lu';
import { usePIIFields } from '../../contexts/PIIFieldsContext';
import { EventDetailsRenderer } from "./EventDetailsRenderer";

interface EventSelectFieldsViewDrawerProps {
  isVisible: boolean;
  onClose: () => void;
  filterFieldsText: string;
  setFilterFieldsText: React.Dispatch<React.SetStateAction<string>>;
}

const FieldSelectionDrawer: React.FC<EventSelectFieldsViewDrawerProps> = ({
  isVisible,
  onClose,
  filterFieldsText,
  setFilterFieldsText,
}) => {
  const { eventFields: eventFieldsValues, getAllFields } = useEventFields();
  const [fieldName, setFieldName] = useState<string>('');
  const { decryptedPiiFields, getIconIfPiiField } = usePIIFields();
  const [selectedEntry, setSelectedEntry] = useState<any>({});

  useEffect(() => {
    getAllFields();
  }, []);

  const [tableFieldsValueStats, setTableFieldsValueStats] = useState<TableFieldValueStats[]>([]);
  const {
    tableFields,
    setTableFields,
    tableColumns,
    handleIconAddField,
    handleIconRemoveField,
    appendEqualsFilter,
    getSuggestionsForField,
  } = useEventsTableContext();

  useEffect(() => {
    const filteredFields =
      filterFieldsText.length > 0
        ? eventFieldsValues
            .filter((field) => field.toLowerCase().includes(filterFieldsText.toLowerCase()))
            .map((field, index) => ({
              key: index.toString(),
              fieldName: field,
            }))
        : eventFieldsValues.map((field, index) => ({
            key: index.toString(),
            fieldName: field,
          }));

    setTableFields(filteredFields);
  }, [eventFieldsValues, filterFieldsText]);

  const isFieldInTable = (fieldName: string) => {
    return tableColumns.some((column) => column.dataIndex === fieldName);
  };

  function handleFieldClick(fieldName) {
    setFieldName(fieldName);

    getSuggestionsForField(fieldName).then((response) => {
      const fieldValues = response.map((item, idx) => {
        return {
          key: item.field_name + "-" + idx,
          valueName: item.field_name,
          value: item.value,
        };
      });
      setTableFieldsValueStats(fieldValues);
    });
  }

  const tableFieldsColumns = [
    {
      title: 'Available fields',
      dataIndex: 'fieldName',
      showSorterTooltip: false,
      sorter: (a: TableField, b: TableField) => a.fieldName.localeCompare(b.fieldName),
      key: 'fieldName',
      render: (text: string) => (
        <div
          className={styles.cellContent}
          onClick={() => handleFieldClick(text) /* handleFieldClick(text) */}
          style={{ cursor: 'pointer' }}
        >
          {isFieldInTable(text) ? <span style={{ fontWeight: 'bold' }}>{text}</span> : text}
          {isFieldInTable(text) ? (
            <span className={styles.hoverIcon} onClick={() => handleIconRemoveField(text)}>
              <MinusCircleOutlined />
            </span>
          ) : (
            <span className={styles.hoverIcon} onClick={() => handleIconAddField(text)}>
              <PlusCircleOutlined />
            </span>
          )}
        </div>
      ),
    },
  ];

  const tableAvailableFieldsStatsColumns = [
    {
      title: 'Value name',
      dataIndex: 'valueName',
      key: 'valueName',
      render: (text: string, record: any, index: number) => (
        (
          <div className={styles.columnHeader}>
            <span
              className={styles.cellContent}
              title={text}
              style={{ maxWidth: 350, wordWrap: 'break-word', whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'anywhere' }}
            >
              {text}
            </span>
            <LuCircleEqual
              title="Filter by this value"
              className={styles.hoverIconTableColumns}
              onClick={(event) => {
                event.stopPropagation();
                appendEqualsFilter(fieldName, text);
              }}
            />
            { getIconIfPiiField("EventSelectFieldsViewDrawer Value Name Column", fieldName, text, fieldName + "-" + index)  }
          </div>
        )
      ),
    },
    {
      title: 'Occurrences',
      dataIndex: 'value',
      key: 'value',
      render: (value: number) => <div className={styles.cellContent}>{value}</div>,
    },
  ];

  return (
    <Drawer
      className="custom-drawer-style"
      onClose={onClose}
      open={isVisible}
      placement="left"
      closable={true}
      width={400}
      bodyStyle={{ padding: 4 }}
      style={{
        padding: 4,
      }}
    >
      <div className={styles.Events}>
        <Input
          placeholder="Type to filter..."
          value={filterFieldsText}
          onChange={(e) => setFilterFieldsText(e.target.value)}
          suffix={filterFieldsText && <CloseCircleOutlined onClick={() => setFilterFieldsText('')} style={{ cursor: 'pointer' }} />}
          style={{ margin: '4px' }}
          autoFocus
        />
        <Table
          style={{ padding: '0px' }}
          className={styles.EventsTable}
          dataSource={tableFields}
          columns={tableFieldsColumns}
          pagination={false}
          scroll={{ y: 500 }}
        />
        <Table
          style={{ padding: '0px', overflow: 'auto', height: '100%', clear: 'both' }}
          className={styles.EventsTable}
          dataSource={tableFieldsValueStats}
          columns={tableAvailableFieldsStatsColumns}
          pagination={false}
          scroll={{ y: 500 }}
        />
      </div>
    </Drawer>
  );
};

export default FieldSelectionDrawer;
