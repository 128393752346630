import {
  DesktopOutlined,
  FileSearchOutlined,
  LogoutOutlined,
  PieChartOutlined,
  SettingOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAtom, currentMenuItemAtom } from "../../common/Atoms";
import "./Navbar.css";

const { Sider } = Layout;

export default function Navbar() {
  let navigate = useNavigate();

  const [user, setUser] = useRecoilState(userAtom);
  const [currentMenuItem, setCurrentMenuItemAtom] = useRecoilState(currentMenuItemAtom);

  const onCollapse = (collapsed: boolean) => {
    setCurrentMenuItemAtom({ ...currentMenuItem, collapsed });
  };

  const onOpenChange = (keys: string[]) => {
    setCurrentMenuItemAtom({ ...currentMenuItem, openKeys: keys });
  };
  
  const handleClick = ({ keyPath }: any) => {
    let menuItem = keyPath[0];
    let openKeys = keyPath.slice(1).reverse();

    if (menuItem === "logout") {
      menuItem = "dashboard";
      handleLogout();
    } else {
      setCurrentMenuItemAtom({
        ...currentMenuItem,
        currentMenuItem: menuItem,
        openKeys: openKeys,
      });
    }
  };

  const handleLogout = () => {
    try {
      localStorage.clear();
      sessionStorage.clear();

      setCurrentMenuItemAtom({
        ...currentMenuItem,
        currentMenuItem: "dashboard",
        openKeys: [],
      });
      setUser({
        ...user,
        is_authenticated: false,
        token: "",
        refresh: "",
        is_2fa_authenticated: false,
      });
      navigate("/login");
    } catch (error) {
      console.error("Error during logout: ", error);
    }
  };

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuProps["items"] = [
    getItem(
      <Link to="/dashboard">Dashboard</Link>,
      "dashboard",
      <PieChartOutlined />
    ),
    getItem(<Link to="/events">Events</Link>, "events", <FileSearchOutlined />),
    getItem(
      <Link to={"/devices"}>Devices</Link>,
      "/devicesSubMenu/devices",
      <DesktopOutlined />
    ),
    getItem("Settings", "settings", <SettingOutlined />, [
      getItem(
        <Link to={"/profile"}>Profile</Link>,
        "/settings/profile",
        <UserOutlined />
      ),
      getItem(
        <Link to={"/organizations"}>Organizations</Link>,
        "/settings/organizations",
        <ShopOutlined />
      ),
      getItem(
        <Link to={"/users"}>Users</Link>,
        "/settings/users",
        <TeamOutlined />
      ),
      getItem("Application", "app-settings", <SettingOutlined />, [
        getItem("Privacy", "privacy-group", <SettingOutlined />, [
          getItem(
            <Link to={"/app-settings/privacy/privacy"}>Privacy</Link>,
            "app-settings/privacy/privacy"
          ),
          getItem(
            <Link to={"/app-settings/privacy/encryption"}>Encryption</Link>,
            "app-settings/privacy/encryption"
          ),
        ]),
        getItem("Agents", "agents-settings", <SettingOutlined />, [
          getItem(
            <Link to={"/app-settings/agents"}>Agents</Link>,
            "app-settings/agents"
          ),
          getItem(
            <Link to={"/app-settings/agents/api-keys"}>API Keys</Link>,
            "app-settings/api-keys"
          ),
          getItem(
            <Link to={"/app-settings/agents/certificates"}>Certificates</Link>,
            "app-settings/certificates"
          ),
          // getItem(
          //   <Link to={"/app-settings/logshippers"}>Log Shippers</Link>,
          //   "app-settings/logshippers"
          // ),
        ]),
        getItem(
          <Link to={"/app-settings/datausage"}>Disk Usage</Link>,
          "app-settings/datausage"
        ),
      ]),
    ]),
    getItem(
      <Link to={"/logout"} onClick={handleLogout}>
        Logout
      </Link>,
      "logout",
      <LogoutOutlined />
    ),
  ];

  return (
    <>
      <Sider
        collapsible
        collapsed={currentMenuItem.collapsed}
        onCollapse={onCollapse}
      >
        <Menu
          style={{  }}
          onClick={handleClick}
          onOpenChange={onOpenChange}
          openKeys={currentMenuItem.openKeys}
          selectedKeys={[currentMenuItem.currentMenuItem]}
          defaultSelectedKeys={["dashboard"]}
          mode="inline"
          items={items}
        />
      </Sider>
    </>
  );
}
