import { Button, Card, Col, notification, Row } from "antd";
import { Utils } from "common/Utils";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";

interface DataItem {
  label: string;
  count: number;
  totalSize: number;
}

const DataUsage: React.FC = () => {
  const { axiosApiInstance } = AxiosApiInstance();
  const [user] = useRecoilState(userAtom);

  const [dataCategory, setDataCategory] = useState<"all" | "demo-sampling">("all");

  const [dataProps, setDataProps] = useState<{
    loading: boolean;
    hasData: boolean;
    data: { [key: string]: DataItem };
  }>({
    loading: true,
    hasData: false,
    data: {},
  });

  const [totals, setTotals] = useState<{ count: number; totalSize: number }>({ count: 0, totalSize: 0 });

  const calculateTotals = (data: { [key: string]: DataItem }) => {
    return Object.entries(data).reduce(
      (acc, [key, value]) => {
        if (key !== "all") {
          acc.count += value.count;
          acc.totalSize += value.totalSize;
        }
        return acc;
      },
      { count: 0, totalSize: 0 }
    );
  };

  const getDiskUsage = () => {
    setDataProps({ ...dataProps, loading: true });
    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/disk-usage/`)
      .then((response) => {
        let results = {};
        if (response && response.status === 200) {
          results = response.data;
        }
        setDataProps({ loading: false, hasData: true, data: results });
        setTotals(calculateTotals(results));
      })
      .catch((exception) => {
        console.log("getDiskUsage.." + exception);
        setDataProps({ ...dataProps, loading: false });
      });
  };

  const deleteData = (dataCategory: string, dataType: string) => {
    console.log("deleteData: " + dataCategory + " " + dataType);
    setDataProps({ ...dataProps, loading: true });
    axiosApiInstance
      .delete(
        `${URL_API_V1}/r/${user.organization}/data/?data-category=${dataCategory}&data-type=${dataType}`
      )
      .then((response) => {
        if (response && response.status === 200) {
          let typeOfData = dataType === "all" ? "" : Utils.titleCase(dataType);
          notification.success({
            message: `Deleted ${dataCategory} ${typeOfData}`,
          });
          getDiskUsage();
        }
      })
      .catch((exception) => {
        console.log("deleteData.." + exception);
        setDataProps({ ...dataProps, loading: false });
      });
  };

  useEffect(() => {
    getDiskUsage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Card>
        <Row>
          <Col>
            <h3>Disk usage</h3>
            <p>View your disk usage and manage your data</p>
          </Col>
          <Col span={24}>
            <div style={{ textAlign: "right", marginBottom: "15px" }}>
              {/* <Popover
                placement="topLeft"
                arrowContent
                content="Activate filter to display only Demo-Data sampling"
              >
                <CheckableTag
                  onChange={() =>
                    setDataCategory(
                      dataCategory === "all" ? "demo-sampling" : "all"
                    )
                  }
                  key="demo-sampling"
                  checked={dataCategory === "demo-sampling"}
                >
                  Demo-Sampling
                </CheckableTag>
              </Popover> */}
            </div>
          </Col>
        </Row>
        <Row>
          {Object.entries(dataProps.data).map(([key, val]) => {
            let value =
              key === "all"
                ? { ...val, count: totals.count, totalSize: totals.totalSize }
                : val;
            return (
              <Card key={key} style={{ margin: 5, width: 300 }}>
                <Row>
                  <Col>
                    <h4>{Utils.titleCase(value.label)}</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>Number of records: {value.count}</Col>
                </Row>
                <Row>
                  <Col>Disk usage: {Utils.formatBytes(value.totalSize)}</Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={{ textAlign: "right", marginTop: "10px" }}>
                      <Button
                        onClick={() => deleteData(dataCategory, key)}
                        style={{ fontSize: "small" }}
                        type="dashed"
                      >
                        Delete data
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            );
          })}
        </Row>
      </Card>
    </div>
  );
};

export default DataUsage;