import { Layout } from "antd";
import IdleMonitor from "common/IdleMonitor";
import LeaveReview from "components/LeaveReview/LeaveReview";
import Navbar from "../Navbar/Navbar";
import Version from "../../common/Version";
import TopBar from "../TopBar/TopBar";

export function BasicLayout({ children }) {
  const { Content, Footer } = Layout;  

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <IdleMonitor />
      <TopBar />
      <Layout>
        <Navbar />
        <Layout>
          <Content>{children}</Content>
          <Footer style={{ textAlign: "center" }}>
            <LeaveReview /> |{" "}
            <span style={{ marginLeft: "12px" }}>
              <Version />
            </span>
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default BasicLayout;
