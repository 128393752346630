export default function RenderErrorMessages(errors_json, form) {
  if (errors_json)
    Object.keys(errors_json).map((key) => {
      return form.setFields([
        {
          name: key,
          errors: [errors_json[key]],
        },
      ]);
    });
}
