export const genericSorter = (a: any, b: any, dataIndex: string): number => {
  const valueA = a[dataIndex];
  const valueB = b[dataIndex];

  if (valueA == null && valueB == null) return 0;
  if (valueA == null) return 1;
  if (valueB == null) return -1;

  if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
    return new Date(valueA).getTime() - new Date(valueB).getTime();
  }

  const ipRegex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
  if (ipRegex.test(valueA) && ipRegex.test(valueB)) {
    const splitA = valueA.split('.').map(Number);
    const splitB = valueB.split('.').map(Number);
    for (let i = 0; i < 4; i++) {
      if (splitA[i] !== splitB[i]) return splitA[i] - splitB[i];
    }
    return 0;
  }

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return valueA.localeCompare(valueB);
  }

  if (typeof valueA === 'number' && typeof valueB === 'number') {
    return valueA - valueB;
  }

  return 0;
};
