import {
  Button,
  Col,
  DatePicker,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Table
} from "antd";
import React, { useState } from "react";

import { CalendarOutlined } from "@ant-design/icons";
import { DEFAULT_DATETIME_FORMAT } from "../../constants/user";
import moment from "moment";
import dayjs from "dayjs";


const { RangePicker } = DatePicker;

const defaultDates: [dayjs.Dayjs, dayjs.Dayjs] = [
  dayjs().subtract(15, "minutes"),
  dayjs()
];

const timeRangeGroups = [
  {
    label: "Short Range",
    value: "short",
    options: ["-1m", "-5m", "-15m", "-30m", "-1h"],
  },
  {
    label: "Medium Range",
    value: "medium",
    options: ["-2h", "-4h", "-8h"],
  },
  {
    label: "Long Range",
    value: "long",
    options: ["-12h", "-24h", "-48h"],
  },
];


function getTimeDifference(startTime: string, endTime: string) {
  if (!startTime || !endTime) {
    return { hours: 0, minutes: 0, seconds: 0 };
  }
  const start = new Date(startTime);
  const end = new Date(endTime);
  const difference = end.getTime() - start.getTime();

  const hours = Math.floor(difference / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  return { hours, minutes, seconds };
}

const TimeRangeOptions: React.FC<TimeRangeOptionsProps> = ({ ...props }) => {
  const { selectedRelativeTime, setSelectedRelativeTime } = props;
  const { selectedAbsoluteDates, setSelectedAbsoluteDates } = props;
  const { timeRangeType, setTimeRangeType } = props;
  //const [timeRangeType, setTimeRangeType] = useState<string>("relative");
  // const [selectedAbsoluteDates, setSelectedAbsoluteDates] =
  //   useState<[moment.Moment, moment.Moment]>(defaultDates);

  //const [selectedRelativeTime, setSelectedRelativeTime] = useState<string>("");

  const selectedOptionClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    console.log("Selected Option:", e.currentTarget.textContent);
    setSelectedRelativeTime(e.currentTarget.textContent || "");
  };

  const [popoverVisibleRelativeTime, setPopoverVisibleRelativeTime] =
  useState<boolean>(false);
const [popoverVisibleAbsoluteTime, setPopoverVisibleAbsoluteTime] =
  useState<boolean>(false);

  const columns = [
    {
      title: "Range",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "",
      dataIndex: "options",
      key: "options",
      render: (options: string[]) => (
        <div>
          {options.map((option) => (
            <Button
              type={selectedRelativeTime === option ? "primary" : "default"}
              onClick={selectedOptionClick}
              key={option}
              size="small"
              style={{ margin: "2px" }}
            >
              {option}
            </Button>
          ))}
        </div>
      ),
    },
  ];

  const handleTimeRangeChange = (e: RadioChangeEvent) => {
    console.log("Time Range Type:", e.target.value);
    setTimeRangeType(e.target.value);
    setPopoverVisibleRelativeTime(e.target.value === "relative");
    setPopoverVisibleAbsoluteTime(e.target.value === "absolute");
  };

  const handleSelectTimeRange = (value: [moment.Moment, moment.Moment]) => {
    console.log("Selected Time Range:", value);
    setSelectedAbsoluteDates(value);
  };

  const relativeTimeRange = (
    <Table<TimeRangeGroup>
      columns={columns}
      dataSource={timeRangeGroups}
      pagination={false}
      size="small"
    />
  );

  const absoluteTimeRange = (
    <>
      <Row>
        <Col>
          <RangePicker
            defaultValue={defaultDates}
            style={{ width: "100%" }}
            showTime={{ format: "HH:mm:ss" }}
            format={DEFAULT_DATETIME_FORMAT}
            onChange={(dates) =>
              handleSelectTimeRange(dates as [moment.Moment, moment.Moment])
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ margin: "4px" }}>
            {" "}
            Looking at the interval of{" "}
            {
              getTimeDifference(
                selectedAbsoluteDates[0].toISOString(),
                selectedAbsoluteDates[1].toISOString()
              ).hours
            }{" "}
            hours{" "}
            {
              getTimeDifference(
                selectedAbsoluteDates[0].toISOString(),
                selectedAbsoluteDates[1].toISOString()
              ).minutes
            }{" "}
            minutes{" "}
            {
              getTimeDifference(
                selectedAbsoluteDates[0].toISOString(),
                selectedAbsoluteDates[1].toISOString()
              ).seconds
            }{" "}
            seconds
          </div>
        </Col>
      </Row>
    </>
  );

  return (
    <div style={{ blockSize: "inline" }}>
      <Radio.Group
        onChange={handleTimeRangeChange}
        value={timeRangeType}
        style={{ marginBottom: 16, display: "inline" }}
      >
        <Popover
          content={relativeTimeRange}
          title="Relative Time Range from Now"
          trigger="click"
          placement="bottom"
          open={popoverVisibleRelativeTime}
          onOpenChange={setPopoverVisibleRelativeTime}
        >
          {/* This span acts as an anchor for the popover */}
          <Radio.Button value="relative"><CalendarOutlined/> Relative</Radio.Button>
        </Popover>
        <Popover
          content={absoluteTimeRange}
          title="Select Specific Time Range"
          trigger="click"
          placement="bottom"
          open={popoverVisibleAbsoluteTime}
          onOpenChange={setPopoverVisibleAbsoluteTime}
        >
          <Radio.Button value="absolute"><CalendarOutlined/> Absolute</Radio.Button>
        </Popover>
      </Radio.Group>
    </div>
  );
};

export default TimeRangeOptions;