import { atom } from "recoil";
import { UserSecrets } from "../types/UserSecretsTypes";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }
    onSet((newValue) => {
      sessionStorage.setItem(key, JSON.stringify(newValue));
    });
  };

// export const isAuthenticatedAtom = atom({
//   key: "isAuthenticated", // unique ID (with respect to other atoms/selectors)
//   default: false, // default value (aka initial value)
//   effects_UNSTABLE: [localStorageEffect("is_authenticated")],
// });

export const userAtom = atom({
  key: "user",
  default: { username: "", token: "", refresh:"", uuid: "", is_authenticated: false, organization: "", organizations: [], is_2fa_activated: false, is_2fa_authenticated: false },
  effects_UNSTABLE: [localStorageEffect("user")],
});

export const notificationsRefreshAtom = atom({
  key: 'notificationsRefreshAtom',
  default: 0,
});

export const userSecretsAtom = atom<UserSecrets>({
  key: "userSecrets",
  default: { DECRYPTION_KEYS_OK:false, DECRYPTION_KEYS: {}, ENCRYPTED_DDK: {} },
});

export const styleModeAtom = atom({
  key: 'theme',
  default: { theme: 'light' },
  effects_UNSTABLE: [
    ({ setSelf, onSet }) => {
      const savedValue = localStorage.getItem('theme');
      if (savedValue) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue) => {
        localStorage.setItem('theme', JSON.stringify(newValue));
      });
    },
  ],
});


export const selectedEventAtom = atom({
  key: "selectedEvent",
  default: {},
});

export const currentMenuItemAtom = atom({
  key: "currentMenuItem",
  default: { currentMenuItem: "dashboard", openKeys: [] as any[], collapsed: false },
  effects_UNSTABLE: [localStorageEffect("currentMenuItem")],
});