import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Utils } from "../common/2FAUtils";
import { userAtom, currentMenuItemAtom } from "../common/Atoms";
import AxiosApiInstance from "../common/Interceptors";
import RenderErrorMessages from "../common/RenderErrorMessages";
import { URL_API_V1 } from "../constants/global";

export default function useAuth() {
  let navigate = useNavigate();

  const [user, setUser] = useRecoilState(userAtom);
  const [currentMenuItem, setCurrentMenuItem] = useRecoilState(currentMenuItemAtom);
  const { axiosApiInstance } = AxiosApiInstance();

  const result: string[] = [];
  const [error, setError] = useState(result);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  async function loginUser(credentials) {
    const res = axios
      .post(`${URL_API_V1}/auth/token`, credentials)
      .then((response) => {
        setError([]);
        if (response && response.status === 200) {
          const data: any = response.data;
          let default_organization = data['default_organization']
          let jwt_decoded: any = jwtDecode(data.token);
          const is_2fa_authenticated = Utils.isJWT2FAAuthenticated(jwt_decoded);
          setUser({ ...data, is_authenticated: true, is_2fa_authenticated, organization: default_organization, organizations: jwt_decoded['organizations'] });
          return true;
        } else if (response.status === 403) {
          setError(["Invalid credentials"]);
        } else {
          setError([response.statusText]);
        }
        return false;
      })
      .catch((err) => {
        if (err && err.response?.data && err.response?.data["detail"]) {
          console.log("error has detail..");
          setError(err.response?.data["detail"]);
        } else if (err && err.response?.status === 403) {
          setError(["Invalid credentials"]);
        } else if (err && err.response?.status === 498) {
          setError([
            `Authentication failed. Invalid challenge-response test, we could not determine if you are a human. Please try again.`,
          ]);
        }
      });

    return await res;
  }

  async function registerUser(data, form) {
    setConfirmationMessage("");
    setError([]);
    axios
      .post(`${URL_API_V1}/auth/register`, data)
      .then((response: any) => {
        setConfirmationMessage("");
        if (response.status === 201) {
          setConfirmationMessage("Account created with success.");
        } else {
          setError(response.data);
        }
      })
      .catch((error) => {
        RenderErrorMessages(error.response.data, form);
      });
  }

  function handleLogout() {
    console.log("handleLogout before push");
    localStorage.clear();
    sessionStorage.clear();
    setCurrentMenuItem({ ...currentMenuItem, currentMenuItem: "dashboard", openKeys: [] });
    // setUser({ ...user, is_authenticated: false, token: "", refresh: "", is_2fa_authenticated: false });
    navigate("/login");
  }

  const refreshToken = () => {
    const refreshToken = user.refresh;
    return axiosApiInstance
      .post(`${URL_API_V1}/auth/refresh`, {
        refresh: refreshToken,
      })
      .then((response) => {
        if (response && response.status === 200) {
          console.log("re-authenticating.. good - got the fresh token");
          const data: any = response.data;
          const token = data["token"];
          // is related with Interceptors.ts AxiosApiInstance() function
          let jwt_decoded: any = jwtDecode(data.token);
          const is_2fa_authenticated = Utils.isJWT2FAAuthenticated(jwt_decoded);
          setUser({ ...user, token: token, is_2fa_authenticated, organizations: jwt_decoded['organizations']});
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return {
    loginUser,
    registerUser,
    error,
    setError,
    confirmationMessage,
    setConfirmationMessage,
    handleLogout,
    refreshToken,
  };
}
