import { useCallback } from 'react';
import moment from 'moment';
import { Utils } from '../common/Utils';

export const useTimeRangeCalculation = () => {
  return useCallback((timeRangeType: string, selectedRelativeTime: string, selectedAbsoluteDates: moment.Moment[]) => {
    let startTime = 0;
    let endTime = 0;

    if (timeRangeType === 'relative') {
      const minutesToSubtract = Utils.parseTimeToMinutes(selectedRelativeTime);
      startTime = moment().subtract(minutesToSubtract, 'minutes').valueOf() * 1000;
      endTime = moment().valueOf() * 1000;
    } else if (selectedAbsoluteDates && selectedAbsoluteDates.length === 2) {
      startTime = selectedAbsoluteDates[0].valueOf() * 1000;
      endTime = selectedAbsoluteDates[1].valueOf() * 1000;
    }

    return { startTime, endTime };
  }, []);
};
