import { Alert, Button, Card, Form, Input, Layout, Space } from "antd";
import { useCallback, useEffect } from "react";
//import Container from "react-bootstrap/Container";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link, useNavigate } from "react-router-dom";
import { reCAPTCHAKEY } from "../../constants/global";
import useAuth from "../../hooks/auth";

const { Footer, Content } = Layout;

export default function Register() {
  let navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { error, registerUser, confirmationMessage, setConfirmationMessage } = useAuth();

  const [form] = Form.useForm();

  const onFinish = useCallback(async (values, pRecaptchaToken) => {
    values["response"] = pRecaptchaToken;
    registerUser(values, form);
  }, [form, registerUser]);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(
    async (values) => {
      let token = "";
      if(reCAPTCHAKEY) {
        if (!executeRecaptcha) {
          console.log("Execute recaptcha not yet available");
          return;
        }
        token = await executeRecaptcha("login");
      }
      onFinish(values, token)
    },
    [executeRecaptcha, onFinish]
  );

  useEffect(() => {
    if (confirmationMessage) {
      window.setTimeout(() => {
        setConfirmationMessage("");
        return navigate("/login");
      }, 5000);
    }
  }, [confirmationMessage, setConfirmationMessage]);

  return (
    <>
      {/* <Container className="p-3"> */}
        <Space direction="vertical">
          <Card title="Create user account" style={{ width: 500 }}>
              <Content>
                <Form form={form} onFinish={handleReCaptchaVerify}>
                  <Form.Item
                    name="email"
                    label="Email address"
                    rules={[
                      {
                        required: true,
                        message: "Email address is required.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Password is required.",
                      },
                    ]}
                  >
                    <Input type="password" autoComplete="off" />
                  </Form.Item>

                  <Form.Item
                    name="passwordConfirmation"
                    label="Password confirmation"
                    rules={[
                      {
                        required: true,
                        message: "Password confirmation is required.",
                      },
                    ]}
                  >
                    <Input type="password" autoComplete="off" />
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                      Create account
                    </Button>
                  </Form.Item>
                </Form>
                {confirmationMessage && (
                  <div style={{ marginTop: 10 }}>
                    <Alert
                      message={confirmationMessage}
                      description="You will be redirected to the login page shortly."
                      type="success"
                      showIcon
                      closable
                    />
                  </div>
                )}
              </Content>
            <Content>
              {error.length > 0 && (
                <pre>
                  {error.map((value, index) => (
                    <Alert key={index} style={{ marginTop: 10 }} message={value} type="error" showIcon closable />
                  ))}
                </pre>
              )}
            </Content>
          </Card>
        </Space>
      {/* </Container> */}
      <nav className="">
        <Link style={{ margin: "5px" }} to={"/forgot-credentials"}>
          {" "}
          Forgot username or password{" "}
        </Link>
        |
        <Link style={{ margin: "5px" }} to={"/login"}>
          {" "}
          Login
        </Link>
      </nav>
    </>
  );
}
