import React, { createContext, FC, ReactNode, useState } from 'react';

interface DeviceContextType {
  selectedItem: Record<string, any>;
  setSelectedItem: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  showDeviceModal: boolean;
  setShowDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEntry: Record<string, any>;
  setSelectedEntry: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  selectedHost: string | undefined;
  setSelectedHost: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const defaultDeviceContextValue: DeviceContextType = {
  selectedItem: {},
  setSelectedItem: () => {},
  showDeviceModal: false,
  setShowDeviceModal: () => {},
  selectedEntry: {},
  setSelectedEntry: () => {},
  selectedHost: undefined,
  setSelectedHost: () => {},
};

const DeviceContext = createContext<DeviceContextType>(defaultDeviceContextValue);

export const DeviceProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState<Record<string, any>>({});
  const [showDeviceModal, setShowDeviceModal] = useState<boolean>(false);
  const [selectedEntry, setSelectedEntry] = useState<Record<string, any>>({});
  const [selectedHost, setSelectedHost] = useState<string | undefined>(undefined);

  return (
    <DeviceContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        showDeviceModal,
        setShowDeviceModal,
        selectedEntry,
        setSelectedEntry,
        selectedHost,
        setSelectedHost,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

export const useDevice = () => {
  const context = React.useContext(DeviceContext);

  if (!context) {
    throw new Error('useDevice must be used within a DeviceProvider');
  }

  return context;
};
