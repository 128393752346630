import { Button, Card, Checkbox, Col, Form, Input, notification, Row, Typography } from 'antd';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import RecaptchaV3Component from 'common/RecaptchaV3Component';
import { Utils } from '../../common/2FAUtils';
import { userAtom } from '../../common/Atoms';
import AxiosApiInstance from '../../common/Interceptors';
import { URL_API_V1, reCAPTCHAKEY } from '../../constants/global';
import { TWOFA_SETUP_PATH } from '../../constants/urls';
import UpdatePasswordModal from './ModalUpdatePassword';
import styles from './Profile.module.scss';
import moment from 'moment';
import { DEFAULT_DATETIME_FORMAT, TIMEZONE } from 'constants/user';

function ProfileInnerComponent() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [showChangePassword, setShowChangePassword] = useState(false);

  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();
  const [form] = Form.useForm();

  const [, setCounter] = useState(0);

  const onFinish = useCallback(
    (values) => {
      const data = {
        email: values.email,
        response: values.response,
      };
      console.log(data);

      axiosApiInstance
        .put(`${URL_API_V1}/profile`, data)
        .then(() => {
          setCounter((keySequence) => keySequence + 1);
        })
        .catch((err) => {
          console.log('error message:' + err.message);
        });
    },
    [axiosApiInstance]
  );

  const getUserProfile = useCallback(() => {
    return axiosApiInstance.get(`${URL_API_V1}/profile`).then((response) => {
      if (response?.data !== undefined) {
        form.setFieldsValue(response.data);
      }
    });
  }, [form, axiosApiInstance]);

  const delete2FA = useCallback(
    (recaptchaToken) => {
      axiosApiInstance
        .delete(`${URL_API_V1}/auth/totp/${recaptchaToken}`)
        .then((resp) => {
          if (resp.status === 200) {
            notification.success({ message: '2FA has been disabled.' });
            let responseData: any = resp.data;
            let token = responseData['token'];

            let jwt_decoded: any = jwtDecode(token);
            const is_2fa_authenticated = Utils.isJWT2FAAuthenticated(jwt_decoded);
            setUser({
              ...user,
              token: token,
              is_authenticated: true,
              is_2fa_activated: false,
              is_2fa_authenticated,
              organizations: jwt_decoded['organizations'],
            });
            getUserProfile();
          }
          console.log(resp.data);
        })
        .catch((err) => {
          console.log('error message:' + err.message);
          notification.error({ message: 'An unexpected error has occurred.' });
        });
    },
    [axiosApiInstance, getUserProfile, setUser, user]
  );

  // Create an event handler so you can call the verification on button click event or form submit
  const handleUpdateProfileReCaptchaVerify = useCallback(
    async (values) => {
      let token = '';
      if(reCAPTCHAKEY) {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }

        token = await executeRecaptcha('login');
      }
      values['response'] = token;
      onFinish(values);
    },
    [executeRecaptcha, onFinish]
  );

  const handleDisable2FAReCaptchaVerify = useCallback(async () => {
    let token = '';
    if(reCAPTCHAKEY) {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      token = await executeRecaptcha('login');
    }
    delete2FA(token);
  }, [delete2FA, executeRecaptcha]);

  const disable2FAButton = (
    <Button style={{ marginLeft: '1em', textAlign: 'right' }} htmlType="button" type="default" onClick={handleDisable2FAReCaptchaVerify}>
      Disable 2FA
    </Button>
  );

  const enable2FAButton = (
    <Button
      style={{ marginLeft: '1em', textAlign: 'right' }}
      htmlType="button"
      type="default"
      onClick={() => navigate(TWOFA_SETUP_PATH)}
    >
      Enable 2FA
    </Button>
  );

  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  function getDateFormat(text) {
    let res = moment.utc(text).tz(TIMEZONE).format(DEFAULT_DATETIME_FORMAT);
    return res;
  }
  
  return (
    <div>
      <Card title="Profile" style={{ margin: "0px 25px 0px 25px" }}>
        <Row>
          <Col span={8} order={1}>
            <Form form={form} onFinish={handleUpdateProfileReCaptchaVerify}>
              <>
                <Col>
                  <Form.Item
                    name="email"
                    label="Email address"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Form.Item name="last_login" label="Last login">
                  <Typography.Text>
                    {getDateFormat(form.getFieldValue('last_login'))}
                  </Typography.Text>
                </Form.Item>
                <Form.Item name="is_active" valuePropName="checked" label="Is active">
                  <Checkbox disabled />
                </Form.Item>
                <Form.Item name="is_verified" valuePropName="checked" label="Is verified">
                  <Checkbox disabled />
                </Form.Item>
                <Col>
                  <Input.Group compact>
                    <Form.Item name="is_2fa_activated" valuePropName="checked" label="Is 2FA activated">
                      <Checkbox disabled />
                    </Form.Item>
                    {user.is_2fa_authenticated ? disable2FAButton : enable2FAButton}
                  </Input.Group>
                </Col>
                <Col>
                  <Input.Group compact>
                    <Form.Item name="password" label="Password">
                      <Button
                        style={{ marginLeft: '1em', textAlign: 'right' }}
                        htmlType="button"
                        type="default"
                        onClick={() => setShowChangePassword(!showChangePassword)}
                      >
                        Modify password
                      </Button>
                    </Form.Item>
                  </Input.Group>
                </Col>
                <Col></Col>
              </>
            </Form>
          </Col>
          <Col span={8} order={2}></Col>
          <Col span={8} order={3}></Col>
        </Row>
      </Card>
      <UpdatePasswordModal isShowing={showChangePassword} hide={() => setShowChangePassword(!showChangePassword)} />
    </div>
  );
}

export const Profile = () => (
  <RecaptchaV3Component 
  reCAPTCHAKEY={reCAPTCHAKEY}
  useRecaptchaNet={true}
  useEnterprise={true}
  scriptProps={{
    async: false, // optional, default to false,
    defer: false, // optional, default to false
    appendTo: 'head', // optional, default to "head", can be "head" or "body",
    nonce: undefined, // optional, default undefined
  }} >
    <ProfileInnerComponent />
  </RecaptchaV3Component>
);
