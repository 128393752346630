import React, { useEffect, useState } from "react";
import { Drawer, Table, Tag } from "antd";
import styles from "./Events.module.scss";
import { LuCircleEqual } from "react-icons/lu";
import { useEventsTableContext } from "../../contexts/EventsTableContext";
import { usePIIFields } from "../../contexts/PIIFieldsContext";

const EventDetailsDrawer = ({ isVisible, onClose, selectedEntry }) => {
  const { searchComponentRef, appendEqualsFilter } = useEventsTableContext();
  const [tableFieldsDetailView, setTableFieldsDetailView] = useState<
    TableColumns[]
  >([]);
  const [tableFieldsDataDetailView, setTableFieldsDataDetailView] = useState<
    EventData[]
  >([]);

  const { decryptedPiiFields, getIconIfPiiField } = usePIIFields();

  useEffect(() => {
    const tableColumns = [
      {
        title: "Field Name",
        dataIndex: "fieldName",
        key: "fieldName",
        width: 250,
      },
      {
        title: "Value",
        dataIndex: "value",
        key: "value",
        width: 250,
        render: (value: any, record: any) => {
          const displayText = typeof value === "boolean" ? value.toString() : value;
          return (
            (
              <div className={styles.columnHeader}>
                {" "}
                {/* Ensure this div has the columnHeader class */}
                <span
                  className={styles.cellContent}
                  title={displayText}
                  style={{
                    maxWidth: 350,
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                    overflowWrap: "anywhere",
                  }}
                >
                  {displayText}
                  {getIconIfPiiField(
                    "EventDetailsDrawer used to render Value",
                    record["key"],
                    displayText,
                    record["recordId"]
                  )}
                  <LuCircleEqual
                    title="Filter by this value"
                    className={styles.hoverIconTableColumns}
                    onClick={(event) => {
                      event.stopPropagation();
                      appendEqualsFilter(record["key"], displayText);
                    }}
                  />
                </span>
              </div>
            )
          );
        },
      },
    ];
    setTableFieldsDetailView(tableColumns);

    const excludedFields = new Set(["other"]); // Example fields to exclude

    const tableData = Object.keys(selectedEntry)
      .filter((field) => !excludedFields.has(field)) // Exclude specified fields
      .map((field) => ({
        key: field,
        fieldName: field,
        value: selectedEntry[field],
        recordId: selectedEntry["ingestion_id"],
      }));

    setTableFieldsDataDetailView(tableData);
  }, [selectedEntry]);

  return (
    <Drawer
      className="custom-drawer-style"
      onClose={onClose}
      open={isVisible}
      placement="right"
      closable={true}
      width={550}
      bodyStyle={{ padding: 4, height: "100%" }}
      style={{
        padding: 4,
      }}
    >
      <div className={styles.Events}>
        <Table
          style={{
            padding: "0px",
            overflow: "auto",
            height: "100%",
            clear: "both",
          }}
          className={styles.EventsTable}
          dataSource={tableFieldsDataDetailView}
          columns={tableFieldsDetailView}
          pagination={false}
        />
      </div>
    </Drawer>
  );
};

export default EventDetailsDrawer;
